import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { multipleFile, BaseAPI, BaseURL } from "../../../services/services";
import { AiFillCheckCircle } from 'react-icons/ai'
import {  toast } from "react-toastify";
import { MdOutlineCancel } from "react-icons/md";
import nofile from "../../../assets/images/noFiles.gif";
import Spin from "../../../components/Loader/Spin";

const Documents = () => {
  const navigate = useNavigate();

  const user = localStorage.getItem("userData");
  const documents = JSON.parse(user).documents;
  const token = localStorage.getItem("token");
  const UserInfo = JSON.parse(user);

  //states
  const [medicalDocuments, setMedicalDocuments] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleteing, setIsDeleteing] = useState(false);
  const [loading , setloading] = useState(false)
  const [deleteDocs , setDeleteDocs] = useState([])

  

  const goBack = () => {
    navigate(-1);
  };

  const documentUpload = (e) => {
    let arr = [];
    
    Object.keys(e.target.files).map((key) => {
      
      multipleFile(e.target.files[key]).then((result) => {
        
        let obj;
        obj = {
          name: e.target.files[key].name,
          fileLink: result.data.url,
        };
        arr.push(obj);
      });
    });
    
    setMedicalDocuments(arr);
    
  };

  const updateDocuments = (type ,arr=[]) => {

    
    
    setloading(true)

    console.log(arr.length)

    if(arr.length === 0 && type==='delete' ){
      setloading(false)
      return toast.warn('Action not allowed !')
    }
    
    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
      "Content-Type": "application/json",
    });

    var raw = JSON.stringify({
      id: UserInfo._id,
      documents:
        type === "update"
          ? [...medicalDocuments, ...documents]
          : arr,
    });

    

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    BaseAPI(`${BaseURL}/users/updateProfile`, requestOptions).then((result) => {
      
      if (result.status !== 200) {
        toast.warn(result.message);
      } else {
        toast.success(`Documents ${type==='update'?'Updated':'Deleted'} Successfully` , {
          icon : <AiFillCheckCircle className="text-lg text-secondary" />
        });
        localStorage.setItem("userData", JSON.stringify(result.data));
        window.location.reload();
      }
    }).catch(err => console.log(err)).finally(()=>setloading(false));
  };

  const confirmDelete = () => {
    let temp = [...documents]
    console.log(temp , deleteDocs)

    if(deleteDocs.length === 0){
      return toast.warn('No changes found !')
    }
     
    let arr = temp.filter(ar => !deleteDocs.find(rm => rm._id === ar._id))

    console.log(arr)


    console.log(temp === arr)
    
   
    
    setMedicalDocuments(arr)
    updateDocuments("delete" ,arr);
    console.log('Document to delete' , arr)
  };

  const DocumentToDelete = (item, e) => {
    
    let arr = [...documents];
    console.log(e.target.checked , item)
    let temp=[...deleteDocs]
    if(e.target.checked)
      temp.push(item)
    console.log(temp)
    setDeleteDocs(temp)
    console.log(deleteDocs)

    
  };


  return (
    <div className="Home bg-Light-gray min-h-screen w-full">
      <Navbar heading="Home / My Documents" />
      <div className="flex justify-between md:items-center w-full py-5 px-4 flex-col md:flex-row space-y-3 md:space-y-0 ">
        <div className="flex text-text  items-center">
          <div
            className="bg-white rounded-lg cursor-pointer p-2 mx-3 shadow-back text-lg font-bold"
            onClick={goBack}
          >
            <HiOutlineChevronLeft />
          </div>
          <h1 className="text-lg font-bold">My Documents</h1>
        </div>
        <div className="flex space-x-3 items-center justify-center md:justify-start ">
          {isUpdating && (
            <input
              type="file"
              accept="image/*,.pdf"
              multiple
              onChange={(e) => {
                documentUpload(e);
              }}
            />
          )}
          <button
            onClick={() => {
              isUpdating
                ? medicalDocuments.length > 0 ? updateDocuments("update") : toast.warn('Unable to upload document. Please wait for a moment')
                : setIsUpdating(true);
            }}
            className="bg-primary text-white  rounded-lg p-3"
          >
            {isUpdating ? <span className="flex items-center"> {loading && <Spin className='mr-2'/> } Update</span> : " Add Documents"}
          </button>
          {isUpdating && (
            <button
              className="bg-danger text-lg rounded-lg p-3 text-white"
              onClick={() => setIsUpdating(false)}
            >
              <MdOutlineCancel />
            </button>
          )}
          {!isUpdating && (
            <button
              className="text-white bg-danger p-3 rounded-lg"
              onClick={() => {
                isDeleteing ? confirmDelete() : setIsDeleteing(true);
              }}
            >
              {isDeleteing ?  <span className="flex items-center">{loading && <Spin className='mr-2'/> } Delete</span> : "Remove Documents"}
            </button>
          )}
          {isDeleteing && (
            <button
              className="text-secondary border-secondary p-3 rounded-lg bg-white border-2"
              onClick={() => setIsDeleteing(false)}
            >
              Cancel
            </button>
          )}
        </div>
      </div>
      {documents.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
          {documents.map((item, index) => {
            return (
              <a
                className="m-5 bg-white rounded-lg text-text font-bold p-4 shadow flex flex-col justify-center items-center hover:shadow-lg transition truncate"
                href={item.fileLink}
                target="_blank"
                key={index} rel="noreferrer"
              >
                {isDeleteing && (
                  <div className="flex justify-end  w-full my-3">
                    {" "}
                    <input
                      type="checkbox"
                      className="h-4 w-4"
                      onChange={(e) => DocumentToDelete(item, e)}
                    />{" "}
                  </div>
                )}
                <iframe
                  src={item.fileLink}
                  title={item.name}
                  height="100%"
                  width="100%"
                />
                <p className="my-5 w-full truncate">{item.name}</p>
              </a>
            );
          })}
        </div>
      ) : (
        <FilesBox />
      )}
    </div>
  );
};

const FilesBox = () => {
  return (
    <div className="flex h-1/2 justify-center items-center">
      {/* ------cards----------- */}
      <div className="bg-white rounded-lg shadow p-4 flex flex-col justify-center items-center">
        <img src={nofile} className=" h-32 w-32 " />
        <h2 className=" my-3 text-lg text-danger font-bold">
          No Documents Found!
        </h2>
        <p className="my-3 text-text">Try adding new documents.</p>
      </div>
    </div>
  );
};

export default Documents;
