import {
  pdfjs, Document, Page, Outline,
} from 'react-pdf';
import { pdf } from '@react-pdf/renderer';
import {
  useCallback, useEffect, useState, Children,
} from 'react';
import { HiArrowLeft, HiArrowRight } from 'react-icons/hi';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PDFViewer({ children, onPageChange = () => {} , rerender=false }) {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(null);

  

  const onDocumentLoadSuccess = useCallback((document) => {
    const { numPages: nextNumPages } = document;
    setNumPages(nextNumPages);
    setPageNumber(1);
  }, []);

  const onItemClick = useCallback(
    ({ pageNumber: nextPageNumber }) => setPageNumber(nextPageNumber),
    [],
  );

  const changePage = useCallback(
    (offset) => setPageNumber((prevPageNumber) => (prevPageNumber || 1) + offset),
    [],
  );

  const previousPage = useCallback(() => changePage(-1), [changePage]);

  const nextPage = useCallback(() => changePage(1), [changePage]);

  useEffect(() => {
    const child = Children.only(children);

    pdf(child).toBlob().then((blob) => {
      setPdfUrl(URL.createObjectURL(blob));
    });
  }, [children]);

  useEffect(() => {
    onPageChange({ pageNumber, numPages });
  }, [pageNumber]);

  return (
    <Document
      file={pdfUrl}
      onLoadSuccess={onDocumentLoadSuccess}
      onItemClick={onItemClick}
    >
      <Outline
        className="custom-classname-outline"
        onItemClick={onItemClick}
      />
      <div className="relative">
        <Page renderMode="svg" pageNumber={pageNumber} />
        <div className="absolute bottom-5 left-1/3 flex border items-stretch bg-white rounded-md drop-shadow-card w-fit gap-4">
          <button
            disabled={pageNumber <= 1}
            onClick={previousPage}
            type="button"
            className="hover:bg-zinc-100 border-r p-2 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <HiArrowLeft />
          </button>
          <div className="self-center text-[14px]">
            {`Page ${pageNumber || (numPages ? 1 : '--')} of ${numPages || '--'}`}
          </div>
          <button
            disabled={pageNumber >= numPages}
            onClick={nextPage}
            type="button"
            className="hover:bg-zinc-100 p-2 border-l disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <HiArrowRight />
          </button>
        </div>
      </div>
    </Document>
  );
}

export default PDFViewer;
