import React from 'react'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Spinner = () => {
    return (
        <div className='flex items-center justify-center'>

        <Loader
        type="MutatingDots"
        // color="#3787FF"
        color="#01A1FF"
        // secondaryColor='#00CAA4'
        secondaryColor='#01A1FF'
        height={100}
        width={100}
        timeout={10000} //3 secs
      />
        </div>
    )
}

export default Spinner
