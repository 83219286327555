import React from "react";
import { HiOutlineChevronLeft } from "react-icons/hi";
import Navbar from "../../../../components/Navbar/Navbar";
import Tag from "../../../../assets/icons/Fees.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { DateAndTime, timeConvert , defaultUserImage } from "../../../../services/Constants";
import moment from 'moment'

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  

  

  //go to previous page
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="Home bg-Light-gray min-h-screem w-full">
      <Navbar heading="Home / Search Results / Doctor Profile / Payment" />
      <div>
        {/* heading */}
        <div className="flex text-text m-4 items-center">
          <div
            className="bg-white cursor-pointer rounded-lg p-2 mx-3 shadow-back md:text-lg font-bold"
            onClick={goBack}
          >
            <HiOutlineChevronLeft />
          </div>
          <h1 className="md:text-lg font-bold">Appointment Booked</h1>
        </div>
      </div>
      {/* card container */} 
      <div className="flex grow flex-col items-center justify-center mt-5">
        <div className="bg-white md:w-[40%] md:min-w-1/3 px-3 py-5 rounded-lg w-max shadow-back">
          <div className="py-5 border-b font-bold text-center text-lg">
            <h1 className="text-primary text-center tracking-widest ">
              Congratulations!
            </h1>
            <p className="text-text ">Appointment is Booked</p>
          </div>

          {/* charges */}
          <div className="flex justify-between items-center my-5">
            <div className="flex items-center">
              <span className="text-white bg-primary rounded-lg p-3 mx-3">
                {state.bookingDate.slice(8, 10)}
              </span>
              <div className="text-text font-bold text-sm">
                <p> {moment(state.bookingDate.slice(0, 10)).format('MMMM DD , YYYY')} </p>
                <p>{`${
                  timeConvert(state.bookingDateAndTime.slice(11, 16))
                } - ${timeConvert(state.bookingEndTime.slice(11, 16))}`}</p>
              </div>
            </div>

            <div className="flex items-center">
              <img src={Tag} alt="tag" />
              <div className="mx-3 text-sm text-text">
                <p className="font-bold">Fee</p>
                <p> $ {state.fee}</p>
              </div>
            </div>
          </div>

          <div className="relative my-5">
            <p className="text-center bg-white px-3 rounded-full absolute md:mx-24 left-32 md:left-20 z-20">
              with
            </p>
            <span className="border-t border-darkMain2 w-full absolute top-3"></span>
          </div>

          <div className="flex justify-between items-center mt-14 py-8  text-text">
            <div className="flex space-x-2  items-center">
              <img
                className="rounded-full object-cover h-14"
                src={state?.doctorId?.profilePic?.thumbnail || defaultUserImage }
                alt="doctor"
              />
              <div className="text-sm ">
                <p className="font-bold">
                  {state?.doctorId?.firstName} {state?.doctorId?.lastName}
                </p>
                <p>{state?.doctorId?.email}</p>
              </div>
            </div>
            <button
              className="rounded-lg px-3 ml-1 py-2 bg-darkMain2"
              onClick={() =>
                navigate("/home/DoctorProfile", {
                  state: { id: state?.doctorId?._id },
                })
              }
            >
              View Profile
            </button>
          </div>
        </div>

        <Link
          to="/home/appointment"
          className="bg-primary text-center p-3 text-white rounded-lg m-4 md:w-1/3  "
        >
          My Appointments
        </Link>
      </div>
    </div>
  );
};

export default PaymentSuccess;
