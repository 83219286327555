import React, { useState, useContext, useEffect } from "react";
import { HiChevronDoubleRight, HiChevronDoubleLeft } from "react-icons/hi";
import Header from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../services/Context/UserContext";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const Medical = () => {
  // document.title = "SignUp - MyHCare Patients";
  document.title = "SignUp - TeleSecure360 Patients";

  //tailwind classes for select input
  // const selectClass = "rounded-lg py-3 px-5 my-3 md:w-96 focus:outline-primary";
  const selectClass = "rounded-lg py-3 px-5 my-3 w-60 focus:outline-primary";

  const disesaseList = [
    "HIV/AIDS",
    "Arthritis",
    "Chronic Pain",
    "Loss of Appetite",
    "nausea",
    "Muscle Spasm",
    "Glaucoma",
    "Weight Loss",
    "Fibromyalgia",
    "Migraine Headaches",
    "Cancer",
    "Seizures",
    "Trouble Sleeping",
    "Anxiety",
    "Other",
  ];
  let [disease, setdisease] = useState([]);
  let navigate = useNavigate();
  const { userInfo, setuserInfo } = useContext(UserContext);
  const { medicalRecord } = userInfo;

  
  
  

  //states
  const [medicalInfo, setmedicalInfo] = useState({});
  const [startDate, setStartDate] = useState(new Date());

  // const [medicalQuestions, setmedicalQuestions] = useState([
  //   {
  //     question:
  //       "Are you RENEWING your certiﬁcation (Have you had a certiﬁcation in the last 10 years)*",
  //     answer: medicalRecord?.Questions ? medicalRecord.Questions[0].answer : "",
  //   },
  //   {
  //     question:
  //       "Do you currently use speciﬁc medications for your medical condition?",
  //     answer: medicalRecord?.Questions ? medicalRecord.Questions[1].answer : "",
  //   },
  //   {
  //     question: "Are you taking any prescription medications or herbs?",
  //     answer: medicalRecord?.Questions ? medicalRecord.Questions[2].answer : "",
  //   },
  //   {
  //     question: "Do you have any allergies to any medications?",
  //     answer: medicalRecord?.Questions ? medicalRecord.Questions[3].answer : "",
  //   },
  //   {
  //     question: "Have you ever had any surgeries or been hospitalized?",
  //     answer: medicalRecord?.Questions ? medicalRecord.Questions[4].answer : "",
  //   },
  //   {
  //     question: "Do you exercise?",
  //     answer: medicalRecord?.Questions ? medicalRecord.Questions[5].answer : "",
  //   },
  //   {
  //     question: "Do you smoke tobacco?",
  //     answer: medicalRecord?.Questions ? medicalRecord.Questions[6].answer : "",
  //   },
  //   {
  //     question: "Do you drink alcohol?",
  //     answer: medicalRecord?.Questions ? medicalRecord.Questions[7].answer : "",
  //   },
  //   {
  //     question:
  //       "Are there health/medical problems that occure frequently in your family?",
  //     answer: medicalRecord?.Questions ? medicalRecord.Questions[8].answer : "",
  //   },
  //   {
  //     question: "Do you have a primary care provider?",
  //     answer: medicalRecord?.Questions ? medicalRecord.Questions[9].answer : "",
  //   },
  //   {
  //     question:
  //       "When was the last time you saw your doctor/specialist about these complaints?",
  //     answer: "",
  //   },
  //   {
  //     question: "Provide details on the medical condition and diagnosis",
  //     answer: medicalRecord?.Questions
  //       ? medicalRecord.Questions[11].answer
  //       : "",
  //   },
  // ]);

  const [medicalQuestions, setmedicalQuestions] = useState([
    {
      question:
        "Are you RENEWING your certiﬁcation (Have you had a certiﬁcation in the last 10 years)*",
      answer: userInfo?.medicalRecord?.Questions ? userInfo?.medicalRecord.Questions[0].answer : "",
    },
    {
      question:
        "Do you currently use speciﬁc medications for your medical condition?",
      answer: userInfo?.medicalRecord?.Questions ? userInfo?.medicalRecord.Questions[1].answer : "",
    },
    {
      question: "Are you taking any prescription medications or herbs?",
      answer: userInfo?.medicalRecord?.Questions ? userInfo?.medicalRecord.Questions[2].answer : "",
    },
    {
      question: "Do you have any allergies to any medications?",
      answer: userInfo?.medicalRecord?.Questions ? userInfo?.medicalRecord.Questions[3].answer : "",
    },
    {
      question: "Have you ever had any surgeries or been hospitalized?",
      answer: userInfo?.medicalRecord?.Questions ? userInfo?.medicalRecord.Questions[4].answer : "",
    },
    {
      question: "Do you exercise?",
      answer: userInfo?.medicalRecord?.Questions ? userInfo?.medicalRecord.Questions[5].answer : "",
    },
    {
      question: "Do you smoke tobacco?",
      answer: userInfo?.medicalRecord?.Questions ? userInfo?.medicalRecord.Questions[6].answer : "",
    },
    {
      question: "Do you drink alcohol?",
      answer: userInfo?.medicalRecord?.Questions ? userInfo?.medicalRecord.Questions[7].answer : "",
    },
    {
      question:
        "Are there health/medical problems that occure frequently in your family?",
      answer: userInfo?.medicalRecord?.Questions ? userInfo?.medicalRecord.Questions[8].answer : "",
    },
    {
      question: "Do you have a primary care provider?",
      answer: userInfo?.medicalRecord?.Questions ? userInfo?.medicalRecord.Questions[9].answer : "",
    },
    {
      question:
        "When was the last time you saw your doctor/specialist about these complaints?",
      answer: "",
    },
    {
      question: "Provide details on the medical condition and diagnosis",
      answer: userInfo?.medicalRecord?.Questions
        ? userInfo?.medicalRecord.Questions[11].answer
        : "",
    },
  ]);

  useEffect(() => {
    if (userInfo?.medicalRecord?.Questions) {
      setStartDate(new Date(medicalRecord?.Questions[10]?.answer))
    }
  }, [userInfo?.medicalRecord?.Questions])

  //functions and statehandler
  const MedicalInputHandler = (e) => {
    
    let name = e.target.name;
    
    // setpersonalInfo({...personalInfo , [name]:e.target.value})

    medicalQuestions.map((item, i) => {
      if (name == item.question) {
        
        const stateCopy = JSON.parse(JSON.stringify(medicalQuestions));
        stateCopy[i].answer = e.target.value;
        setmedicalQuestions(stateCopy);
      }
    });
  };

  const DiseaseCheck = (e) => {
    
    if (e.target.checked) {
      setdisease([...disease, e.target.id]);
    }
  };

  const FormSubmit = (e) => {
    e.preventDefault();
    
    
    setmedicalInfo({
      ...medicalInfo,
      DiseaseList: disease,
      medicalQuestion: medicalQuestions,
    });

    

    setuserInfo((prevState) => ({
      ...prevState,
      medicalRecord: {
        DiseaseList: [...disease],
        Questions: medicalQuestions,
      },
    }));
    navigate("/signup/esign", {
      state: {
        DiseaseList: disease,
        Questions: medicalQuestions,
        personal: userInfo.personalRecord,
      },
    });
  };

  // Previous button handler
  const PreviousPage = () => {
    
    navigate("/signup/personal", { replace: true });
  };

  useEffect(() => {
    let newArr = [...medicalQuestions]; // copying the old datas array
    newArr[10].answer = startDate.toISOString().substring(0, 10);

    setmedicalQuestions(newArr);
  }, [startDate]);

  return (
    <>
      <div className="md:mx-32">
        <Header />
        <div className="m-3 bg-gray rounded-lg min-h-screen min-w-screen px-3 p-3 text-text md:p-3 md:py-5">
          <form className="md:ml-10" onSubmit={FormSubmit}>
            <div>
              <h5>
                What is/are the main medical problem(s) which you currently have
                or had In the past?
              </h5>
              <div className="grid grid-cols-2 mt-5 gap-y-1 md:grid-cols-7 md:gap-y-5">
                {disesaseList.map((item, i) => {
                  return (
                    <div key={i}>
                      <input
                        id={item}
                        type="checkbox"
                        defaultChecked={
                          // medicalRecord?.DiseaseList &&
                          // medicalRecord.DiseaseList.includes(item)
                          userInfo?.medicalRecord?.DiseaseList &&
                          userInfo?.medicalRecord.DiseaseList.includes(item)
                        }
                        onChange={DiseaseCheck}
                      />
                      <label className="mx-3">{item}</label>
                    </div>
                  );
                })}
              </div>
              <div className="mt-5">
                <div className="flex flex-col  md:w-3/4 md:justify-between md:flex-row md:space-x-5">
                  <h5>
                    Are you RENEWING your certiﬁcation <br />
                    (Have you had a certiﬁcation in the last 10 years)
                  </h5>
                  <select
                    name="Are you RENEWING your certiﬁcation (Have you had a certiﬁcation in the last 10 years)*"
                    className={selectClass}
                    id="certificate renew"
                    onChange={MedicalInputHandler}
                    defaultValue={
                      medicalRecord?.Questions
                        ? medicalRecord.Questions[0].answer
                        : ""
                    }
                    required
                  >
                    <option value="" selected disabled hidden>
                      Select Answer
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div className="flex flex-col  md:w-3/4 md:justify-between md:flex-row md:space-x-5">
                  <h5>
                    Do you currently use speciﬁc medications for your medical
                    condition?
                  </h5>
                  <select
                    name="Do you currently use speciﬁc medications for your medical condition?"
                    // className={selectClass}
                    className="rounded-lg py-3 px-5 my-3 w-64 focus:outline-primary"
                    id="medication usage"
                    onChange={MedicalInputHandler}
                    defaultValue={
                      medicalRecord?.Questions
                        ? medicalRecord.Questions[1].answer
                        : ""
                    }
                    required
                  >
                    <option value="" selected disabled hidden>
                      Select Answer
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div className="flex  md:w-3/4 md:justify-between md:items-center flex-col md:flex-row md:space-x-5">
                  <h5>
                    Are you taking any prescription medications or herbs?
                  </h5>
                  <select
                    name="Are you taking any prescription medications or herbs?"
                    className={selectClass}
                    id="taking medications"
                    onChange={MedicalInputHandler}
                    defaultValue={
                      medicalRecord?.Questions
                        ? medicalRecord.Questions[2].answer
                        : ""
                    }
                    required
                  >
                    <option value="" selected disabled hidden>
                      Select Answer
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div className="flex  md:w-3/4 md:justify-between md:items-center flex-col md:flex-row md:space-x-5">
                  <h5>Do you have any allergies to any medications?</h5>
                  <select
                    name="Do you have any allergies to any medications?"
                    className={selectClass}
                    id="allergies"
                    onChange={MedicalInputHandler}
                    defaultValue={
                      medicalRecord?.Questions
                        ? medicalRecord.Questions[3].answer
                        : ""
                    }
                    required
                  >
                    <option value="" selected disabled hidden>
                      Select Answer
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div className="flex  md:w-3/4 md:justify-between md:items-center flex-col md:flex-row md:space-x-5">
                  <h5>
                    Have you ever had any surgeries or been hospitalized?
                  </h5>
                  <select
                    name="Have you ever had any surgeries or been hospitalized?"
                    className={selectClass}
                    id="hospitalized"
                    onChange={MedicalInputHandler}
                    defaultValue={
                      medicalRecord?.Questions
                        ? medicalRecord.Questions[4].answer
                        : ""
                    }
                    required
                  >
                    <option value="" selected disabled hidden>
                      {" "}
                      Select Answer{" "}
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div className="flex  md:w-3/4 md:justify-between md:items-center flex-col md:flex-row md:space-x-5">
                  <h5>Do you exercise?</h5>
                  <select
                    name="Do you exercise?"
                    className={selectClass}
                    id="Exercise"
                    onChange={MedicalInputHandler}
                    defaultValue={
                      medicalRecord?.Questions
                        ? medicalRecord.Questions[5].answer
                        : ""
                    }
                    required
                  >
                    <option value="" selected disabled hidden>
                      {" "}
                      Select Answer{" "}
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div className="flex  md:w-3/4 md:justify-between md:items-center flex-col md:flex-row md:space-x-5">
                  <h5>Do you smoke tobacco?</h5>
                  <select
                    name="Do you smoke tobacco?"
                    className={selectClass}
                    id="tobacco"
                    onChange={MedicalInputHandler}
                    defaultValue={
                      medicalRecord?.Questions
                        ? medicalRecord.Questions[6].answer
                        : ""
                    }
                    required
                  >
                    <option value="" selected disabled hidden>
                      {" "}
                      Select Answer{" "}
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div className="flex  md:w-3/4 md:justify-between md:items-center flex-col md:flex-row md:space-x-5">
                  <h5>Do you drink alcohol?</h5>
                  <select
                    name="Do you drink alcohol?"
                    className={selectClass}
                    id="alcohol"
                    onChange={MedicalInputHandler}
                    defaultValue={
                      medicalRecord?.Questions
                        ? medicalRecord.Questions[7].answer
                        : ""
                    }
                    required
                  >
                    <option value="" selected disabled hidden>
                      {" "}
                      Select Answer{" "}
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div className="flex  md:w-3/4 md:justify-between md:items-center flex-col md:flex-row md:space-x-5">
                  <h5>
                    Are there health/medical problems that occure frequently in
                    your family?
                  </h5>
                  <select
                    name="Are there health/medical problems that occure frequently in your family?"
                    // className={selectClass}
                    className="rounded-lg py-3 px-5 my-3 w-64 focus:outline-primary"
                    id="health problems"
                    onChange={MedicalInputHandler}
                    defaultValue={
                      medicalRecord?.Questions
                        ? medicalRecord.Questions[8].answer
                        : ""
                    }
                    required
                  >
                    <option value="" selected disabled hidden>
                      {" "}
                      Select Answer{" "}
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div className="flex  md:w-3/4 md:justify-between md:items-center flex-col md:flex-row md:space-x-5">
                  <h5>Do you have a primary care provider?</h5>
                  <select
                    name="Do you have a primary care provider?"
                    className={selectClass}
                    id="primary care provider"
                    onChange={MedicalInputHandler}
                    defaultValue={
                      medicalRecord?.Questions
                        ? medicalRecord.Questions[9].answer
                        : ""
                    }
                    required
                  >
                    <option value="" selected disabled hidden>
                      {" "}
                      Select Answer{" "}
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div className="flex  md:w-3/4 md:justify-between md:items-center flex-col md:flex-row md:space-x-5">
                  <h5>
                    When was the last time you saw your doctor/specialist about
                    these complaints?(mm/dd/yyyy)
                  </h5>
                  <div>
                    <DatePicker
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={50}
                      selected={startDate}
                      className={selectClass}
                      onChange={(date) => {
                        setStartDate(date);
                      }}
                      maxDate={new Date()}
                      minDate={userInfo?.personalRecord?.DOB}
                    />
                  </div>
                </div>

                <h5 className="font-semibold text-base mb-5">
                  YOU WILL HAVE AN ABILITY TO UPLOAD TO YOUR PORTAL PREVIOUS
                  RECOMMENDATION OR ANY OTHER MEDICAL RECORDS AS RELATED TO THE
                  MEDICAL CONDITION(S) IDENTIFIED ABOVE.
                </h5>

                <div className="flex  md:w-3/4 md:justify-between md:items-center flex-col md:flex-row md:space-x-5">
                  <h5>
                    Provide details on the medical condition and diagnosis
                  </h5>
                  <textarea
                    name="Provide details on the medical condition and diagnosis"
                    placeholder="Enter your details"
                    className="rounded-lg min-w-screen p-3 md:w-1/2 focus:outline-primary"
                    id="details"
                    defaultValue={
                      medicalRecord?.Questions
                        ? medicalRecord.Questions[11].answer
                        : ""
                    }
                    onChange={MedicalInputHandler}
                    required
                  />
                </div>

                <div className="mt-5 flex justify-center space-x-5 md:justify-end">
                  <button
                    className="flex items-center justify-center px-5 py-3 bg-white border border-primary rounded-lg"
                    onClick={PreviousPage}
                  >
                    <HiChevronDoubleLeft className="text-lg mr-3" />
                    Previous{" "}
                  </button>
                  <button
                    type="submit"
                    className="flex items-center justify-center px-5 py-3 bg-secondary  rounded-lg text-white"
                  >
                    Next <HiChevronDoubleRight className="text-lg ml-3" />
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Medical;
