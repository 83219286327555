import React from "react";
import logo from "../../assets/images/Logo-Horizontal.svg";
import { HiChevronLeft } from "react-icons/hi";
import { BsDot } from "react-icons/bs";

const Terms = () => {
  const disease = [
    "Short term memory loss",
    " Anxiety/Nervousness",
    " Irregular Heart beat",
    " Dry Mouth",
    "Slower reaction time",
    "Poor physical coordination",
    " Hunger",
    "Loss of Appetite",
    "Dizziness",
    "Cough",
    " Dependency",
    "Confusion",
    " Impaired Vision",
    "Feeling of Euphoria",
    " Tiredness Drowsiness",
    " Headache",
    "Nausea/Vomiting",
    "Apathy",
    " Change in sleeping patterns",
    "Numbness",
    "Laryngitis",
    "Bronchitis",
    "Depression",
    "Agitation/Irritability",
    "Trouble Concentrating",
    " High or Low blood pressure",
    " Sedation",
    "Difficulty completing complex tasks",
    "Inability to concentrate",
    "Paranoia, psychotic symptoms (i.e., delusions)",
    "Suppression of immune system (ft)Talkativeness",
    "Impairment of motor skills, coordination, and reaction time",
  ];

  const symptoms = [
    "Start to feel sad or have crying spells",
    "Lose interest in my usual activities",
    "Have changes in my normal sleep patterns",
    "Lose my appetite",
    "Withdraw from family and friends",
    "Become more irritable than usual",
    "Become pregnant",
    "Become unusually tired",
  ];

  return (
    <div className="min-h-screen bg-gray text-text">
      {/* ----------Header section-------- */}
      <div className="bg-white shadow px-2 py-4 flex justify-between items-center">
        <div className="flex items-center">
          <span
            className="bg-white shadow rounded-lg p-2 text-lg mx-3 cursor-pointer font-bold"
            onClick={() => window.history.back()}
          >
            <HiChevronLeft />
          </span>
          <img src={logo} alt="logo" className="h-auto w-auto" />
        </div>
        <a
          className="bg-primary text-white p-3 rounded-lg hidden md:block "
          href="https://kifdoctors.com/"
          target="_blank"
        >
          <span>Go to KIF Doctors</span>{" "}
        </a>
      </div>
      {/* ----------title section-------- */}
      <div className="m-4 bg-white rounded-lg shadow-back p-8">
        <h1 className="font-bold text-center text-lg my-3">
          Informed Consent and Release of Liability Form
        </h1>
        <p >
          Please read each item and e-sign below to indicate you understand the
          information regarding the risks and side effects of using marijuana. I
          agree to tell the attending physician if I do not understand any of
          the information provided.
        </p>
        <p className="font-bold">Patient understands the following:</p>
        <p>
          I understand that the cultivation, possession and use of marijuana,
          even for medical purposes, are currently ILLEGAL UNDER FEDERAL LAW.{" "}
          <br className="my-2" />
          Under federal and state law, it is not permitted to use or possess
          marijuana within 1000 feet of a daycare or school. I agree not to do
          so. <br className="my-2" />
          I agree not to use marijuana while under the influence of alcohol.
          <br className="my-2" />I understand that it is against the law to drive a vehicle while
          using marijuana and that doing so will result in a DUI for driving
          under the influence
        </p>
        <p>
          I agree to tell the attending physician if I have ever had symptoms of
          depression, been psychotic, attempted suicide or had any other mental
          problems. I also agree to tell the attending physician if I have ever
          been prescribed or taken medicine for any of the conditions stated
          above. Furthermore, I understand that the attending physician does not
          suggest nor condone that I cease treatment and/or medication that
          stabilize my mental or physical condition I agree to immediately stop
          using marijuana and inform the physician in the event I become
          suicidal, homicidal, paranoid or have an increase in any of these
          symptoms. <br className="my-2" />
          There are few known interactions between marijuana and medications or
          other herbs. However, very few interactions between herbs and
          medications have been studied. I agree to tell my attending physician
          if I am using any herbs, supplements or other medications. <br className="my-2" />
          Some users might develop a tolerance to marijuana. This means higher
          and higher does are required to achieve the same clinical effect. It
          is recommended for doctors to have an intermission with drug for at
          least 3 weeks every 3-4 months. If I think I may be developing a
          tolerance to marijuana. I will notify my attending physician. Should
          respiratory problems or other ill effects be experienced in
          association with the use of medical marijuana, I agree to discontinue
          its use and report any or report such problems or effects to the
          attending physician. Although smoking marijuana has not been linked to
          lung cancer, smoking marijuana can cause respiratory harm, such as
          bronchitis. Many researchers agree that marijuana smoke contains known
          carcinogens (chemicals that can cause cancer), and that smoking
          marijuana may increase the risk of respiratory diseases and cancers of
          the lungs, mouth and tongue. I have been advised that cannabis
          (medical marijuana) smoke contain chemicals known as tars that may be
          harmful to my health. Vaporizers may substantially reduce many of the
          potentially harmful smoke and toxins that are normally present in
          marijuana smoke.
          <br className="my-2" />
          Marijuana varies in potency. The effects of marijuana can also vary
          with the delivery system. Estimating the proper marijuana dosage is
          very important. Symptoms of marijuana overdose include, but are not
          limited to nausea, vomiting, hacking cough, disturbances to heart
          rhythms and numbness in the limbs.
          <br className="my-2" />
          The efficacy and potency of marijuana varies widely depending on the
          marijuana strain and ingestion method. Under federal law, the
          attending physician is unable to discuss dosage. <br className="my-2" />
          I am not currently pregnant. In the event I become pregnant, I agree
          to immediately discontinue use of marijuana and contact the physician.
          (Females only) <br className="my-2" />
          Cannabis is not regulated by the USFDA and therefore may contain
          unknown quantities of active ingredients, impurities and or
          contaminants. <br className="my-2" />I understand that side effects may occur while I
          am taking medical marijuana can include but are not limited to:
        </p>
        <ul className="my-4">
          {disease.map((item) => (
            <li className="flex items-center gap-4">
              {" "}
              <BsDot className="text-lg" />
              {item}
            </li>
          ))}
        </ul>
        <p>
          I understand there may be benefits and risks associated with marijuana
          use that have not been identified. <br className="my-2" />
          If I start taking medical marijuana, I agree to immediately tell my
          physician if I:
        </p>
        <ul className="my-4">
          {symptoms.map((item) => (
            <li className="flex items-center gap-4">
              {" "}
              <BsDot className="text-lg" />
              {item}
            </li>
          ))}
        </ul>
        <p>
          Your personal statement regarding the provided facts: <br className="my-2" />
          I, confirm that the information provided by me regarding my diagnosis
          and medical records is true and correct.
        </p>
      </div>
    </div>
  );
};

export default Terms;
