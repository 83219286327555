import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import { useNavigate, useLocation } from "react-router-dom";
import { HiOutlineChevronLeft ,  HiLocationMarker } from "react-icons/hi";
import { GoPrimitiveDot } from "react-icons/go";
import { AiFillTag , AiFillCheckCircle , AiOutlineRight} from 'react-icons/ai'
import {BsCreditCard} from 'react-icons/bs'
import { FaStripe} from 'react-icons/fa'
import { BaseAPI, BaseURL } from "../../../services/services";
import { toast } from "react-toastify";
import Spinner from "../../../components/Loader/Spinner";
import Spin from "../../../components/Loader/Spin";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { CardBrands } from "../../../utils";
import  { defaultUserImage } from '../../../services/Constants'

const paymentInputStyles = {
  base: {
    fontFamily: "'Poppins', Helvetica, Arial, serif",
    fontSize: "12px",
  },
};


const stripePromise = loadStripe(
  "pk_test_51PeC8wFRt3G7hN0WZNZcKex5v8iON99ZeJNR0Ivlo9xzC4DlUtbv15tew2PyAVgavrh7WOtAWCdGhilbYBIwWav500tDGdXNJR"
);



function CheckoutForm({ clientSecret }) {
  const stripe = useStripe();
  const elements = useElements();

  const location = useLocation();
  const navigate = useNavigate();

  const { state } = location;

  const { fee, profile, dateString, paymentPending, aptType, booking_id, doctorAddress, ...rest } = state;


  const {specialityName} =profile?.specialities.length > 0 && profile?.specialities?.find( el => el._id === rest.specialityId)
  const doctorName = `${profile.firstName} ${profile.lastName}`

  //validation schema for form
  const validationSchema = Yup.object({
    selectedCard : Yup.string().when('isFieldActive', {
      is: (selectedCard) => selectedCard === false,
      then: Yup.string().required('Either add new card or select from saved cards.')
    }),
  cardNumber: Yup.boolean().when('isFieldActive', {
    is: (selectedCard) => selectedCard === true,
    then: Yup.boolean().required('Card Number is required')
  }),
  expiry: Yup.boolean().when('isFieldActive', {
    is: (selectedCard) => selectedCard === true,
    then: Yup.boolean().required('Expiry date is required')
  }),
  cvv: Yup.boolean().when('isFieldActive', {
    is: (selectedCard) => selectedCard ===true,
    then: Yup.boolean().required('CVV is required')
  }),
  paymentMethod: Yup.string().required("Please select the card type"),
  holderName: Yup.string().when('isFieldActive', {
    is: (selectedCard) => selectedCard ===true,
    then: Yup.string().required('Name is required')
  }),
});
  

  const [loading, setLoading] = useState(false);
  const [paymentComplete, setPaymentComplete] = useState(false);
  const [savedCards, setSavedCards] = useState(null);
  const [cardCount, setCardCount] = useState(0);
  const [cardBrand, setCardBrand] = useState("placeholder");
  const [showPaymentFields , setShowFields] = useState(false)
  const [taxList , setTaxList] = useState([]);
  const [doctor_state_tax , setDoctor_state_tax] = useState(null);


  useEffect(() => { 
    const token = localStorage.getItem("token");

    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
      "Content-Type": "application/json",
    });

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    BaseAPI(`${BaseURL}/admin/getAllTaxRates?limit=10000`, requestOptions)
      .then((result) => {
        setTaxList(result?.data?.data)
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      })
  }, [])

  useEffect( async() => {
    filterTaxrate();
  }, [taxList])

  const filterTaxrate = () => {
    if (taxList.length > 0) {
      const filteredTax = taxList?.filter((data) => data?.state === doctorAddress[0]?.state);

      if (filteredTax.length > 0) {
        setDoctor_state_tax(filteredTax[0]?.taxRateInPercentage);
      } else {
        setDoctor_state_tax(0);
      }

    }
  }

  const calculatedTaxAmount = (Number(fee) * Number(doctor_state_tax)) / 100;

  const totalAmountIncludingTax = Number(fee) + Number(calculatedTaxAmount);

  const handleBrandChange = (brandName) => {
    if (CardBrands[brandName]) setCardBrand(brandName);
    else setCardBrand("placeholder");
  };

  const createAppointment = (paymentInfo = null, state = null) => {
    

    const token = localStorage.getItem("token");



    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
      "Content-Type": "application/json",
    });

    

    var rawData = {
      ...rest,
      aptType: aptType,
      paymentMethodId: paymentInfo?.setupIntent?.payment_method,
    };

    var raw = JSON.stringify(rawData);

    

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    BaseAPI(`${BaseURL}/booking/createAppointment`, requestOptions)
      .then((result) => {
        
        if (result.status === 200) {
          toast.success("Payment Successful ! Appointment Created Successfully" , {
            icon : <AiFillCheckCircle className="text-secondary text-lg" />
          });
          createInvoice(result?.data?._id);
          setTimeout(() => {
            // navigate("/home/success", { state: { ...result.data, fee } });
            navigate("/home/success", { state: { ...result.data, fee, totalAmountIncludingTax, calculatedTaxAmount } });
          }, 1000);
        } else {
          toast.error(result.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createInvoice = (id) => {

    const token = localStorage.getItem("token");

    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
      "Content-Type": "application/json",
    });

    const raw = JSON.stringify({
      bookingId: id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    BaseAPI(`${BaseURL}/doctor/createInvoice`, requestOptions)
      .then((result) => {
        if (result?.status === 200) {
          toast.success("Invoice generated! Invoice generated for the appointment" , {
            icon : <AiFillCheckCircle className="text-secondary text-lg" />
          });
        } else {
          toast.error("Something went wrong!");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  }

  const handlePendingPayment = (paymentInfo = null, state = null) => {
    
    const token = localStorage.getItem("token");

    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
      "Content-Type": "application/json",
    });

    var rawData = {
      // ...rest,
      booking_id: booking_id,
      paymentMethodId: paymentInfo?.setupIntent?.payment_method,
      // status: "1",
      clinicId: state?.clinicId
    };

    var raw = JSON.stringify(rawData);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    BaseAPI(`${BaseURL}/booking/acceptRejectAppointment`, requestOptions)
      .then((result) => {
        
        if (result.status === 200) {
          toast.success("Payment Successful" , {
            icon : <AiFillCheckCircle className="text-secondary text-lg" />
          });
          createInvoice(booking_id);
          setTimeout(() => {
            navigate("/home/appointment");
          }, 1000);
        } else {
          toast.error(result.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSubmit = async (values) => {
    

    setLoading(true);

    let stripeResponse;

    const cardData = elements.getElement(CardNumberElement);

    // console.log(cardData);

    // console.log(clientSecret);

    // console.log(values.selectedCard)

    

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if(values.selectedCard){
      console.log('Selected Card')
      stripeResponse = await stripe.confirmCardSetup(clientSecret, {
        payment_method: values.selectedCard,
      })
      // .then((result) => createAppointment(result, state));
    }else{
      console.log('New Card')
      stripeResponse = await stripe
    .confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardData,
      },
    })
    // .then((result) => createAppointment(result, state));
    }

    

    

    if (stripeResponse.error) {
      console.log(stripeResponse.error);
      toast.error('Somthing went wrong !')
      setLoading(false);
    } else {
      // if (paymentPending !== undefined) {
      // console.log("Payment Done for unpaid appt", stripeResponse);
      // handlePendingPayment(stripeResponse, state)
      // } else{
      // createAppointment(stripeResponse, state)
      // console.log("Payment Done", stripeResponse);
      // }

      if (paymentPending !== undefined) {
        // console.log("Payment Done for unpaid appt", stripeResponse);

        const setupIntentId = stripeResponse.setupIntent.id;

          // Send the setupIntentId to Stripe to retrieve the customer ID
          const response = await fetch(`https://api.stripe.com/v1/setup_intents/${setupIntentId}`, {
            method: 'GET',
            headers: {'Authorization': `Bearer sk_test_51PeC8wFRt3G7hN0WuDXTcpLcndTJ44i6xbWtW1QoxBpwWDVNki8cK8fTxwrfySEIGM5h7QQCVdcHFeXAG83zBvVe003GLgOy6o`,},
          });
          
          const data = await response.json();
          
          // Ensure you handle errors appropriately
          if (data.error) {
            console.error('Error retrieving customer ID:', data.error.message);
          }

        const customerData = savedCards?.filter((ele) => ele.id === stripeResponse?.setupIntent?.payment_method)

          let myHeaders = new Headers({
              accept: 'application/json',
              authorization: `Bearer sk_test_51PeC8wFRt3G7hN0WuDXTcpLcndTJ44i6xbWtW1QoxBpwWDVNki8cK8fTxwrfySEIGM5h7QQCVdcHFeXAG83zBvVe003GLgOy6o`,
              'content-type': 'application/x-www-form-urlencoded',
          });

          const payload = new URLSearchParams({
              // "amount": `${parseInt(fee) * 100}`,
              // "amount": `${parseInt(totalAmountIncludingTax) * 100}`,
              "amount": `${Number(totalAmountIncludingTax) * 100}`,
              "currency": 'usd',
              "payment_method_types[]": `${stripeResponse?.setupIntent?.payment_method_types}`,
              "payment_method": `${stripeResponse?.setupIntent?.payment_method}`,
              "off_session": "true",
              "confirm": "true",
              // "customer": `${customerData[0]?.customer}`,
              // "customer": `${values.selectedCard ? customerData[0]?.customer : savedCards[0]?.customer ? savedCards[0]?.customer : state?.patientId}`,
              "customer": `${values.selectedCard ? customerData[0]?.customer : data.customer }`,
              "description": 'Paying for appointment booking',
              "metadata[patientId]" : `${state?.patientId}`,
              "metadata[doctorId]" : `${state?.doctorId}`,
              "metadata[clinicId]" : `${state?.clinicId}`,
              "metadata[taxInPercentage]" : `${Number(doctor_state_tax)}`,
              "metadata[tax]" : `${calculatedTaxAmount}`,
              "metadata[appointmentFee]" : `${parseInt(fee)}`,
          });

          var requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: payload,
          };

          BaseAPI(`https://api.stripe.com/v1/payment_intents`, requestOptions)
              .then((result) => {
                console.log('result', result);
                handlePendingPayment(stripeResponse, state)
              })
              .catch((err) => console.log(err))
              .finally(() => { });
        // handlePendingPayment(stripeResponse, state)

        } else{

          const setupIntentId = stripeResponse.setupIntent.id;

          // Send the setupIntentId to Stripe to retrieve the customer ID
          const response = await fetch(`https://api.stripe.com/v1/setup_intents/${setupIntentId}`, {
            method: 'GET',
            headers: {'Authorization': `Bearer sk_test_51PeC8wFRt3G7hN0WuDXTcpLcndTJ44i6xbWtW1QoxBpwWDVNki8cK8fTxwrfySEIGM5h7QQCVdcHFeXAG83zBvVe003GLgOy6o`,},
          });
          
          const data = await response.json();
          
          // Ensure you handle errors appropriately
          if (data.error) {
            console.error('Error retrieving customer ID:', data.error.message);
          }

          const customerData = savedCards?.filter((ele) => ele.id === stripeResponse?.setupIntent?.payment_method)

          let myHeaders = new Headers({
              accept: 'application/json',
              authorization: `Bearer sk_test_51PeC8wFRt3G7hN0WuDXTcpLcndTJ44i6xbWtW1QoxBpwWDVNki8cK8fTxwrfySEIGM5h7QQCVdcHFeXAG83zBvVe003GLgOy6o`,
              'content-type': 'application/x-www-form-urlencoded',
          });

          const payload = new URLSearchParams({
              // "amount": `${parseInt(fee) * 100}`,
              // "amount": `${parseInt(totalAmountIncludingTax) * 100}`,
              "amount": `${Number(totalAmountIncludingTax) * 100}`,
              "currency": 'usd',
              "payment_method_types[]": `${stripeResponse?.setupIntent?.payment_method_types}`,
              "payment_method": `${stripeResponse?.setupIntent?.payment_method}`,
              "off_session": "true",
              "confirm": "true",
              "customer": `${values.selectedCard ? customerData[0]?.customer : data.customer }`,
              "description": 'Paying for appointment booking',
              "metadata[patientId]" : `${state?.patientId}`,
              "metadata[doctorId]" : `${state?.doctorId}`,
              "metadata[clinicId]" : `${state?.clinicId}`,
              "metadata[taxInPercentage]" : `${Number(doctor_state_tax)}`,
              "metadata[tax]" : `${calculatedTaxAmount}`,
              "metadata[appointmentFee]" : `${parseInt(fee)}`,
          });

          var requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: payload,
          };

          BaseAPI(`https://api.stripe.com/v1/payment_intents`, requestOptions)
              .then((result) => {
                createAppointment(stripeResponse, state)
              })
              .catch((err) => console.log(err))
              .finally(() => { });
        }
    }
  };

  if (paymentComplete) {
    
  }

  const getSavedCards = () => {

    const token = localStorage.getItem("token");

    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
    });

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    BaseAPI(`${BaseURL}/users/getListOfCards`, requestOptions)
      .then((result) => {
        console.log(result.data);
        setSavedCards(result.data);
        if(result.data.length === 0){
          setShowFields(true)
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };

  const changeCardPage = () => {
    if (cardCount + 3 <= savedCards.length) {
      setCardCount((prev) => prev + 3);
      console.log(cardCount, savedCards.length);
    } else {
      setCardCount((prev) => prev * 0);
    }
  };

  const SavedCardArray = () => {

    let paginatedArray = []

    if(savedCards){if(savedCards.slice(cardCount,cardCount+3).length > 0)
      paginatedArray = savedCards.slice(cardCount,cardCount+3)
    else
      setCardCount(prev => prev*0)}

      return paginatedArray
  }

  useEffect(() => {
    getSavedCards();
  }, []);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        cardNumber: "",
        expiry: "",
        cvv: "",
        paymentMethod: "",
        holderName: "",
        selectedCard: "",
        isFieldActive : false
      }}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, errors, values }) => (
        <Form className="grid md:grid-cols-2 grid-cols-1 text-text w-full gap-16 md:gap-52 justify-center border-Light-gray shadow rounded-lg p-4 h-auto bg-white ">
          {/* ------Payment Details-------- */}
          {console.log(errors , values)}
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-4">
              <h1 className="text-lg font-bold">Select Your Payment Method</h1>
              <div className="flex gap-4">
                <div
                  className={`flex gap-2  items-center w-32 p-3 rounded-lg  border ${
                    values.paymentMethod === "Credit"
                      ? "bg-primary text-white"
                      : "text-primary bg-white"
                  } `}
                >
                  <Field
                    name="paymentMethod"
                    type="radio"
                    value="Credit"
                    className="h-3.5 w-3.5 mr-2 text-primary border-gray-300 focus:ring-0 focus:ring-offset-0"
                  />
                  <div className="text-sm">Credit Card</div>
                </div>
                <div
                  className={`flex gap-2  items-center w-32 p-3 rounded-lg  border ${
                    values.paymentMethod === "Debit"
                      ? "bg-primary text-white"
                      : "text-primary bg-white"
                  }`}
                >
                  <Field
                    name="paymentMethod"
                    type="radio"
                    value="Debit"
                    className="h-3.5 w-3.5 mr-2 text-primary border-gray-300 focus:ring-0 focus:ring-offset-0"
                  />
                  <div className="text-sm">Debit Card</div>
                </div>
              </div>
              <ErrorMessage
                name="paymentMethod"
                component="p"
                className="text-sm text-danger text-right"
              />
              {/* <div className="flex items-center gap-3">
                <input type="checkbox" />
                <span className="text-sm">
                  Save this card for future payments
                </span>
              </div> */}
            </div>

            {/* Saved Cards section */}
            {savedCards && (
              <div className="flex flex-col gap-4 my-4">
                <h2 className="font-bold">Saved Cards</h2>
                <div className="flex flex-col gap-3 rounded-lg md:w-auto lg:w-2/3">
                  {SavedCardArray()
                    .map((savedCard, i) => {
                      return (
                        <div key={i} className={`flex rounded-lg border ${values.selectedCard === savedCard.id ? 'border bg-Light-text' : 'opacity-20'} items-center justify-between p-2 gap-2`}>
                          <div className="flex items-center gap-3">
                            <div className="relative mr-3">
                            <Field
                              type="radio"
                              name="selectedCard"
                              className='opacity-0 cursor-pointer'
                              value={savedCard.id}
                            />
                            <AiFillCheckCircle className="absolute top-0 -left-1 text text-primary -z-10 pointer-events-none text-lg cursor-pointer" />
                            </div>
                            <div className="text-sm flex flex-col">
                              <span>{`XXXX - XXXX - XXXX - ${savedCard.card.last4}`}</span>
                              <span>{`Expires On : ${savedCard.card.exp_month}/${savedCard.card.exp_year}`}</span>
                            </div>
                          </div>
                          <img
                            src={CardBrands[savedCard.card.brand]}
                            alt={savedCard.card.brand}
                            className=" w-9 "
                          />
                        </div>
                      );
                    })}
                  {/* <button type="button" onClick={changeCardPage} className='bg-primary text-white p-2 rounded-lg'>
                    Show More
                  </button> */}
                  <ErrorMessage
                    name="selectedCard"
                    component="p"
                    className="text-sm text-danger text-right"
                  />
                  <div className="flex justify-between items-center">
                  <button type="button" className="flex items-center text-primary lg:w-1/2 font-bold"  onClick={()=>{setFieldValue('selectedCard',''); setShowFields(true)}} > <BsCreditCard className="mr-3" /> Add Card</button>
                 { savedCards.length > 3 &&  <button type="button" className="shadow p-2 rounded-lg hover:shadow-lg" onClick={()=>setCardCount(prev => prev+3)} > <AiOutlineRight/></button>}
                  </div>
                </div>
              </div>
            )}

            { showPaymentFields && values.selectedCard === '' && <div className="flex flex-col gap-4 ">
              <h2 className="text-lg font-bold">Fill your card details</h2>
              <div>
                <div className="relative flex flex-col mb-3">
                  <label className="text-sm font-bold">Card Holder Name</label>
                  <Field
                    name="holderName"
                    className="payment-input text-sm focus:border-primary border-2"
                    placeholder="Name on card "
                  />
                  <ErrorMessage
                    name="holderName"
                    component="p"
                    className="text-sm text-danger text-right"
                  />
                </div>
                <label className="text-sm font-bold ">Card Number</label>
                <div className="relative">
                  <CardNumberElement
                    className="payment-input tracking-widest "
                    onChange={(e) => {
                      handleBrandChange(e.brand);
                      setFieldValue("cardNumber", e.complete);
                    }}
                    options={{
                      placeholder: "XXXX - XXXX - XXXX - XXXX",
                      classes: {
                        focus: "focused-payment-input",
                        invalid: "invalid-payment-input",
                        base: ``,
                      },
                      style: paymentInputStyles,
                    }}
                  />
                  <img
                    className="absolute w-9 right-2 top-2.5"
                    alt={cardBrand}
                    src={CardBrands[cardBrand]}
                  />
                </div>
                <ErrorMessage
                  name="cardNumber"
                  component="p"
                  className="text-sm text-danger text-right"
                />
              </div>
              <div className="flex gap-4 w-full">
                <div className="flex flex-col w-full">
                  <label className="text-sm font-bold">Card Expiry Date</label>
                  <CardExpiryElement
                    onChange={(e) => setFieldValue("expiry", e.complete)}
                    className="payment-input"
                    options={{
                      placeholder: "MM / YY",
                      classes: {
                        focus: "focused-payment-input",
                        invalid: "invalid-payment-input",
                        base: ``,
                      },
                      style: paymentInputStyles,
                    }}
                  />
                  <ErrorMessage
                    name="expiry"
                    component="p"
                    className="text-sm text-danger text-right"
                  />
                </div>
                <div className="flex flex-col w-full">
                  <label className="text-sm font-bold">CVV</label>
                  <CardCvcElement
                    className="payment-input"
                    onChange={(e) => setFieldValue("cvv", e.complete)}
                    options={{
                      placeholder: "CVV",
                      classes: {
                        focus: "focused-payment-input",
                        invalid: "invalid-payment-input",
                        base: ``,
                      },
                      style: paymentInputStyles,
                    }}
                  />
                  <ErrorMessage
                    name="cvv"
                    component="p"
                    className="text-sm text-danger text-right"
                  />
                </div>
              </div>
                <div className="flex justify-between" >
                    <div className="flex gap-2 items-center">
                      <input type='checkbox' required  name='terms' onChange={(e) => e.target.checked && setFieldValue('isFieldActive' , true)} />
                      {/* <p className="text-sm">I agree to the <a className="text-primary font-bold" href="https://thesynergyworks.com/" target='_blank' rel='noreferrer' > Terms and conditions </a></p> */}
                      <p className="text-sm flex flex-wrap">I agree to the <a className="text-primary font-bold mx-1" href="https://thesynergyworks.com/" target='_blank' rel='noreferrer' > Terms and conditions </a> Secured with <span className="text-primary mx-1 font-bold">stripe</span>payments </p>
                    </div>
                    {/* <div className="flex text-sm items-center" >Secured with <FaStripe className="mx-2 text-primary text-xl"/> payments </div> */}
                </div>
            </div>}
          </div>
          {/* -------Summary---- */}
          <div className="flex flex-col gap-5 md:gap-8 flex-grow items-center w-full p-5">
            {/* ---Appointment Summary------ */}
            <div className="rounded-lg shadow w-full p-3 text-text">
                <p className="text-lg font-bold mb-3" >Appointment Summary</p>
                <div className="flex gap-4 items-center">
                    <div>
                      <img src={ profile?.profilePic?.thumbnail ??  defaultUserImage} className='rounded-full object-cover w-20' />
                    </div>
                    <div className="text-sm flex flex-col">
                      <p className="font-bold">{doctorName}</p>
                      <p className="flex items-center"> <GoPrimitiveDot className="mr-2" /> {specialityName}</p>
                      <p className="flex items-center"> <HiLocationMarker className="mr-2"/> {profile?.addresses[0].address}</p>
                      {/* <p className="flex items-center"> <AiFillTag className="mr-2"/> $ {profile?.consultationFee}</p> */}
                      <p className="flex items-center"> <AiFillTag className="mr-2"/> $ {fee}</p>
                    </div>
                </div>
                <p className="mt-3 text-sm font-bold">{dateString}</p>
            </div>
            <div className="w-full flex flex-col gap-6 md:gap-10 h-full">
              <h2 className="text-lg font-bold">Amount Distribution</h2>
              <div className="flex flex-col gap-4">
                {/* <p className="flex items-center justify-between border-b pb-4"> */}
                <p className="flex items-center justify-between">
                  <span>Cost for Appointment</span>
                  <span> $ {fee}</span>
                </p>

                <p className="flex items-center justify-between border-b pb-4">
                  <span>Tax</span>
                  {/* <span> $ {(fee * doctor_state_tax) / 100}</span> */}
                  <span> $ {calculatedTaxAmount}</span>
                </p>
                <p className="flex items-center justify-between  pb-4">
                  <span className="text-lg">Total Amount</span>
                  {/* <span> $ {fee}</span> */}
                  <span> $ {totalAmountIncludingTax}</span>
                </p>
              </div>
              <button
                type="submit"
                className="rounded-lg bg-primary text-white font-bold w-full p-3 flex items-center justify-center"
                disabled={loading}
              >
                {loading && <Spin className="mr-3" />}
                Submit
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default function Payment() {
  const navigate = useNavigate();
  //get token from session storage

  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    let token = localStorage.getItem("token");

    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
    });

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    BaseAPI(`${BaseURL}/users/getSetupIntent`, requestOptions)
      .then((result) => {
        
        setClientSecret(result.data.client_secret);
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  }, []);

  const options = {
    // passing the client secret obtained from the server
    clientSecret,
  };

  //go to previous page
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="bg-gray w-full">
      <Navbar heading="Home / Search Results / Payment" />

      {/* heading */}
      <div className="flex text-text m-4 items-center">
        <div
          className="bg-white rounded-lg p-2 mx-3 shadow-back text-lg font-bold cursor-pointer"
          onClick={goBack}
        >
          <HiOutlineChevronLeft />
        </div>
        <h1 className="text-lg font-bold">Payment</h1>
      </div>

      <div className="h-auto m-4">
        {clientSecret ? (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm clientSecret={clientSecret} />
          </Elements>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
}
