import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import img from "../../assets/images/hipa-logo.svg";
import { useNavigate, Link } from "react-router-dom";
import Rodal from "rodal";
import { MdLogout } from "react-icons/md";

// include styles
import "rodal/lib/rodal.css";

const Navbar = (props) => {
  const navigate = useNavigate();

  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [visible, setvisible] = useState(false);

  const user = localStorage.getItem("userData");
  const UserInfo = JSON.parse(user);
  const userImage = UserInfo?.profilePic?.original;
  const name = UserInfo?.firstName;

  const isGuest = localStorage.getItem("loggedInAsGuest");

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Logout = () => {
    setAnchorEl(null);
    // localStorage.clear();
    // localStorage.clear();
    // navigate('/login')
    setvisible(true);
  };

  const ConfirmLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    navigate("/login");
  };

  return (
    <div className="bg-white px-4  flex justify-between items-center shadow text-text sticky top-0 right-0 z-30 w-auto mb-8">
      <div className="opacity-0 md:opacity-100 block ">{props.heading}</div>
      <div className="flex items-center">
        {isGuest === 'false' && <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <Avatar src={userImage} />
        </IconButton>}
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className="mt-10 ml-4"
        >
          <MenuItem
            onClick={() => {
              navigate("/home/profile");
            }}
          >
            My Profile
          </MenuItem>
        </Menu>
        {isGuest === 'false' && <span
          onClick={Logout}
          className="text-danger cursor-pointer text-lg mx-3"
        >
          {" "}
          <MdLogout />{" "}
        </span>}
        {isGuest === 'true' && <button type="button" onClick={() => ConfirmLogout()} className="bg-secondary flex justify-center items-center text-white tracking-widest w-full rounded-lg py-2 px-4 my-2">
          Login
        </button>}
      </div>
      <Rodal visible={visible} onClose={() => setvisible(false)}>
        <div>Confirm</div>
        <h1 className="text-center text-lg font-bold mt-6">
          Are you sure you want to Logout?
        </h1>
        <div className="flex justify-between mt-14 text-white font-bold mx-5">
          <button
            className="px-5 py-2 rounded-lg bg-primary"
            onClick={() => setvisible(false)}
          >
            No
          </button>
          <button
            className="px-5 py-2 rounded-lg bg-danger"
            onClick={() => ConfirmLogout()}
          >
            Yes , Logout
          </button>
        </div>
      </Rodal>
    </div>
  );
};

export default Navbar;
