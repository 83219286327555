import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import { AiFillCheckCircle} from 'react-icons/ai'
import { ForgotAPI } from "../../services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { Form, Formik, Field, ErrorMessage } from "formik";
import Spin from '../../components/Loader/Spin'


const ForgotPass = () => {
  const navigate = useNavigate();

  const labelClass = "block text-base text-text";

  
  const [loading , setloading] = useState(false)


  const ForgotPassword = (values) => {
      setloading(true)
    

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      ...values,
    });

    

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    ForgotAPI(requestOptions)
      .then((result) => {
        
        if (result.status != "200") toast.warn(result.message);
        else toast.success("Mail has been sent to your registered email." , {
          icon : <AiFillCheckCircle className="text-secondary text-lg" />
        });
      })
      .catch((err) => console.log(err)).finally(()=>setloading(false));
  };

  //validation schema
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
  });

  return (
    <>
      <Header hide={true} />
      <div className="Background bg-gray min-h-screen flex flex-col justify-center items-center">
        <div className="w-full mx-3 flex flex-col justify-center md:w-1/3">
          <div className="text-center text-text">
            <h1 className="text-base tracking-widest">LOGIN</h1>
            <h1 className="text-xl font-bold">Forgot Password</h1>
            <h1 className="text-base mt-3">
              ENTER YOUR EMAIL ADDRESS TO GET CONFIRMATION LINK
            </h1>
          </div>
          <div className="bg-white mt-5 mx-5 rounded-2xl px-5 py-10 space-y-5 shadow-lg ">
            <Formik
              initialValues={{ email: "" , role : 2}}
              validationSchema={validationSchema}
              onSubmit={ForgotPassword}
            >
              {({ errors, touched }) => (
                <Form>
                  <div>
                    <div className="space-y-5">
                      <label className={labelClass}>
                        Enter email to get confirmation mail
                      </label>
                      <div>
                        <Field
                          type="email"
                          name="email"
                          className="border border-primary min-w-full rounded-lg px-5 py-3 focus:outline-primary"
                          placeholder="Enter email"
                          autofocus
                        />
                        <p className="text-sm text-danger text-right">
                          {errors.email}
                        </p>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className={`bg-secondary text-white w-full rounded-lg py-3 mt-3 flex justify-center items-center ${loading && 'cursor-not-allowed opacity-80'}`}
                    >
                     {loading && <Spin className='mr-2'/>} Send Confirmation Link
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="flex justify-center">
              <button
                className="text-secondary border rounded-lg mt-2 px-5 py-3 "
                onClick={() => navigate("/")}
              >
                Back to Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPass;
