import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import {
    Form, Formik, Field, ErrorMessage,
} from 'formik';
import moment from 'moment';
import { MdOutlineCancel } from 'react-icons/md';
import Rodal from "rodal";
import { toast } from "react-toastify";
import Spin from '../../../../../components/Loader/Spin';
import { BaseAPI, BaseURL } from "../../../../../services/services";



const validationSchema = Yup.object({
    name: Yup.string().required('Name Required'),
    dob: Yup.date().required('Required').nullable(),
    // age: Yup.number().required('Age Required'),
    description: Yup.string().required('Description Required'),
    pastIllness: Yup.string().required('Past Illness is required'),
});



function DetailForm({ type = 'add', data = {}, isOpen = false, setIsOpen = () => { }, setData = () => { } }) {

    const user = localStorage.getItem("userData");
    const token = localStorage.getItem("token");

    const UserInfo = JSON.parse(user);
    const familyMembers = UserInfo?.familyDetails || [];


    console.log('data', data)

    const defaultValues = {
        name: '',
        dob: '',
        age: '',
        description: '',
        pastIllness: '',
        hasDisease: 'yes',
    };

    const [selectedVals, setSelectedVals] = useState(null);
    const [loading, setLoading] = useState(false);
    const [prepopulateAge, setPrepopulateAge ] = useState(null);

    const addFamilyMember = (values) => {
        let myHeaders = new Headers({
            accept: "application/json",
            authorization: `${token}`,
            "Content-Type": "application/json",
        });

        let { hasDisease, age, ...rest } = values

        rest.anyCurrentDisease = hasDisease === 'yes'
        rest.age = age = prepopulateAge;

        var raw = JSON.stringify(rest);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        BaseAPI(`${BaseURL}/patient/createFamilyMember`, requestOptions)
            .then((result) => {
                if (result.status !== 200) {
                    toast.warn(result?.message);
                } else {
                    toast.success(`Family member added`);
                    console.log(result)
                    let obj = {
                        ...UserInfo,
                        familyDetails: result?.data
                    }
                    localStorage.setItem("userData", JSON.stringify(obj));
                    setData(result?.data)
                    setIsOpen(false)
                    // window.location.reload();
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    }


    const updateFamilyMember = (values) => {

        let myHeaders = new Headers({
            accept: "application/json",
            authorization: `${token}`,
            "Content-Type": "application/json",
        });

        let { hasDisease, ...rest } = values

        rest.anyCurrentDisease = hasDisease === 'yes'

        var raw = JSON.stringify(rest);

        var requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        BaseAPI(`${BaseURL}/patient/updateFamilyMember?familyMemberId=${data?.familyMemberId}`, requestOptions)
            .then((result) => {
                if (result.status !== 200) {
                    toast.warn(result?.message);
                } else {
                    toast.success(`Family member updated!`);
                    console.log(result)
                    setData(result?.data)
                    let obj = {
                        ...UserInfo,
                        familyDetails: result?.data
                    }
                    localStorage.setItem("userData", JSON.stringify(obj));
                    setIsOpen(false)
                    // window.location.reload();
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));

    }



    const handleFormSubmit = (values) => {

        setLoading(true);

        console.log(values)

        type === 'edit' ? updateFamilyMember(values) : addFamilyMember(values)



    };

    useEffect(() => {
        if (type === 'edit') {
            const obj = {
                name: data?.name || 'Tom',
                dob: data?.dob?.split('T')[0] || '2022-02-01',
                age: data?.age || 11,
                pastIllness: data?.pastIllness || 'None',
                description: data?.description || 'None',
                hasDisease: data?.hasDisease ? 'yes' : 'no',
            };

            setSelectedVals(obj);
        } else {
            setSelectedVals(defaultValues);
        }
    }, [type]);

    if (!selectedVals) return <p>Loading</p>


     const calculateAge = (birthDate) => {
        const today = new Date();
        const birthDateObj = new Date(birthDate);
        let age = today.getFullYear() - birthDateObj.getFullYear();
        const monthDifference = today.getMonth() - birthDateObj.getMonth();
        const dayDifference = today.getDate() - birthDateObj.getDate();

        // If the birth month hasn't been reached or it's the birth month but the birth date hasn't been reached, subtract one year from the age
        if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
            age--;
        }
        setPrepopulateAge(age);
        return age;
    }
    return (
        <Rodal visible={isOpen} onClose={() => setIsOpen(false)} height={500} width={500} >
            {/* ---Form----- */}
            <p className='text-text font-semibold my-2'>{type === 'edit' ? 'Edit Details' : 'Add Member Details'}</p>
            <Formik
                validationSchema={validationSchema}
                initialValues={selectedVals}
                onSubmit={handleFormSubmit}
            >
                {
                    (values) => (
                        <Form className="flex flex-col gap-3 h-auto">
                            {/* ---grif---- */}
                            <div className="grid grid-cols-2 gap-8">
                                <div className="relative">
                                    <label className="text-sm font-medium">
                                        Name
                                        {' '}
                                        <span className="text-danger">*</span>
                                        {' '}

                                    </label>
                                    <Field
                                        name="name"
                                        type="text"
                                        className="form-input"
                                        placeholder="Name"
                                    />
                                    <ErrorMessage component="div" name="name" className="text-xs absolute  font-medium mt-0.5 text-danger right-4 text-sm" />
                                </div>

                                <div className="relative">
                                    <label className="text-sm font-medium">
                                        Date of birth
                                        {' '}
                                        <span className="text-danger">*</span>
                                        {' '}

                                    </label>
                                    {/* <Field name="dob" type="date" className="form-input" max={moment().subtract('18', 'years').format('YYYY-MM-DD')} min={moment().subtract('100', 'years').format('YYYY-MM-DD')} /> */}
                                    <Field name="dob" type="date" className="form-input" max={moment().format('YYYY-MM-DD')} />
                                    <ErrorMessage component="div" name="dob" className="text-xs absolute -bottom-4 font-medium mt-0.5 text-danger right-0 text-sm" />
                                </div>

                                <div className="relative">
                                    <label className="text-sm font-medium">
                                        Age
                                        {' '}
                                        <span className="text-danger">*</span>
                                        {' '}

                                    </label>
                                    {/* <Field
                                        name="age"
                                        type="number"
                                        className="form-input"
                                        placeholder="Age"
                                    />
                                    <ErrorMessage component="div" name="age" className="text-xs absolute  font-medium mt-0.5 text-danger right-4 text-sm" /> */}
                                    <Field
                                        name="age"
                                        type="number"
                                        className="form-input"
                                        placeholder="Age"
                                        value={calculateAge(values.values?.dob)}
                                        readOnly
                                    />
                                </div>

                                <div className="flex flex-col relative w-fit">
                                    <div className="font-medium text-sm pb-1">Any Current Disease?</div>
                                    <div className="flex gap-4 ">
                                        <div>
                                            <Field
                                                name="hasDisease"
                                                type="radio"
                                                value="yes"
                                                // className="h-4 w-4 mr-2 text-primary-500 border-gray-300 focus:ring-0 focus:ring-offset-0"
                                                className="h-4 w-4 mr-2 text-[#01A1FF] border-gray-300 focus:ring-0 focus:ring-offset-0"
                                            />
                                            <label className="text-sm font-medium">Yes</label>
                                        </div>
                                        <div>
                                            <Field
                                                name="hasDisease"
                                                type="radio"
                                                value="no"
                                                // className="h-4 w-4 mr-2 text-primary-500 border-gray-300 focus:ring-0 focus:ring-offset-0"
                                                className="h-4 w-4 mr-2 text-[#01A1FF] border-gray-300 focus:ring-0 focus:ring-offset-0"
                                            />
                                            <label className="text-sm font-medium">No</label>
                                        </div>
                                    </div>
                                    <ErrorMessage component="div" name="isAcceptingClients" className="text-xs absolute font-medium mt-0.5 text-danger right-0 -bottom-2 text-sm" />
                                </div>
                            </div>

                            <div>

                                <div className="relative">
                                    <label className="text-sm font-medium">
                                        Description
                                        {' '}
                                        <span className="text-danger">*</span>
                                        {' '}

                                    </label>
                                    <Field
                                        name="description"
                                        as="textarea"
                                        className="form-input "
                                        placeholder="Any current Disease . If not , enter none"
                                    />
                                    <ErrorMessage component="div" name="description" className="text-xs absolute  font-medium mt-0.5 text-danger right-4 text-sm" />
                                </div>

                            </div>
                            <div>
                                <div className="relative">
                                    <label className="text-sm font-medium">
                                        Past Illness
                                        {' '}
                                        <span className="text-danger">*</span>
                                        {' '}

                                    </label>
                                    <Field
                                        name="pastIllness"
                                        as="textarea"
                                        className="form-input"
                                        placeholder="Any past illness . If not , enter none"
                                    />
                                    <ErrorMessage component="div" name="pastIllness" className="text-xs absolute  font-medium mt-0.5 text-danger right-4 text-sm" />
                                </div>
                            </div>

                            <div className="flex justify-end mt-2">
                                <button className="btn btn-primary text-sm flex gap-2 items-center mt-2 " type='submit' disabled={loading}>
                                    {/* {' '}
                    {(addingData || updatingData) && <Spinner className="mr-2" />}
                    {' '} */}
                                    {loading && <Spin />}
                                    Save
                                </button>
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </Rodal>
    )
}

export default DetailForm