import React, { useState } from "react";
import moment from "moment";
import { BiEdit } from "react-icons/bi";
import { MdCancelPresentation } from "react-icons/md";
import { toast } from "react-toastify";
import DetailForm from "./Form";
import { BaseAPI, BaseURL } from "../../../../../services/services";


function Family() {
    const user = localStorage.getItem("userData");
    const token = localStorage.getItem("token");
    const UserInfo = JSON.parse(user);
    const familyData = UserInfo?.familyDetails || [];

    const [modalOpen, setModalOpen] = useState(false);
    const [formType, setFormType] = useState("add");
    const [selectedMember, setSelectedMember] = useState();
    const [familyMembers, setFamilyMembers] = useState(familyData)

    const deleteFamilyMember = (id) => {
        let myHeaders = new Headers({
            accept: "application/json",
            authorization: `${token}`,
            "Content-Type": "application/json",
        });



        var requestOptions = {
            method: "DELETE",
            headers: myHeaders,
            redirect: "follow",
        };

        BaseAPI(`${BaseURL}/patient/deleteFamilyMember?familyMemberId=${id}`, requestOptions)
            .then((result) => {
                if (result.status !== 200) {
                    toast.warn(result?.message);
                } else {
                    toast.success(`Family member deleted!`);
                    console.log(result)
                    let obj = {
                        ...UserInfo,
                        familyDetails: result?.data
                    }
                    setFamilyMembers(result?.data)
                    localStorage.setItem("userData", JSON.stringify(obj));
                    setFamilyMembers(result?.data)
                    // window.location.reload();
                }
            })
            .catch((err) => console.log(err))
    }

    return (
        <div className="w-full text-text mx-4 py-3">
            {/* ----family member card---- */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {familyMembers.length > 0 ? (
                    familyMembers.map((member) => (
                        <div
                            // className="card transform-gpu drop-shadow-card border-primary-500 border-l-4 p-3 flex justify-between bg-white rounded-lg"
                            className="card transform-gpu drop-shadow-card border-[#01A1FF] border-l-4 p-3 flex justify-between bg-white rounded-lg"
                            key={member?._id}
                        >
                            {/* ---info div---- */}
                            <div className="flex flex-col gap-2">
                                <h5 className="font-bold">{member?.name}</h5>
                                <div className="text-secondary-500 text-sm flex flex-col gap-2">
                                    <p>DOB : {moment(member?.dob.split('T')[0]).format("MMMM DD , YYYY")}</p>
                                    <p>Age : {moment().diff(member?.dob.split('T')[0], "years")} years</p>
                                    <p>Any Current Disease : {member?.anyCurrentDisease ? 'Yes' : 'No'} </p>
                                    <p>Past Illness :{member?.pastIllness} </p>
                                </div>
                            </div>

                            {/* ----action buttons ----- */}
                            <div className="flex flex-col justify-center gap-4 items-between h-full">
                                <button
                                    className="btn bg-primary text-white"
                                    onClick={() => {
                                        setFormType("edit");
                                        setSelectedMember(member);
                                        setModalOpen(true);
                                    }}
                                >
                                    <BiEdit />
                                </button>
                                <button
                                    className="btn bg-danger text-white"
                                    onClick={() => {
                                        deleteFamilyMember(member?.familyMemberId);
                                    }}
                                >
                                    <MdCancelPresentation />
                                </button>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-danger font-bold">No Details Found!</p>
                )}
            </div>

            {/* --add button div---- */}
            <div className="flex justify-end my-4 mx-4">
                <button
                    type="button"
                    className="btn btn-primary text-white"
                    onClick={() => {
                        setFormType("add");
                        setModalOpen(true);
                    }}
                >
                    Add Member Details
                </button>
            </div>

            {/* ----Form Modal------ */}
            {modalOpen && <DetailForm
                type={formType}
                isOpen={modalOpen}
                setIsOpen={setModalOpen}
                data={selectedMember}
                setData={setFamilyMembers}
            />}
        </div>
    );
}

export default Family;
