import React, { useEffect, useState } from 'react'
// import LogoCenter from '../../assets/images/myCareVertical.svg'
// import Logo from '../../assets/images/myCareMini.svg'
import LogoCenter from '../../assets/images/TeleSecure360logo.svg'
import Logo from '../../assets/images/TeleSecure360-miniLogo.svg'
import { NavLink, useLocation } from 'react-router-dom'
import { AiOutlineDoubleRight, AiOutlineDoubleLeft, AiOutlineMenu } from "react-icons/ai";
import { RiStethoscopeFill } from "react-icons/ri";
import { BiLinkExternal } from "react-icons/bi";
import { ReactComponent as Home } from "../../assets/icons/Category.svg";
import { ReactComponent as User } from "../../assets/icons/Add_User.svg";
import { ReactComponent as Document } from "../../assets/icons/Document.svg";
import { ReactComponent as Calendar } from "../../assets/icons/Calendar.svg";
import healthSpace from './../../assets/images/logo.png';
import { BsClipboardPlus } from 'react-icons/bs';


const Sidebar = () => {


    const LinkData = [
        { title: 'Home', path: '/home/home', icon: <Home /> },
        { title: 'My Appointments', path: '/home/appointment', icon: <Calendar /> },
        { title: 'My Profile', path: '/home/profile', icon: <User /> },
        { title: 'My Documents', path: '/home/documents', icon: <Document /> },
        {
            title: "Prescriptions/Invoices",
            path: "/home/prescriptions",
            icon: <BsClipboardPlus className="text-sm" />,
        },
        {
            title: "Intake Form",
            path: "/home/clinical",
            icon: <RiStethoscopeFill className="text-sm font-normal" />,
        },
    ];

    const LinkDataForGuest = [
        { title: 'Home', path: '/home/home', icon: <Home /> },
    ];

    const isGuest = localStorage.getItem("loggedInAsGuest");

    const [showSidebar, setshowSidebar] = useState(true);
    const [userToggled, setUserToggled] = useState(false);

    const location = useLocation();
    const currentPath = location.pathname;
    const userEmail = localStorage.getItem("emailUser");

    //classes
    const defaultClass = `transition duration-700 ease-in-out ${showSidebar ? 'pl-8' : ''}  py-1 rounded-lg hover:bg-Light-text hover:text-primary`;

    const activeClass = `transition duration-700 ease-in-out ${showSidebar ? 'pl-8' : ''} py-1 rounded-lg bg-Light-text font-bold text-primary`

    const NavLinkClass = `flex ${showSidebar ? '' : 'justify-center'}  items-center`

    const spanClass = `absolute cursor-pointer   right-2  font-bold  ${showSidebar ? 'text-lg top-2' : 'text-sm'}`;

    const LogoContainer = ` ${showSidebar ? 'p-5' : 'py-5 px-3 mt-8'} flex justify-center flex-shrink-0 items-center`

    const LogoImage = showSidebar ? LogoCenter : Logo

    //Sidebar Toggle
    // const toggleSidebar = () => {
    //     setshowSidebar(!showSidebar)
    // }

    const toggleSidebar = () => {
        setshowSidebar(!showSidebar)
        setUserToggled(true);
    }

    // Handle window resize
    useEffect(() => {
        const handleResize = () => {
            // if (window.innerWidth < 768) {
            //     setshowSidebar(false);
            // } else {
            //     setshowSidebar(true);
            // }

            if (window.innerWidth < 768) {
                setshowSidebar(false);
            } else if (!userToggled) {
                setshowSidebar(true);
            }
        };

        window.addEventListener('resize', handleResize);

        // Initial check
        handleResize();

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [userToggled]);

    return (
        <div className='md:relative absolute z-50 '>
            <span
                // onClick={() => { setshowSidebar(!showSidebar) }}
                onClick={() => { setshowSidebar(!showSidebar); setUserToggled(true); }}
                className={`md:hidden ${showSidebar ? 'hidden' : 'block'} absolute z-40 top-5`}
            ><AiOutlineMenu /></span>
            <div className={`bg-white relative min-h-screen ease-in-out duration-300 ${showSidebar ? "w-64 " : "md:w-20 md:block hidden"
                }`}>

                <span onClick={toggleSidebar} className={spanClass}>{showSidebar ? <AiOutlineDoubleLeft /> : <AiOutlineDoubleRight />}</span>
                <div className='p-5 flex items-center justify-center flex-shrink-0 px-4'>
                    {/* {
                        userEmail === 'myhcaredemo+patient@gmail.com' ?
                            <img
                                className={`${showSidebar ? 'h-[5.5rem]' : 'h-[3rem] w-auto'} w-auto`}
                                src={healthSpace}
                                alt="Workflow"
                            /> :
                            <img
                                className="h-[6rem] w-auto"
                                src={showSidebar ? LogoCenter : LogoImage}
                                alt="Workflow"
                            />
                    } */}
                    <img className={`${showSidebar && userEmail === 'myhcaredemo+patient@gmail.com' ? 'h-24 w-auto' : 'h-14 w-auto'}h-24`} src={userEmail === 'myhcaredemo+patient@gmail.com' ? healthSpace : LogoImage} alt='sidebarlogo' />
                </div>

                <div className='font-semibold tracking-widest text-center py-2 border-t border-b border-Darkgray'>Patient</div>
                <ul className='border-t-2 border-Light-dark mx-3  space-y-3 text-text mt-5 py-5 '>
                    {/* {
                        LinkData.map((item, i) => {
                            return <li key={i} className={currentPath === item.path ? activeClass
                                : defaultClass} >
                                <NavLink to={item.path} className={NavLinkClass}>
                                    <span className='mr-3 text-lg'>{item.icon}</span>
                                    {showSidebar && item.title}
                                </NavLink>
                            </li>
                        })
                    } */}
                    {isGuest === "true" ?
                        LinkDataForGuest.map((item, i) => {
                            return <li key={i} className={currentPath === item.path ? activeClass
                                : defaultClass} >
                                <NavLink to={item.path} className={NavLinkClass}>
                                    <span className='mr-3 text-lg'>{item.icon}</span>
                                    {showSidebar && item.title}
                                </NavLink>
                            </li>
                        })
                        :
                        LinkData.map((item, i) => {
                            return <li key={i} className={currentPath === item.path ? activeClass
                                : defaultClass} >
                                <NavLink to={item.path} className={NavLinkClass}>
                                    <span className='mr-3 text-lg'>{item.icon}</span>
                                    {showSidebar && item.title}
                                </NavLink>
                            </li>
                        })
                    }
                </ul>
                {showSidebar && <div className='absolute bottom-5 w-full flex justify-center items-center'>
                    {/* <a href='https://thesynergyworks.com/' target='_blank' rel='noreferrer' className={`bg-primary px-3 py-2 rounded-lg ${userEmail == 'myhcaredemo+patient@gmail.com' ? 'hidden' : 'block'} flex items-center text-white`}> */}
                    <a href='https://telesecure360.com/' target='_blank' rel='noreferrer' className={`bg-primary px-3 py-2 rounded-lg ${userEmail == 'myhcaredemo+patient@gmail.com' ? 'hidden' : 'block'} flex items-center text-white`}>
                        Go to Telesecure360 <BiLinkExternal className='ml-3' />
                    </a>
                </div>}
            </div>
        </div>
    )
}

export default Sidebar
