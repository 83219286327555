import React , {useState , useEffect} from "react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { BaseAPI , BaseURL } from "../../../services/services";
import { loadStripe } from "@stripe/stripe-js";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate } from "react-router-dom";

const paymentInputStyles = {
  base: {
    fontFamily: "'Poppins', Helvetica, Arial, serif",
    fontSize: "12px",
  },
};

const stripePromise = loadStripe(
  "pk_test_51KkjgyR0KVUv2mgaSsVrrNTiwQoG1qkItSWGrY7VnpsgE97ozXzun8Nx39C572hZ8kXc3aAtIVLwaEHxp6ussgno0053IpB0BT"
);





const options = {
  // passing the client secret obtained from the server
  clientSecret: "{{CLIENT_SECRET}}",
};



const validationSchema = Yup.object({
  cardNumber: Yup.boolean().required("Card number is required"),
  expiry: Yup.boolean().required("Expiry date is required"),
  cvv: Yup.boolean().required("CVV is required"),
  paymentMethod: Yup.string().required("Please select the card type"),
  holderName: Yup.string().required("Name is required"),
});

function CheckoutForm({clientSecret}) {

  const stripe = useStripe();
 const elements = useElements();

  const [loading , setLoading] = useState(false)
  const [paymentComplete , setPaymentComplete] = useState(false)


  const handleSubmit = async(values) => {
    
    
     

      let stripeResponse;

    const cardData = elements.getElement(CardNumberElement);

    

    

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const {error} = await stripe.confirmCardSetup(clientSecret , {
      payment_method: {
        card: cardData,
      },
    })

    // stripeResponse = await stripe.confirmCardPayment(clientSecret, {
    //   payment_method: {
    //     card: cardData,
    //     billing_details: { name: values.holderName },
    //   },
    // });

     

     if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      //pk_live_51KkjgyR0KVUv2mgasmYVUrFZxrkBHzxZxxUTAtXcRurlhg4TMNDnbxtDoUCNyTZW7WQoPav1HjTv8lzEyiZKDmu800jpgF35nP
      //pk_test_51KkjgyR0KVUv2mgaSsVrrNTiwQoG1qkItSWGrY7VnpsgE97ozXzun8Nx39C572hZ8kXc3aAtIVLwaEHxp6ussgno0053IpB0BT
      // details incomplete)
      console.log(error)
    }

    //  if (stripeResponse.error) {
    //   toast.error('Card declined')
    // } else setPaymentComplete(true);
       
     
  };

  if(paymentComplete){
      
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        cardNumber: "",
        expiry: "",
        cvv: "",
        paymentMethod: "",
        holderName: "",
      }}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, errors }) => (
        <Form className="grid md:grid-cols-2 grid-cols-1 text-text w-full gap-16 justify-center border-Light-gray shadow rounded-lg p-4 h-auto ">
          {/* ------Payment Details-------- */}
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-4">
              <h1 className="text-lg font-bold">Select Your Payment Method</h1>
              <div className="flex gap-4">
                <div className="flex gap-2 bg-white items-center w-32 p-3 rounded-lg  border">
                  <Field
                    name="paymentMethod"
                    type="radio"
                    value="Credit"
                    className="h-3.5 w-3.5 mr-2 text-primary border-gray-300 focus:ring-0 focus:ring-offset-0"
                  />
                  <div className="text-sm">Credit Card</div>
                </div>
                <div className="flex gap-2 bg-white items-center w-32 p-3 rounded-lg  border">
                  <Field
                    name="paymentMethod"
                    type="radio"
                    value="Debit"
                    className="h-3.5 w-3.5 mr-2 text-primary border-gray-300 focus:ring-0 focus:ring-offset-0"
                  />
                  <div className="text-sm">Debit Card</div>
                </div>
              </div>
              <ErrorMessage name="paymentMethod" component='p' className='text-sm text-danger text-right'/>
              <div className="">
                <input type="checkbox" />
                <span className="text-sm">
                  Save this card for future payments
                </span>
              </div>
            </div>

            <div className="flex flex-col gap-4 ">
              <h2 className="text-lg font-bold">Fill you card details</h2>
              <div>
                <div className="relative flex flex-col mb-3">
                  <label className="text-sm">Card Holder Name</label>
                  <Field
                    name="holderName"
                    className="payment-input text-sm focus:border-primary border-2"
                    placeholder="Name on card "
                  />
                  <ErrorMessage
                    name="holderName"
                    component="p"
                    className="text-sm text-danger text-right"
                  />
                </div>
                <label className="text-sm ">Card Number</label>
                <CardNumberElement
                  className="payment-input tracking-widest "
                  onChange={(e) => setFieldValue("cardNumber", e.complete)}
                  options={{
                    placeholder: "XXXX - XXXX - XXXX - XXXX",
                    classes: {
                      focus: "focused-payment-input",
                      invalid: "invalid-payment-input",
                      base: ``,
                    },
                    style: paymentInputStyles,
                  }}
                />
                <ErrorMessage
                  name="cardNumber"
                  component="p"
                  className="text-sm text-danger text-right"
                />
              </div>
              <div className="flex gap-4 w-full">
                <div className="flex flex-col w-full">
                  <label className="text-sm">Card Expiry Date</label>
                  <CardExpiryElement
                    onChange={(e) => setFieldValue("expiry", e.complete)}
                    className="payment-input"
                    options={{
                      placeholder: "MM / YY",
                      classes: {
                        focus: "focused-payment-input",
                        invalid: "invalid-payment-input",
                        base: ``,
                      },
                      style: paymentInputStyles,
                    }}
                  />
                  <ErrorMessage
                    name="expiry"
                    component="p"
                    className="text-sm text-danger text-right"
                  />
                </div>
                <div className="flex flex-col w-full">
                  <label className="text-sm">CVV</label>
                  <CardCvcElement
                    className="payment-input"
                    onChange={(e) => setFieldValue("cvv", e.complete)}
                    options={{
                      placeholder: "XXXX",
                      classes: {
                        focus: "focused-payment-input",
                        invalid: "invalid-payment-input",
                        base: ``,
                      },
                      style: paymentInputStyles,
                    }}
                  />
                  <ErrorMessage
                    name="cvv"
                    component="p"
                    className="text-sm text-danger text-right"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* -------Summary---- */}
          <div className="flex flex-col flex-grow items-center w-full">
            <div className="w-full flex flex-col gap-6 h-full">
              <h2 className="text-lg font-bold">Amount Distribution</h2>
              <div className="flex flex-col gap-4">
                <p className="flex items-center justify-between border-b pb-4">
                  <span>Cost for Appointment</span>
                  <span> $ 100</span>
                </p>
                <p className="flex items-center justify-between border-b pb-4">
                  <span>Taxes</span>
                  <span> $ 15</span>
                </p>
              </div>
              <button
                type="submit"
                className="rounded-lg bg-primary text-white font-bold w-full p-3"
              >
                Submit
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default function Example() {

  const [clientSecret, setClientSecret] = useState("");

  useEffect(()=>{

    let token = localStorage.getItem('token')


    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
    });

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    BaseAPI(`${BaseURL}/users/getSetupIntent` , requestOptions)
    .then(result => { console.log(result.data.setUpIntent); setClientSecret(result.data.client_secret)})
    .catch(err => console.log(err))
    .finally(()=> {} )
  },[])

  const options = {
    // passing the client secret obtained from the server
    clientSecret
  };


  return (
    <div className="h-auto w-full m-4">
      { clientSecret && <Elements stripe={stripePromise} options={options}>
        <CheckoutForm clientSecret={clientSecret} />
      </Elements>}
    </div>
  );
}
