import React, { useState, useMemo, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PDFViewer from "../../components/PDFViewer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { FileUpload, BaseAPI, BaseURL } from "../../services/services";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Spin from "../../components/Loader/Spin";
import { stateDocuments } from "../../Documents";

//getting patient signature.
;



const Recommendation = () => {
  const { state } = useLocation();

  
  

  //TODO: Connect the documents with state to evaluate

  const [isLastPage, setIsLastPage] = useState(false);
  const [toSign, setToSign] = useState(false);
  const [loading, setLoading] = useState(false);
  const [evaluatedState , setevaluatedState] = useState(null)
  const [documentState , setDocumentState] = useState(evaluatedState)

  const user = localStorage.getItem("userData");
const UserInfo = JSON.parse(user)

  let stateForEvaluation = UserInfo?.stateForDoctorEvaluation;

  useEffect(()=>{

    if(stateForEvaluation){
      if (stateForEvaluation !== "New York" && stateForEvaluation !== "California") {
        stateForEvaluation = "Others";
        setevaluatedState("Others")
      }else{
        setevaluatedState(stateForEvaluation)
      }
    }

  },[])

 


  const handlePageChange = ({ pageNumber, numPages }) => {
    if (pageNumber / numPages === 1) setIsLastPage(true);
    else setIsLastPage(false);
  };

  //generate File name for download
  const fileName = `${
    state?.bookingData?.patientId?.firstName
  }_${new Date().getTime()}.pdf`;

  const pdfContent = useMemo(
    () => (
      <PDFViewer onPageChange={handlePageChange} rerender={toSign}>
        {/* <NewYork verified={toSign} details={state.bookingData} /> */}
        {React.createElement(stateDocuments[evaluatedState], {
          verified: toSign,
          details: state.bookingData,
        })}
      </PDFViewer>
    ),
    [toSign , evaluatedState]
  );

  const signPdfDocument = (data) => {
    const token = localStorage.getItem("token");

    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
      "Content-Type": "application/json",
    });

    var raw = JSON.stringify(data);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    BaseAPI(`${BaseURL}/booking/signPdfDocument`, requestOptions)
      .then((data) => {
        
        if (data.status === 200) {
          toast.success("Document uploaded .");
        }else{
          toast.error(data.message)
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const UploadDocument = async (blob) => {
    setLoading(true);

    let fileLink;
    
    fileLink = await FileUpload(blob).then((result) => result.data.url);
    

    let raw = {
      bookingId: state.bookingId,
      signedDocument: {
        name: fileName,
        fileLink: fileLink,
      },
    };

    

    signPdfDocument(raw);
  };

  

  return (
    <div className="Home bg-Light-gray min-h-screen w-full">
      <Navbar heading="Document Sign" />
      {/* headings */}
      <div className="flex justify-between items-center w-full py-5 px-4 flex-col md:flex-row space-y-3 md:space-y-0">
        <div>
          <h1 className="text-lg font-bold text-text">
            Recommendation Document
          </h1>
        </div>
        {/* Document Section */}
        <div className="flex items-center gap-4">
          {isLastPage ? (
            <button
              className="bg-primary text-white rounded-lg p-3"
              onClick={() => setToSign(true)}
            >
              Sign Document
            </button>
          ) : null}

          {console.log(evaluatedState , 'form render')}

          { toSign && evaluatedState&& <PDFDownloadLink
            document={React.createElement(stateDocuments[evaluatedState], {
              verified: toSign,
              details: state.bookingData,
            })}
            fileName={fileName}
          >
            {({ blob, url, loading, error }) => {
              
              return (
                <div className="flex gap-3">
                  {isLastPage && (
                    <button
                      className="bg-secondary flex items-center gap-2 text-white rounded-lg p-3 mx-3"
                      onClick={() => UploadDocument(blob, url)}
                    >
                      {" "}
                      {loading && <Spin className="mr-2" />} Upload
                    </button>
                  )}
                </div>
              );
            }}
          </PDFDownloadLink>}
        </div>
      </div>

      {/* pdf view section */}
      <div className="bg-white rounded-lg m-4 flex items-center justify-center">
        {evaluatedState && pdfContent}
      </div>
    </div>
  );
};

export default Recommendation;
