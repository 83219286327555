import React, { useEffect, useState } from 'react'
import { HiOutlineChevronLeft } from 'react-icons/hi';
import Navbar from '../../components/Navbar/Navbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { BaseAPI, BaseURL } from '../../services/services';
import { toast } from 'react-toastify';
import Spinner from '../../components/Loader/Spinner';
import { BsFillStarFill, BsStar } from 'react-icons/bs';

const ViewRating = () => {
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;

    const [bookingDetails, setBookingDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [review, setReview] = useState('');
    const [ratings, setRatings] = useState({
        waitingTime: 0,
        doctorBehavior: 0,
        overallExperience: 0,
    });

    const defaultImage = "https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-image-182145777.jpg";

    useEffect(() => {
        if (!state) {
            localStorage.removeItem('token')
            localStorage.removeItem('userData')
            navigate('/login')
        } else
            viewAppointmentDetails(state.id);
    }, []);

    useEffect(() => {
        if (bookingDetails?.rating) {
            setRatings({
                waitingTime: bookingDetails?.rating[0]?.waitingTimeRatings,
                doctorBehavior: bookingDetails?.rating[0]?.behaviorRatings,
                overallExperience: bookingDetails?.rating[0]?.overallRatings,
            })
            setReview(bookingDetails?.rating[0]?.review)
        }
    }, [bookingDetails])

    const viewAppointmentDetails = (id) => {
        setLoading(true);
        let myHeaders = new Headers({
            accept: "application/json",
            authorization: `${token}`,
            "Content-Type": "application/json",
        });

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        BaseAPI(
            `${BaseURL}/booking/getBookingDetails?bookingId=${id}`,
            requestOptions
        ).then((result) => {
            if (result.status === 200) {
                setBookingDetails(result.data);
                sessionStorage.setItem('details', JSON.stringify(result.data))
            } else { toast.error('OOPS ! Something went wrong. Please try after some time.') }
        }).catch((err) => { console.log(err); }
        ).finally(() => { setLoading(false); });
    };

    const goBack = () => { navigate(-1); };

    const handleRatingChange = (question, rating) => {
        setRatings((prevRatings) => ({
            ...prevRatings,
            [question]: rating,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (ratings.waitingTime && ratings.doctorBehavior && ratings.overallExperience) {
            // Form is valid, submit the data
            setLoading(true);
            let myHeaders = new Headers({
                accept: "application/json",
                authorization: `${token}`,
                "Content-Type": "application/json",
            });

            const payload = {
                doctorId: bookingDetails?.doctorId?._id,
                review: review,
                waitingTimeRatings: ratings?.waitingTime,
                behaviorRatings: ratings?.doctorBehavior,
                overallRatings: ratings?.overallExperience,
                bookingId: bookingDetails?._id,
            }

            var raw = JSON.stringify(payload);

            var requestOptions = {
                method: "PUT",
                headers: myHeaders,
                body: raw,
            };

            BaseAPI(`${BaseURL}/users/ratingToDoctor`, requestOptions)
                .then((result) => {
                    if (result.status === 200) {
                        toast.success("Your review has been successfully submitted");
                        goBack();
                    } else { toast.error('OOPS ! Something went wrong. Please try after some time.') }
                }).catch((err) => { console.log(err); }
                ).finally(() => { setLoading(false); });
        } else { setIsError(true) }
    };

    return (
        <div className="Home bg-Light-gray min-h-screen w-full">
            <Navbar heading="Home / Rating" />
            <div className="flex justify-between items-center w-full py-5 px-4 flex-col md:flex-row">
                <div className="flex text-text  items-center ">
                    <div className="bg-white rounded-lg cursor-pointer p-2 mx-3 shadow-back text-lg font-bold" onClick={goBack}>
                        <HiOutlineChevronLeft />
                    </div>
                    <h1 className="text-lg font-bold">Review and Rating</h1>
                </div>
            </div>

            {loading ? (
                <Spinner />
            ) : (
                bookingDetails && (
                    <div className='flex justify-center'>
                        {bookingDetails?.rating?.length > 0 && <div className="bg-white rounded-lg w-2/3 mx-4 text-text shadow">
                            <div className='flex justify-center'>
                                <img
                                    className="h-20 w-20 rounded-full mt-5 mb-3"
                                    src={bookingDetails?.doctorId?.profilePic?.original || defaultImage}
                                    alt="user"
                                />
                            </div>
                            {/* <div className='flex justify-center font-bold' >Appointment Id: {bookingDetails.bookingId}</div> */}

                            <div className='flex justify-center font-bold pb-3'>Your expertise with {bookingDetails?.doctorId?.firstName} {bookingDetails?.doctorId?.lastName}</div>

                            <RatingQuestion
                                    question="How was your waiting time?"
                                    rating={ratings.waitingTime}
                                    // isError={isError}
                                    // isDisable={true}
                                    // onRatingChange={(rating) => handleRatingChange('waitingTime', rating)}
                                />
                                <RatingQuestion
                                    question="How was doctor’s behavior?"
                                    rating={ratings.doctorBehavior}
                                    // isError={isError}
                                    // isDisable={true}
                                    // onRatingChange={(rating) => handleRatingChange('doctorBehavior', rating)}
                                />
                                <RatingQuestion
                                    question="Your Overall Experience?"
                                    rating={ratings.overallExperience}
                                    // isError={isError}
                                    // isDisable={true}
                                    // onRatingChange={(rating) => handleRatingChange('overallExperience', rating)}
                                />
                            <div className='flex justify-center gap-2 p-3'>
                                <div className='font-bold text-sm'>Review: </div>
                                <div className='text-sm'>{review}</div>
                            </div>
                        </div>
                        }

                        {bookingDetails?.rating?.length === 0 && <div className="bg-white rounded-lg w-2/3 mx-4 text-text shadow">
                            <div className='flex justify-center'>
                                <img
                                    className="h-20 w-20 rounded-full mt-5 mb-3"
                                    src={bookingDetails?.doctorId?.profilePic?.original || defaultImage}
                                    alt="user"
                                />
                            </div>
                            {/* <div className='flex justify-center font-bold' >Appointment Id: {bookingDetails.bookingId}</div> */}

                            <div className='flex justify-center font-bold pb-4'>How was your expertise with {bookingDetails?.doctorId?.firstName} {bookingDetails?.doctorId?.lastName}?</div>

                            <form onSubmit={handleSubmit}>
                                <RatingQuestion
                                    question="How was your waiting time?"
                                    rating={ratings.waitingTime}
                                    isError={isError}
                                    onRatingChange={(rating) => handleRatingChange('waitingTime', rating)}
                                />
                                <RatingQuestion
                                    question="How was doctor’s behavior?"
                                    rating={ratings.doctorBehavior}
                                    isError={isError}
                                    onRatingChange={(rating) => handleRatingChange('doctorBehavior', rating)}
                                />
                                <RatingQuestion
                                    question="Your Overall Experience?"
                                    rating={ratings.overallExperience}
                                    isError={isError}
                                    onRatingChange={(rating) => handleRatingChange('overallExperience', rating)}
                                />
                                <div className='flex justify-center pt-5'>
                                    <textarea
                                        id="review"
                                        type="text"
                                        name="review"
                                        placeholder="Write Review"
                                        className="peer border w-1/2 rounded-md text-sm px-2"
                                        rows={3}
                                        defaultValue={review}
                                        onChange={(e) => setReview(e.target.value)}
                                    />
                                </div>

                                {/* <div className='flex justify-around p-4'>
                                    <button type="button" className='px-4 py-2 border rounded-md text-primary' onClick={goBack}>Cancel</button>
                                    <button type="submit" className='bg-primary text-white px-4 y-2 rounded-md'>Submit</button>
                                </div> */}

                                <div className='flex justify-center p-4'>
                                    <button type="submit" className='bg-primary text-white px-4 py-2 rounded-md'>Submit</button>
                                </div>
                            </form>

                        </div>}
                    </div>
                )
            )}
        </div>
    );
}
export default ViewRating

const RatingQuestion = ({ question, rating, isError, onRatingChange }) => {
    console.log('onRatingChange', onRatingChange);
    const stars = [1, 2, 3, 4, 5];

    return (
        <div className='py-2'>
            <p className='flex justify-center text-sm font-bold py-2'>{question}</p>
            <div className='flex justify-center gap-2'>
                {stars.map((star) => (
                    <span key={star} onClick={() => onRatingChange && onRatingChange(star)}>
                        {star <= rating ? <BsFillStarFill color="#f1c40f" /> : <BsStar color="#f1c40f" />}
                    </span>
                ))}
            </div>
            {rating === 0 && isError === true && <p style={{ color: 'red' }} className='flex justify-center text-sm'>Rating is required</p>}
        </div>
    );
};
