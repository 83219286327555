import { useState, useCallback, useEffect } from "react";
import Room from "./Room/Room";
import { BaseAPI, BaseURL } from "../../services/services";
import { Link } from "react-router-dom";
import docImg from "../../assets/images/Doctor.svg";
import AgoraRoom from "./Room/AgoraRoom";
import Spinner from "../Loader/Spinner";

const VideoChat = () => {
  //local storage token
  const token = localStorage.getItem("token");

  //get url param
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("id");

  //getting auth token from api for video chat
  const [authToken, setAuthToken] = useState("");

  //state to check if user is left room or not
  const [leftRoom, setLeftRoom] = useState(false);

  //hadling logout form chat
  const handleLogout = useCallback(() => {
    setAuthToken(null);
    setLeftRoom(true);
  }, [authToken]);

  const getAgoraAuthToken = useCallback(async () => {
    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
      "Content-Type": "application/json",
    });

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    BaseAPI(
      `${BaseURL}/users/agora/token/${myParam}/publisher/123456`,
      requestOptions
    ).then((result) => {
      setAuthToken(result.data);
    });
  }, [authToken]);

  useEffect(() => {
    getAgoraAuthToken();
  }, [myParam]);

  if(authToken === '') return <Spinner />
  return (
    <>
      {authToken ? (
        <AgoraRoom
          authToken={authToken}
          handleLogout={handleLogout}
        />
      ) : leftRoom ? (
        <ExitScreen />
      ) : (
        ""
      )}
    </>
  );
};

const ExitScreen = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen w-full">
      <div className="flex flex-col rounded-lg items-center justify-center">
        <img src={docImg} alt="doctor" className="w-72 h-72 mb-2" />
        <h1 className="text-danger font-bold">You have left the room</h1>
        <div className="my-3">
          <Link
            to="/home/appointment"
            className="bg-secondary text-white p-3 rounded-lg mx-3"
          >
            Go To Appointments
          </Link>
          <button
            className="bg-primary text-white p-3 rounded-lg"
            onClick={() => window.location.reload()}
          >
            Rejoin
          </button>
        </div>
      </div>
    </div>
  );
};

export default VideoChat;

// import { useState, useCallback, useEffect } from "react";
// import Room from "./Room/Room";
// import { BaseAPI, BaseURL } from "../../services/services";
// import { Link } from "react-router-dom";
// import docImg from "../../assets/images/Doctor.svg";
// import AgoraRoom from "./Room/AgoraRoom";

// const VideoChat = () => {
//   //local storage token
//   const token = localStorage.getItem("token");

//   //get url param
//   const urlParams = new URLSearchParams(window.location.search);
//   const myParam = urlParams.get("id");

  

//   //getting auth token from api for video chat
//   const [authToken, setAuthToken] = useState("");
//   const [roomName, setRoomName] = useState("");
//   const [bookingData , setBookingData] = useState(null)

//   //state to check if user is left room or not
//   const [leftRoom, setLeftRoom] = useState(false);

//   //hadling logout form chat
//   const handleLogout = useCallback(() => {
//     setAuthToken(null);
//     setLeftRoom(true);
//   }, [authToken]);

//   const getAuthToken = useCallback(async () => {
//     let myHeaders = new Headers({
//       accept: "application/json",
//       authorization: `${token}`,
//       "Content-Type": "application/json",
//     });

//     var requestOptions = {
//       method: "GET",
//       headers: myHeaders,
//     };

//     BaseAPI(
//       `${BaseURL}/users/getAccessToken?bookingId=${myParam}`,
//       requestOptions
//     ).then((result) => {
      
//       setAuthToken(result.data.accessToken);
//       setRoomName(result.data.roomName);
//       setBookingData(result.data.bookingData)
//     });
//   }, [authToken]);

//   useEffect(() => {
//     getAuthToken();
//   }, [myParam]);

//   return (
//     <>
//       {authToken ? (
//         // <Room
//         <AgoraRoom
//           roomName={roomName}
//           bookingData={bookingData}
//           authToken={authToken}
//           handleLogout={handleLogout}
//         />
//       ) : leftRoom ? (
//         <ExitScreen />
//       ) : (
//         ""
//       )}
//     </>
//   );
// };

// const ExitScreen = () => {
//   return (
//     <div className="flex flex-col items-center justify-center h-screen w-full">
//       <div className="flex flex-col rounded-lg items-center justify-center">
//         <img src={docImg} alt="doctor" className="w-72 h-72 mb-2" />
//         <h1 className="text-danger font-bold">You have left the room</h1>
//         <div className="my-3">
//           <Link
//             to="/home/appointment"
//             className="bg-secondary text-white p-3 rounded-lg mx-3"
//           >
//             Go To Appointments
//           </Link>
//           <button
//             className="bg-primary text-white p-3 rounded-lg"
//             onClick={() => window.location.reload()}
//           >
//             Rejoin
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default VideoChat;
