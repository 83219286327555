/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect , useMemo } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Spinner from "../../components/Loader/Spinner";
import { useNavigate } from "react-router-dom";
import {
  BaseAPI,
  BaseURL,
  getQualificationsList,
  getSpecialitiesList,
} from "../../services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdGpsFixed } from "react-icons/md";
import {AiFillTag} from 'react-icons/ai'
import {
  HiLocationMarker,
  HiSearch,
  HiArrowCircleRight,
  HiArrowCircleLeft,
} from "react-icons/hi";
import { GoPrimitiveDot } from "react-icons/go";
import Autocomplete from "../../components/PlacesAutocomplete/Autocomplete";

import "react-datepicker/dist/react-datepicker.css";
import { BsFillStarFill, BsStar } from "react-icons/bs";

const Home = () => {
  const selectClass = "rounded-lg shadow-lg text-sm py-2 px-3";

  let navigate = useNavigate();
  
  // document.title = "MyHCare Patients";
  document.title = "TeleSecure360 Patients";

  const user = localStorage.getItem("userData");
  const token = localStorage.getItem("token");
  // const address = JSON.parse(user).addresses[0];

  const isGuest = localStorage.getItem("loggedInAsGuest");
  const defaultAddress = {
    latitude: 25.76905,
    longitude: -80.132234,
    address: 'Miami Beach, Florida'
  }
  const address = (isGuest === 'true' ? defaultAddress : JSON.parse(user)?.addresses[0]);

  const [isLoading, setisLoading] = useState(false);
  const [searchText, setsearchText] = useState("");
  const [doctorsList, setdoctorsList] = useState(null);
  const [cords, setcords] = useState({
    lat: address.latitude,
    lng: address.longitude,
  });
  const [searchLength, setsearchLength] = useState({
    byName: 0,
    byPlace: 0,
  });
  const [qualificationsList, setqualificationsList] = useState([]);
  const [specialitiesList, setspecialitiesList] = useState([]);
  //filters
  const [qual , setQual] = useState('')
  const [spec , setSpec] = useState('')
  const [doctorCount, setDoctorCount] = useState(0);

  //search text header
  const  [text , setText] = useState('Recommended Doctors for You')

  const [recommends, setRecommends] = useState(null);

  const [showImage, setshowImage] = useState(false);
  const [doctors, setDoctors] = useState(null);


  //update doctor data getting doctor list  ( same as recommended)
  const updateDoctorList = (arr) => {
    // console.log(arr);
    let temp = [];
    arr.forEach((element) => {
      let obj = {};
      obj = {
        firstName: element.firstName,
        lastName: element.lastName,
        _id: element._id,
        specialities: element.specialityData ?? element?.specialities,
        addresses: element.addresses,
        profilePic: element.profilePic,
        consultationFee: element.consultationFee,
        consultationFeeAudioVideo: element.consultationFeeAudioVideo,
        consultationFeeInPerson: element.consultationFeeInPerson,
        avgRatings: element.avgRatings,
      };

      temp.push(obj);
    });

    // console.log("new array", temp);
    if(temp.length > 0)
    setDoctors(temp);
    else 
    setDoctors(null)
  };

  //getDate for esign
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = mm + "/" + dd + "/" + yyyy;

  const HandleSearch = (e) => {
    setsearchText((prev) => (prev = e.target.value));
    setsearchLength({
      ...searchLength,
      byName: e.target.value.length,
    });
  };

  //Get users current location
  async function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
    }
  }

  async function showPosition(position) {
    await setcords({
      ...cords,
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    });

    reloadResult(); //for showing the instant results
  }

  const reloadResult = async () => {
    getDoctorList(cords.lat, cords.lng);
  };

  const getRecommendedData = () => {
    setisLoading(true);

    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
    });

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    BaseAPI(`${BaseURL}/patient/getRecommendedData`, requestOptions)
      .then((result) => {
        // console.log(result);
        if (result.status !== 200) {
          toast.warn(result.message);
          if (result.status === 401) {
            localStorage.clear();
            navigate("/login");
          }
        }
        setRecommends(result.data);
        setDoctors(result.data.recommendedDoctors);
        updateDoctorList(result.data.recommendedDoctors);
        setisLoading(false);
      })
      .catch((err) => toast.error("Something went wrong!"))
      .finally(() => setisLoading(false));
  };

  const getDoctorList = (lat, lng) => {
    setisLoading(true);

    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
    });

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    BaseAPI(
      `${BaseURL}/patient/getDoctorsList?${
        searchText.length > 1 ? `searchUser=${searchText}&` : ""
      }longitude=${cords.lng}&latitude=${cords.lat}&radius=40&qualification=${
        qual
      }&speciality=${spec}&limit=100&isAdminVerified=true`,
      requestOptions
    )
      .then((result) => {
        if (result.status !== 200) {
          toast.warn(result.message);
          if (result.status === 401) {
            localStorage.clear();
            navigate("/login");
          }
        }
        setdoctorsList(result.data);
        // setRecommends(prev => null)
        updateDoctorList(result?.data?.users);
        setText(`${result?.data?.usersCount} doctors found near you`)
        
      })
      .catch((err) => toast.error("Something went wrong!"))
      .finally(() => setisLoading(false));
  };

  const filterClass = `mx-4 mt-5 `;

  useEffect(() => {
    getQualificationsList()
      .then((result) => setqualificationsList(result.qualification))
      .catch((err) => console.log(err));

    getSpecialitiesList()
      .then((result) => setspecialitiesList(result.speciality))
      .catch((err) => console.log(err));

    getRecommendedData();
  }, []);

  const BookAppointment = (id, doctor) => {
    navigate("/home/DoctorProfile", {
      state: { id: id, doctorProfile: doctor },
    });
  };

  // useEffect(() => {
  //   getDoctorList()
  // }, [qual , spec]);

  // useMemo(() => getDoctorList(), [qual , spec])

  //create new array for adding pagination
  const doctorCountArray = (arr) => {
    let paginatedArray = [];

    if (arr) {
      if (arr.slice(doctorCount, doctorCount + 10).length > 0)
        paginatedArray = arr.slice(doctorCount, doctorCount + 10);
      else setDoctorCount((prev) => prev * 0);
    }

    return paginatedArray;
  };

  const url =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

  return (
    <div className="Home bg-Light-gray min-h-screen w-full">
      <Navbar heading="Home" />
      <div
        className={` bg-white mx-4 py-6 mt-8 rounded-lg shadow-lg ${"mx-4"}`}
      >
        <div className="flex justify-between items-center w-auto wrap">
          <div className=" flex justify-between items-center w-auto gap-3 px-3 flex-grow">
            <div className="relative w-1/2">
              <input
                className="border rounded-lg p-2 w-full pl-8"
                placeholder="Search Doctor"
                onChange={(e) => HandleSearch(e)}
              />
              <HiSearch className="absolute left-1 bottom-2 text-lg opacity-50" />
            </div>
            <div className="relative  w-auto flex-grow">
              {/* <Autocomplete
                name={"filter"}
                personalInfo={cords}
                setpersonalInfo={setcords}
                Length={searchLength}
                setLength={setsearchLength}
                defaultValue={address.address}
              /> */}
              <input
                name="filter"
                className="border rounded-lg p-2 w-full pl-8"
                defaultValue={address.address}
                onChange={(e) => HandleSearch(e)}
              />
              <span
                onClick={async () => {
                  await getLocation();
                  setshowImage(true);
                }}
                className="text-secondary bg-white absolute right-2 top-2 text-lg cursor-pointer"
              >
                <MdGpsFixed />
              </span>
            </div>
            <button
              className="bg-primary  md:block mx-2 px-3 py-2 rounded-lg text-white"
              onClick={() => {
                setshowImage(true);
                getDoctorList(cords.lat, cords.lng);
              }}
            >
              
                <span className=" hidden md:inline-flex sm:hidden">Search Doctor</span>
              
                <HiSearch className="mx-3 text-lg inline-flex md:hidden" />
             
            </button>
          </div>
        </div>
      </div>
      <div className={filterClass}>
        {/* recommended Specialiities */}
        {/* {  <div className="flex flex-col gap-4">
          <h1>Specialities</h1>
          <div>
            {recommends &&
              recommends?.recommendedSpecialities.map((item, i) => (
                <button
                  key={i}
                  className="p-2 flex flex-col bg-white rounded-lg shadow-lg items-center text-sm gap-3"
                  onClick={()=> {
                    console.log(item)
                    let temp;
                    temp = doctors.filter(doctor => {
                      return doctor.specialities.some((f) => {
                return  item._id === f._id;
  });
                    })
                    console.log( 'filtered array' , temp)
                    setDoctors(prev => temp)
                  }}
                >
                  <span className="flex justify-between items-center">
                    {" "}
                    <img
                      src={url}
                      className="h-14 rounded-full"
                      alt="logo"
                    />{" "}
                  </span>
                  <span>{item.specialityName}</span>
                </button>
              ))}
          </div>
        </div>} */}
        <div className="flex justify-between items-center flex-col md:flex-row ">
          <div className="order-last md:order-first md:my-0 my-3">
            <h2 className="md:text-lg text-text ">
             {text}
            </h2>
          </div>
          {/* <div className="space-x-2"> */}
          <div className="flex flex-wrap gap-2">
            <select
              className={`${selectClass} w-32 md:w-auto `}
              onChange={(e) => {
                setQual(e.target.value)
                // setfilter({ ...filter, ["qualification"]: e.target.value });
                // getDoctorList();
                // console.log(qual)
              }}
            >
              <option value="none" selected disabled hidden>
                Qualification
              </option>
              <option value="">All</option>

              {qualificationsList &&
                qualificationsList.map((qualification, index) => {
                  return (
                    <option key={index} value={qualification._id}>
                      {qualification.qualificationName}
                    </option>
                  );
                })}
            </select>
            <select
              className={selectClass}
              onChange={(e) => {
                setSpec( e.target.value)
                // setfilter({ ...filter, ["speciality"]: e.target.value });
                // getDoctorList();
              }}
            >
              <option value="none" selected disabled hidden>
                Speciality
              </option>
              <option value="">All</option>
              {specialitiesList &&
                specialitiesList.map((speciality, index) => {
                  return (
                    <option key={index} value={speciality._id}>
                      {speciality.specialityName}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className={filterClass}>
          {/* Recommended Doctor */}
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5 text-sm">
            {doctors ?
              doctorCountArray(doctors).map((doctor, i) => (
                <div className="rounded-lg bg-white justify-between p-4 flex flex-col gap-3 shadow hover:shadow-lg  transition ease-in-out">
                  {/* image conatiner */}
                  <div className="-mt-5 -ml-2 flex items-center rounded-full h-10 w-10">
                    {doctor.avgRatings > 0 ? <div className="flex items-center gap-0.5">{doctor.avgRatings}<BsFillStarFill color="#f1c40f" /> </div> : <BsStar color="#f1c40f" />}
                  </div>
                  <div className="flex justify-center items-center">
                    <img
                      className="object-cover rounded-full h-16 lg:h-24 md:h-18 w-auto"
                      src={doctor?.profilePic?.thumbnail || url}
                      alt="doctor"
                    />
                  </div>
                  {/* info conatiner */}
                  <div>
                    <p>{`${doctor?.firstName} ${doctor?.lastName}`}</p>
                    <p className="font-bold text-primary flex items-center gap-2">
                      <span><GoPrimitiveDot/></span>
                      {doctor?.specialities
                        .map((i) => i.specialityName)
                        .join(", ")}
                        {/* .join(" ,")} */}
                    </p>
                    <p className=" text-secondary flex items-center gap-2">
                      <span><AiFillTag/></span>
                      {/* {`$ ${doctor.consultationFee}`} */}
                      {`$ ${doctor.consultationFeeAudioVideo}*`}
                    </p>
                    <p className="opacity-60 flex gap-2 items-center">
                        <span><HiLocationMarker/></span>
                      {doctor?.addresses[0]?.address}
                    </p>
                  </div>
                  {/* Call button */}
                  <button
                    className="btn btn-outline text-base tracking-wide hover:bg-primary hover:text-white transition ease-in-out "
                    onClick={() => BookAppointment(doctor._id, doctor)}
                  >
                    Book Appointment
                  </button>
                </div>
              )) :
          <p className="md:col-span-3 lg:col-span-5 col-span-2 text-center text-xl font-bold text-danger" >No Doctor Found</p>
              } 
          </div>
          {/* {doctorsList && doctorsList?.usersCount > 0 ? (
            doctorCountArray( doctorsList?.users ).map((doctor, i) => {
              return (
                <div
                  key={i}
                  className="flex md:flex-row flex-col justify-between text-text px-2 md:px-8 py-5 border-Light-text  border-b-2"
                >
                  <div className="flex items-center justify-between ">
                    <div className="mr-10 md:mr-0">
                      <img
                        className="rounded-full h-20 w-20 lg:h-24 lg:w-24 object-cover"
                        src={doctor?.profilePic?.original || url}
                        alt="Profile"
                      />
                    </div>
                    <div className="md:mx-5">
                      <h2 className="text-lg font-bold px-3 md:px-0">
                        {doctor.fullName}
                      </h2>
                      <p>
                        <span key={i} className="flex items-center">
                          <span className="text-primary mr-3">
                            <GoPrimitiveDot />
                          </span>
                          {doctor.specialityData
                            .map((item, i) => {
                              return item.specialityName;
                            })
                            .join(", ")}
                        </span>
                      </p>
                      <p className="flex space-x-5 items-center">
                        <span className="text-primary mr-3">
                          <HiLocationMarker />
                        </span>
                        {doctor?.addresses[0]?.address}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center justify-center flex-col mt-4 md:mt-0">
                    <h2 className="text-primary font-bold text-right md:text-left px-4 md:px-0">
                      Consultation Fees : ${" "}
                      {doctor?.consultationFee != "" ||
                      doctor.consultationFee === null
                        ? doctor?.consultationFee
                        : "20"}
                    </h2>
                    <button
                      className="bg-primary text-white rounded-lg px-4 py-3 mt-2 mx-auto md:mx-0"
                      onClick={() => BookAppointment(doctor._id, doctor)}
                    >
                      Book appointment
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-center text-danger text-lg font-bold">
              {" "}
              No Doctor Found For This Location{" "}
            </div>
          )} */}
        </div>
      )}
      {doctorsList &&  doctorsList.usersCount > 10 && (
        <div
          className={`flex ${
            doctorCount === 0 ? "justify-end" : "justify-between"
          }  items-center m-4`}
        >
          {doctorCount !== 0 && (
            <button
              className="text-text flex items-center rounded-lg px-3 py-1 shadow gap-3 hover:shadow-lg"
              onClick={() => setDoctorCount((prev) => prev - 10)}
            >
              {" "}
              <HiArrowCircleLeft className="h-10" /> Previous
            </button>
          )}
          <button
            className="text-text flex items-center shadow px-3 py-1 rounded-lg gap-3 hover:shadow-lg"
            onClick={() => setDoctorCount((prev) => prev + 10)}
          >
            Next <HiArrowCircleRight className="h-10" />
          </button>
        </div>
      )}
    </div>
  );
};

export default Home;
