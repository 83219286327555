import React, { useState, useEffect, useMemo } from 'react'
import Table from '../../../../components/Table/Table';
import Spinner from "../../../../components/Loader/Spinner";
import { AiOutlineEye, AiOutlineDownload } from 'react-icons/ai'
import moment from 'moment'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BaseAPI, BaseURL } from "../../../../services/services";
import { timeConvert } from '../../../../services/Constants';

//session storage


function Invoices() {

    const user = localStorage.getItem("userData");
    const token = localStorage.getItem("token");
    const patientId = JSON.parse(user)?._id;

    const [invoices, setInvoices] = useState(null);
    const [isLoading, setisLoading] = useState(false);
    const [searchText, setsearchText] = useState("");

    //getting booking list   searchDoctor
    const getInvoiceList = () => {
        setisLoading(true);

        let myHeaders = new Headers({
            accept: "application/json",
            authorization: `${token}`,
            "Content-Type": "application/json",
        });

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        BaseAPI(
            `${BaseURL}/doctor/getPdfList?patientId=${patientId}&pdfType=2`,
            requestOptions
        )
            .then((result) => {
                if (result.status !== 200) {
                    toast.error(result.message);
                } else {

                    setInvoices(result.data);
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("Something went wrong!");
            })
            .finally(() => setisLoading(false));
    };

    const getPdf = (id) => {
        let myHeaders = new Headers({
            accept: "application/json",
            authorization: `${token}`,
        });

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        return fetch(
            `${BaseURL}/doctor/getPdf?id=${id}`,
            requestOptions
        )
    }

    const handleViewPdf = ({ pdfDocumentId = '' }) => {
        console.log(pdfDocumentId)
        console.log(pdfDocumentId)
        getPdf(pdfDocumentId)
            .then((res) =>
                res.arrayBuffer()
            )
            .then(res => {
                console.log(res)
                // Create a Blob from the PDF Stream
                const file = new Blob([res], { type: 'application/pdf' })
                // Build a URL from the file  
                const fileURL = URL.createObjectURL(file)
                // Open the URL on new Window
                window.open(fileURL)
            })
            .catch(err => console.log(err))

    }

    //dummy data
    const data = useMemo(() => invoices, [invoices]);

    // table colummns
    const columns = useMemo(
        () => [
            {
                Header: "Appointment Id",
                Cell: ({ row }) => (
                  <div className="flex items-center text-text">
                      <p >{row.original?.appointmentuniqueId}</p>
                  </div>
                ),
              },
              {
                Header: "Patient Id",
                Cell: ({ row }) => (
                  <div className="flex items-center text-text">
                      <p >{row.original?.doctorUniqueId}</p>
                  </div>
                ),
              },
            {
                Header: "Doctor",
                Cell: ({ row }) => (
                    <div className="flex items-center text-text">
                        <p className="font-bold">{row.original.doctorName}</p>
                    </div>
                ),
            },
            // bookingDate
            // bookingDateAndTime: "2022-02-14T10:00:00.000Z"
            // bookingEndTime: "2022-02-14T11:00:00.000Z"
            {
                Header: "Appointment Date",
                Cell: ({ row }) => (
                    <div className="text-text">
                        {/* <p>{`${moment(row.original.bookingStartTime).format('MMM DD,YYYY hh:mm A')} - ${moment(row.original.bookingEndTime).format('hh:mm A')}`}</p> */}
                        <p>{`${moment.utc(row.original.bookingStartTime).format('MMM DD,YYYY')}, ${timeConvert(row.original.bookingStartTime.slice(11, 16))} - ${timeConvert(row.original.bookingEndTime.slice(11, 16))}`}</p>
                    </div>
                ),
            },

            {
                Header: "Actions",
                Cell: ({ row }) => (
                    <div className="flex items-center space-x-5 text-lg">
                        <button type='button' title='View' onClick={() => handleViewPdf(row.original)} > <AiOutlineEye /> </button>
                        <button type='button' title='Download' onClick={() => handleViewPdf(row.original)} className='text-secondary' > <AiOutlineDownload /> </button>
                    </div>
                ),
            },
        ],
        []
    );

    useEffect(() => {
        getInvoiceList()
    }, [])



    if (isLoading || !invoices) return <Spinner />;

    return (
        <Table
            className="bg-white mx-3"
            columns={columns}
            data={data}
            pageSize={10}
        />
    )
}

export default Invoices