import React, { useEffect, useState, useRef } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { BiVideo, BiVideoOff } from 'react-icons/bi';
import { AiOutlineAudio, AiOutlineAudioMuted } from 'react-icons/ai';
import { MdCallEnd } from 'react-icons/md';
import docImg from "../../../assets/images/Doctor.svg";
import defaultImg from "../../../assets/images/VideoDefault.svg";

const options = {
  appId: "7f9b654c261f42debd13ac886307663c",
  token: '007eJxTYCg5+LH78Idl0TeX9N4qL8k1my9cdbDJndekJqBu8k+fmW0KDOZplklmpibJRmaGaSZGKalJKYbGickWFmbGBuZmZsbJP0xD0hoCGRl490WxMDJAIIjPwhCSWlzCwAAAzfIgRA==',
};

const AgoraRoom = ({ authToken, handleLogout }) => {

  const [joined, setJoined] = useState(false);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [remoteUsers, setRemoteUsers] = useState([]);
  const [userLeft, setUserLeft] = useState('');

  const clientRef = useRef(null);
  const localAudioTrackRef = useRef(null);
  const localVideoTrackRef = useRef(null);

  const [remoteUsersMSG, setRemoteUsersMSG] = useState('');

  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("id");

  useEffect(() => {
    clientRef.current = AgoraRTC.createClient({ mode: "rtc", codec: "h264" });

    clientRef.current.on("user-published", async (user, mediaType) => {
      await clientRef.current?.subscribe(user, mediaType);
      console.log("Subscribe success");
      setRemoteUsersMSG('Subscribe success')
      setUserLeft('');

      setRemoteUsers(prevUsers => {
        const userExists = prevUsers.some(u => u.uid === user.uid);
        if (!userExists) {
          return [...prevUsers, user];
        }
        return prevUsers;
      });

      let remoteStreamContainer = document.getElementById(user.uid.toString());
      if (!remoteStreamContainer) {
        remoteStreamContainer = document.createElement("div");
        remoteStreamContainer.id = user.uid.toString();
        // remoteStreamContainer.style.width = "640px";
        // remoteStreamContainer.style.height = "480px";
        remoteStreamContainer.style.width = "100%";
        remoteStreamContainer.style.height = "80vh";
        document.getElementById("remote-stream")?.append(remoteStreamContainer);
      }

      if (mediaType === "video") {
        user.videoTrack?.play(remoteStreamContainer.id);
      }

      if (mediaType === "audio") {
        user.audioTrack?.play();
      }
    });

    clientRef.current.on("user-unpublished", (user) => {
      setRemoteUsersMSG('user-unpublished')
      // setRemoteUsers(prevUsers => prevUsers.filter(u => u.uid !== user.uid));
      // const playerContainer = document.getElementById(user.uid.toString());
      // if (playerContainer) playerContainer?.remove();

      clientRef.current.on("user-info-updated", (u, msg) => {
        if (msg === 'mute-video') {
          const playerContainer = document.getElementById(user.uid.toString());
          if (playerContainer) playerContainer?.remove();
        }
      })
      clientRef.current.on("user-left", (user, msg) => {
        setUserLeft('User left');
        if (msg === 'Quit') {
          const playerContainer = document.getElementById(user.uid.toString());
          if (playerContainer) playerContainer?.remove();
        }
      })
    });

    return () => {
      handleLeave();
      handleLogout();
    };
  }, []);

  useEffect(() => {
    // if (joined && localVideoTrackRef.current) {
    //   const localStreamElement = document.getElementById("local-stream");
    //   if (localStreamElement) {
    //     localVideoTrackRef.current.play("local-stream");
    //   } else {
    //     console.error('local-stream element not found');
    //   }
    // }
    if (joined && localVideoTrackRef.current) {
      const localStreamElement = document.getElementById("local-stream");
      if (localStreamElement) {
        console.log('Playing local video track...');
        localVideoTrackRef.current?.play("local-stream")?.then(() => {
          console.log('Local video track played successfully');
        }).catch(error => {
          console.error('Error playing local video track:', error);
        });
      } else {
        console.error('local-stream element not found');
      }
    }
  }, [joined, videoEnabled]);

  useEffect(() => {
    handleSubmit();
  }, []);

  async function handleSubmit() {
    try {
      if (joined) {
        console.log("Already joined");
        return;
      }

      // const uid = await clientRef.current.join(options.appId, "Test", options.token, null);
      const uid = await clientRef.current.join(options.appId, myParam, authToken, "123456");
      console.log(`Joined with UID: ${uid}`);

      localAudioTrackRef.current = await AgoraRTC.createMicrophoneAudioTrack();
      localVideoTrackRef.current = await AgoraRTC.createCameraVideoTrack();

      // clientRef.current.on("user-published", async (user, mediaType) => {
      //   await clientRef.current?.subscribe(user, mediaType);
      //   console.log("Subscribe success");

      //   setRemoteUsers(prevUsers => [...prevUsers, user]);

      //   if (mediaType === "video") {
      //     const remoteStreamContainer = document.createElement("div");
      //     remoteStreamContainer.id = user.uid.toString();
      //     // remoteStreamContainer.style.width = "640px";
      //     // remoteStreamContainer.style.height = "480px";
      //     remoteStreamContainer.style.width = "100%";
      //     remoteStreamContainer.style.height = "80vh";
      //     document.getElementById("remote-stream")?.append(remoteStreamContainer);

      //     user.videoTrack?.play(remoteStreamContainer.id);
      //   }

      //   if (mediaType === "audio") {
      //     user.audioTrack?.play();
      //   }
      // });

      // clientRef.current.on("user-unpublished", (user) => {
      //   setRemoteUsers(prevUsers => prevUsers.filter(u => u.uid !== user.uid));
      //   const playerContainer = document.getElementById(user.uid.toString());
      //   if (playerContainer) playerContainer.remove();
      // });

      await clientRef.current.publish([localAudioTrackRef.current, localVideoTrackRef.current]);
      console.log("Publish success");
      setJoined(true);
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  }

  async function handleLeave() {
    if (!joined) {
      return;
    }
    localAudioTrackRef.current?.close();
    localVideoTrackRef.current?.close();

    localAudioTrackRef.current = null;
    localVideoTrackRef.current = null;

    await clientRef.current.leave();
    setJoined(false);

    const localContainer = document.getElementById("local-stream");
    if (localContainer) localContainer.textContent = "";

    clientRef.current.remoteUsers.forEach((user) => {
      const playerContainer = document.getElementById(user.uid.toString());
      if (playerContainer) playerContainer.remove();
    });
    handleLogout();
  }

  const handleToggleAudio = () => {
    if (localAudioTrackRef.current) {
      localAudioTrackRef.current.setEnabled(!audioEnabled);
      setAudioEnabled(!audioEnabled);
    }
  };

  const handleToggleVideo = () => {
    if (localVideoTrackRef.current) {
      localVideoTrackRef.current.setEnabled(!videoEnabled);
      setVideoEnabled(!videoEnabled);
    }
  };
  return (
    <div style={{ height: '80vh', width: '100%' }}>
      <div className="flex flex-col h-full bg-white">
        <div className="h-full w-full relative mx-auto rounded-md flex-col justify-center items-center ">
          <div className="local-stream-container absolute bottom-0 z-20 rounded-md bg-red-500" style={{ width: '245px', height: '181px' }}>
            {joined ? (
              videoEnabled ? (
                <div id="local-stream" className="local-stream" style={{ width: '100%', height: '100%', backgroundColor: 'black' }} />
              ) : (
                <img src={defaultImg} alt="default" className="h-full w-full border border-black bg-white" />
              )
            ) : (
              ''
            )}
          </div>
          <div className="h-[50%] w-[50%] z-10 flex justify-center items-center">
            {clientRef?.current?.remoteUsers?.length > 0 ? (
              // clientRef?.current?.remoteUsers[0]?._video_muted_ === false ? (remoteUsers?.length === 0 ? <NoParticipant /> : <div id="remote-stream" className="stream remote-stream" style={{ width: '100%', height: '100%', }}></div>) :
              clientRef?.current?.remoteUsers[0]?._video_muted_ === false ? (userLeft === 'User left' ? <NoParticipant /> : <div id="remote-stream" className="stream remote-stream" style={{ width: '100%', height: '100%', }}></div>) :
                <img src={defaultImg} alt="default" className="h-72 w-72 mt-10" />
            ) : (
              clientRef?.current?.remoteUsers[0]?._video_muted_ === undefined && <NoParticipant />
            )}
          </div>
        </div>
      </div>
      <div className="flex space-x-5 mt-2 items-center justify-center w-full text-sm">
        <button
          onClick={handleToggleAudio}
          className={`rounded-lg  p-3 text-lg font-bold shadow ${audioEnabled ? 'bg-danger text-white' : 'bg-white text-primary'}`}
        >
          {audioEnabled ? <AiOutlineAudio /> : <AiOutlineAudioMuted />}
        </button>
        <button
          onClick={handleToggleVideo}
          className={`rounded-lg  p-3 text-lg font-bold  shadow ${videoEnabled ? 'bg-danger text-white' : 'bg-white text-primary'} disabled:cursor-not-allowed disabled:opacity-80`}
          title={'Turn camera off/on'}
        >
          {videoEnabled ? <BiVideo /> : <BiVideoOff />}
        </button>
        <button
          onClick={() => handleLeave()}
          disabled={!joined}
          className="rounded-lg  p-3 text-lg font-bold  shadow bg-white text-danger "
        >
          <MdCallEnd />
        </button>
      </div>
    </div>
  );
};

const NoParticipant = () => {
  return (
    <div className="rounded-lg p-5 absolute flex flex-col justify-center items-center mt-80">
      <img src={docImg} alt="" className="h-72 w-72 " />
      <p className="text-primary font-bold">
        Please wait for the other person to join.
      </p>
    </div>
  );
};

export default AgoraRoom;

// //////////////////////////////////////////////////////////////////////////////////////////////
// this code is working but having issue with mute & unmute audio

// import React, { useEffect, useState, useRef } from "react";
// import AgoraRTC from "agora-rtc-sdk-ng";
// import { BiVideo, BiVideoOff } from 'react-icons/bi';
// import { AiOutlineAudio, AiOutlineAudioMuted } from 'react-icons/ai';
// import { MdCallEnd } from 'react-icons/md';
// import docImg from "../../../assets/images/Doctor.svg";
// import defaultImg from "../../../assets/images/VideoDefault.svg";

// const options = {
//   appId: "7f9b654c261f42debd13ac886307663c",
//   token: '007eJxTYCg5+LH78Idl0TeX9N4qL8k1my9cdbDJndekJqBu8k+fmW0KDOZplklmpibJRmaGaSZGKalJKYbGickWFmbGBuZmZsbJP0xD0hoCGRl490WxMDJAIIjPwhCSWlzCwAAAzfIgRA==',
// };

// const AgoraRoom = ({ authToken, handleLogout }) => {

//   const [joined, setJoined] = useState(false);
//   const [audioEnabled, setAudioEnabled] = useState(true);
//   const [videoEnabled, setVideoEnabled] = useState(true);
//   const [remoteUsers, setRemoteUsers] = useState([]);

//   const clientRef = useRef(null);
//   const localAudioTrackRef = useRef(null);
//   const localVideoTrackRef = useRef(null);

//   const [remoteUsersMSG, setRemoteUsersMSG] = useState('');

//   const urlParams = new URLSearchParams(window.location.search);
//   const myParam = urlParams.get("id");

//   useEffect(() => {
//     clientRef.current = AgoraRTC.createClient({ mode: "rtc", codec: "h264" });

//     clientRef.current.on("user-published", async (user, mediaType) => {
//       await clientRef.current?.subscribe(user, mediaType);
//       console.log("Subscribe success");
//       setRemoteUsersMSG('Subscribe success')

//       setRemoteUsers(prevUsers => [...prevUsers, user]);

//       if (mediaType === "video") {
//         const remoteStreamContainer = document.createElement("div");
//         remoteStreamContainer.id = user.uid.toString();
//         // remoteStreamContainer.style.width = "640px";
//         // remoteStreamContainer.style.height = "480px";
//         remoteStreamContainer.style.width = "100%";
//         remoteStreamContainer.style.height = "80vh";
//         document.getElementById("remote-stream")?.append(remoteStreamContainer);

//         user.videoTrack?.play(remoteStreamContainer.id);
//       }

//       if (mediaType === "audio") {
//         user.audioTrack?.play();
//       }
//     });

//     clientRef.current.on("user-unpublished", (user) => {
//       setRemoteUsersMSG('user-unpublished')
//       setRemoteUsers(prevUsers => prevUsers.filter(u => u.uid !== user.uid));
//       const playerContainer = document.getElementById(user.uid.toString());
//       if (playerContainer) playerContainer.remove();
//     });

//     // clientRef.current.on("user-info-updated", (uid, msg) => {
//     //   console.log("User info updated:", uid, msg);
//     //   const user = clientRef.current.remoteUsers.find(u => u.uid === uid);
//     //   if (user) {
//     //     if (msg === "mute-audio" || msg === "unmute-audio") {
//     //       const playerContainer = document.getElementById(uid.toString());
//     //       if (playerContainer && user.videoTrack) {
//     //         user.videoTrack.play(playerContainer.id);
//     //       }
//     //     }
//     //   }
//     // });

//     clientRef.current.on("user-info-updated", (user, msg) => {
//       if (msg === "mute-video" || msg === "unmute-video") {
//         const playerContainer = document.getElementById(user.uid.toString());
//         if (playerContainer && user.videoTrack) {
//           user.videoTrack.play(playerContainer.id);
//         }
//       }
//     });

//     return () => {
//       handleLeave();
//       handleLogout();
//     };
//   }, []);

//   useEffect(() => {
//     // if (joined && localVideoTrackRef.current) {
//     //   const localStreamElement = document.getElementById("local-stream");
//     //   if (localStreamElement) {
//     //     localVideoTrackRef.current.play("local-stream");
//     //   } else {
//     //     console.error('local-stream element not found');
//     //   }
//     // }
//     if (joined && localVideoTrackRef.current) {
//       const localStreamElement = document.getElementById("local-stream");
//       if (localStreamElement) {
//         console.log('Playing local video track...');
//         localVideoTrackRef.current?.play("local-stream")?.then(() => {
//           console.log('Local video track played successfully');
//         }).catch(error => {
//           console.error('Error playing local video track:', error);
//         });
//       } else {
//         console.error('local-stream element not found');
//       }
//     }
//   }, [joined, videoEnabled]);

//   useEffect(() => {
//     handleSubmit();
//   }, []);

//   async function handleSubmit() {
//     try {
//       if (joined) {
//         console.log("Already joined");
//         return;
//       }

//       // const uid = await clientRef.current.join(options.appId, "Test", options.token, null);
//       const uid = await clientRef.current.join(options.appId, myParam, authToken, "123456");
//       console.log(`Joined with UID: ${uid}`);

//       localAudioTrackRef.current = await AgoraRTC.createMicrophoneAudioTrack();
//       localVideoTrackRef.current = await AgoraRTC.createCameraVideoTrack();

//       // clientRef.current.on("user-published", async (user, mediaType) => {
//       //   await clientRef.current?.subscribe(user, mediaType);
//       //   console.log("Subscribe success");

//       //   setRemoteUsers(prevUsers => [...prevUsers, user]);

//       //   if (mediaType === "video") {
//       //     const remoteStreamContainer = document.createElement("div");
//       //     remoteStreamContainer.id = user.uid.toString();
//       //     // remoteStreamContainer.style.width = "640px";
//       //     // remoteStreamContainer.style.height = "480px";
//       //     remoteStreamContainer.style.width = "100%";
//       //     remoteStreamContainer.style.height = "80vh";
//       //     document.getElementById("remote-stream")?.append(remoteStreamContainer);

//       //     user.videoTrack?.play(remoteStreamContainer.id);
//       //   }

//       //   if (mediaType === "audio") {
//       //     user.audioTrack?.play();
//       //   }
//       // });

//       // clientRef.current.on("user-unpublished", (user) => {
//       //   setRemoteUsers(prevUsers => prevUsers.filter(u => u.uid !== user.uid));
//       //   const playerContainer = document.getElementById(user.uid.toString());
//       //   if (playerContainer) playerContainer.remove();
//       // });

//       await clientRef.current.publish([localAudioTrackRef.current, localVideoTrackRef.current]);
//       console.log("Publish success");
//       setJoined(true);
//     } catch (error) {
//       console.error("Error in handleSubmit:", error);
//     }
//   }

//   async function handleLeave() {
//     if (!joined) {
//       return;
//     }
//     localAudioTrackRef.current?.close();
//     localVideoTrackRef.current?.close();

//     localAudioTrackRef.current = null;
//     localVideoTrackRef.current = null;

//     await clientRef.current.leave();
//     setJoined(false);

//     const localContainer = document.getElementById("local-stream");
//     if (localContainer) localContainer.textContent = "";

//     clientRef.current.remoteUsers.forEach((user) => {
//       const playerContainer = document.getElementById(user.uid.toString());
//       if (playerContainer) playerContainer.remove();
//     });
//     handleLogout();
//   }

//   const handleToggleAudio = () => {
//     if (localAudioTrackRef.current) {
//       localAudioTrackRef.current.setEnabled(!audioEnabled);
//       setAudioEnabled(!audioEnabled);
//     }
//   };

//   const handleToggleVideo = () => {
//     if (localVideoTrackRef.current) {
//       localVideoTrackRef.current.setEnabled(!videoEnabled);
//       setVideoEnabled(!videoEnabled);
//     }
//   };
//   return (
//     <div style={{ height: '80vh', width: '100%' }}>
//       <div className="flex flex-col h-full bg-white">
//         <div className="h-full w-full relative mx-auto rounded-md flex-col justify-center items-center ">
//           <div className="local-stream-container absolute bottom-0 z-20 rounded-md bg-red-500" style={{ width: '245px', height: '181px' }}>
//             {joined ? (
//               videoEnabled ? (
//                 <div id="local-stream" className="local-stream" style={{ width: '100%', height: '100%', backgroundColor: 'black' }} />
//               ) : (
//                 <img src={defaultImg} alt="default" className="h-full w-full border border-black bg-white" />
//               )
//             ) : (
//               ''
//             )}
//           </div>
//           <div className="h-[50%] w-[50%] z-10 flex justify-center items-center">
//             {clientRef?.current?.remoteUsers?.length > 0 ? (
//               clientRef?.current?.remoteUsers[0]?._video_muted_ === false ? (remoteUsers?.length === 0 ? <NoParticipant /> : <div id="remote-stream" className="stream remote-stream" style={{ width: '100%', height: '100%', }}></div>) :
//                 <img src={defaultImg} alt="default" className="h-72 w-72 mt-10" />
//             ) : (
//               clientRef?.current?.remoteUsers[0]?._video_muted_ === undefined && <NoParticipant />
//             )}
//           </div>
//         </div>
//       </div>
//       <div className="flex space-x-5 mt-2 items-center justify-center w-full text-sm">
//         <button
//           onClick={handleToggleAudio}
//           className={`rounded-lg  p-3 text-lg font-bold shadow ${audioEnabled ? 'bg-danger text-white' : 'bg-white text-primary'}`}
//         >
//           {audioEnabled ? <AiOutlineAudio /> : <AiOutlineAudioMuted />}
//         </button>
//         <button
//           onClick={handleToggleVideo}
//           className={`rounded-lg  p-3 text-lg font-bold  shadow ${videoEnabled ? 'bg-danger text-white' : 'bg-white text-primary'} disabled:cursor-not-allowed disabled:opacity-80`}
//           title={'Turn camera off/on'}
//         >
//           {videoEnabled ? <BiVideo /> : <BiVideoOff />}
//         </button>
//         <button
//           onClick={() => handleLeave()}
//           disabled={!joined}
//           className="rounded-lg  p-3 text-lg font-bold  shadow bg-white text-danger "
//         >
//           <MdCallEnd />
//         </button>
//       </div>
//     </div>
//   );
// };

// const NoParticipant = () => {
//   return (
//     <div className="rounded-lg p-5 absolute flex flex-col justify-center items-center mt-80">
//       <img src={docImg} alt="" className="h-72 w-72 " />
//       <p className="text-primary font-bold">
//         Please wait for the other person to join.
//       </p>
//     </div>
//   );
// };

// export default AgoraRoom;

// ////////////////////////////////////////////////////////////////////////////////////////


// import React, { useEffect, useState, useRef } from "react";
// import AgoraRTC from "agora-rtc-sdk-ng";
// import { BiVideo, BiVideoOff } from 'react-icons/bi';
// import { AiOutlineAudio, AiOutlineAudioMuted } from 'react-icons/ai';
// import { MdCallEnd } from 'react-icons/md';
// import docImg from "../../../assets/images/Doctor.svg";
// import defaultImg from "../../../assets/images/VideoDefault.svg";

// const options = {
//   appId: "7f9b654c261f42debd13ac886307663c",
//   token: '007eJxTYCg5+LH78Idl0TeX9N4qL8k1my9cdbDJndekJqBu8k+fmW0KDOZplklmpibJRmaGaSZGKalJKYbGickWFmbGBuZmZsbJP0xD0hoCGRl490WxMDJAIIjPwhCSWlzCwAAAzfIgRA==',
// };

// const AgoraRoom = ({ authToken, handleLogout }) => {

//   const [joined, setJoined] = useState(false);
//   const [audioEnabled, setAudioEnabled] = useState(true);
//   const [videoEnabled, setVideoEnabled] = useState(true);
//   const [remoteUsers, setRemoteUsers] = useState([]);

//   const clientRef = useRef(null);
//   const localAudioTrackRef = useRef(null);
//   const localVideoTrackRef = useRef(null);

//   const urlParams = new URLSearchParams(window.location.search);
//   const myParam = urlParams.get("id");

//   useEffect(() => {
//     clientRef.current = AgoraRTC.createClient({ mode: "rtc", codec: "h264" });

//     return () => {
//       handleLeave();
//       handleLogout();
//     };
//   }, []);

//   useEffect(() => {
//     // if (joined && localVideoTrackRef.current) {
//     //   const localStreamElement = document.getElementById("local-stream");
//     //   if (localStreamElement) {
//     //     localVideoTrackRef.current.play("local-stream");
//     //   } else {
//     //     console.error('local-stream element not found');
//     //   }
//     // }
//     if (joined && localVideoTrackRef.current) {
//       const localStreamElement = document.getElementById("local-stream");
//       if (localStreamElement) {
//         console.log('Playing local video track...');
//         localVideoTrackRef.current?.play("local-stream")?.then(() => {
//           console.log('Local video track played successfully');
//         }).catch(error => {
//           console.error('Error playing local video track:', error);
//         });
//       } else {
//         console.error('local-stream element not found');
//       }
//     }
//   }, [joined, videoEnabled]);

//   useEffect(() => {
//     handleSubmit();
//   }, []);

//   async function handleSubmit() {
//     try {
//       if (joined) {
//         console.log("Already joined");
//         return;
//       }

//       // const uid = await clientRef.current.join(options.appId, "Test", options.token, null);
//       const uid = await clientRef.current.join(options.appId, myParam, authToken, "123456");
//       console.log(`Joined with UID: ${uid}`);

//       localAudioTrackRef.current = await AgoraRTC.createMicrophoneAudioTrack();
//       localVideoTrackRef.current = await AgoraRTC.createCameraVideoTrack();

//       clientRef.current.on("user-published", async (user, mediaType) => {
//         await clientRef.current?.subscribe(user, mediaType);
//         console.log("Subscribe success");

//         setRemoteUsers(prevUsers => [...prevUsers, user]);

//         if (mediaType === "video") {
//           const remoteStreamContainer = document.createElement("div");
//           remoteStreamContainer.id = user.uid.toString();
//           // remoteStreamContainer.style.width = "640px";
//           // remoteStreamContainer.style.height = "480px";
//           remoteStreamContainer.style.width = "100%";
//           remoteStreamContainer.style.height = "80vh";
//           document.getElementById("remote-stream")?.append(remoteStreamContainer);

//           user.videoTrack?.play(remoteStreamContainer.id);
//         }

//         if (mediaType === "audio") {
//           user.audioTrack?.play();
//         }
//       });

//       clientRef.current.on("user-unpublished", (user) => {
//         setRemoteUsers(prevUsers => prevUsers.filter(u => u.uid !== user.uid));
//         const playerContainer = document.getElementById(user.uid.toString());
//         if (playerContainer) playerContainer.remove();
//       });

//       await clientRef.current.publish([localAudioTrackRef.current, localVideoTrackRef.current]);
//       console.log("Publish success");
//       setJoined(true);
//     } catch (error) {
//       console.error("Error in handleSubmit:", error);
//     }
//   }

//   async function handleLeave() {
//     if (!joined) {
//       return;
//     }
//     localAudioTrackRef.current?.close();
//     localVideoTrackRef.current?.close();

//     localAudioTrackRef.current = null;
//     localVideoTrackRef.current = null;

//     await clientRef.current.leave();
//     setJoined(false);

//     const localContainer = document.getElementById("local-stream");
//     if (localContainer) localContainer.textContent = "";

//     clientRef.current.remoteUsers.forEach((user) => {
//       const playerContainer = document.getElementById(user.uid.toString());
//       if (playerContainer) playerContainer.remove();
//     });
//     handleLogout();
//   }

//   const handleToggleAudio = () => {
//     if (localAudioTrackRef.current) {
//       localAudioTrackRef.current.setEnabled(!audioEnabled);
//       setAudioEnabled(!audioEnabled);
//     }
//   };

//   const handleToggleVideo = () => {
//     if (localVideoTrackRef.current) {
//       localVideoTrackRef.current.setEnabled(!videoEnabled);
//       setVideoEnabled(!videoEnabled);
//     }
//   };
//   return (
//     <div style={{ height: '80vh', width: '100%' }}>
//       <div className="flex flex-col h-full bg-white">
//         <div className="h-full w-full relative mx-auto rounded-md flex-col justify-center items-center ">
//           <div className="local-stream-container absolute bottom-0 z-20 rounded-md bg-red-500" style={{ width: '245px', height: '181px' }}>
//             {joined ? (
//               videoEnabled ? (
//                 <div id="local-stream" className="local-stream" style={{ width: '100%', height: '100%', backgroundColor: 'black' }} />
//               ) : (
//                 <img src={defaultImg} alt="default" className="h-full w-full border border-black bg-white" />
//               )
//             ) : (
//               ''
//             )}
//           </div>
//           <div className="h-[50%] w-[50%] z-10 flex justify-center items-center">
//             {clientRef?.current?.remoteUsers?.length > 0 ? (
//               clientRef?.current?.remoteUsers[0]?._video_muted_ === false ? (remoteUsers?.length === 0 ? <NoParticipant /> : <div id="remote-stream" className="stream remote-stream" style={{ width: '100%', height: '100%', }}></div>) :
//                 <img src={defaultImg} alt="default" className="h-72 w-72 mt-10" />
//             ) : (
//               clientRef?.current?.remoteUsers[0]?._video_muted_ === undefined && <NoParticipant />
//             )}
//           </div>
//         </div>
//       </div>
//       <div className="flex space-x-5 mt-2 items-center justify-center w-full text-sm">
//         <button
//           onClick={handleToggleAudio}
//           className={`rounded-lg  p-3 text-lg font-bold shadow ${audioEnabled ? 'bg-danger text-white' : 'bg-white text-primary'}`}
//         >
//           {audioEnabled ? <AiOutlineAudio /> : <AiOutlineAudioMuted />}
//         </button>
//         <button
//           onClick={handleToggleVideo}
//           className={`rounded-lg  p-3 text-lg font-bold  shadow ${videoEnabled ? 'bg-danger text-white' : 'bg-white text-primary'} disabled:cursor-not-allowed disabled:opacity-80`}
//           title={'Turn camera off/on'}
//         >
//           {videoEnabled ? <BiVideo /> : <BiVideoOff />}
//         </button>
//         <button
//           onClick={() => handleLeave()}
//           disabled={!joined}
//           className="rounded-lg  p-3 text-lg font-bold  shadow bg-white text-danger "
//         >
//           <MdCallEnd />
//         </button>
//       </div>
//     </div>
//   );
// };

// const NoParticipant = () => {
//   return (
//     <div className="rounded-lg p-5 absolute flex flex-col justify-center items-center mt-80">
//       <img src={docImg} alt="" className="h-72 w-72 " />
//       <p className="text-primary font-bold">
//         Please wait for the other person to join.
//       </p>
//     </div>
//   );
// };

// export default AgoraRoom;
