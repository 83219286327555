import React, { useState } from 'react'
import Navbar from '../../../components/Navbar/Navbar'
import { useNavigate } from "react-router-dom";
import { HiOutlineChevronLeft } from "react-icons/hi";
import Clinical from './tabs/Clinical';
import Family from './tabs/Family/Family';

function MedicalData() {

    //tab states
    const [tab, setTab] = useState(1);

    const navigate = useNavigate()

    const goBack = () => {
        navigate(-1);
    };

    return (
        <div className="Home bg-Light-gray min-h-screen w-full text-text">
            <Navbar heading="Home / Clinical Details" />
            <div className='px-3 flex flex-col gap-4'>
                {/* ---header---- */}
                <div className="flex text-text  items-center justify-between ">
                    <div className='flex items-center gap-2'>
                        <div
                            className="bg-white rounded-lg cursor-pointer p-2 mx-3 shadow-back text-lg font-bold"
                            onClick={goBack}
                        >
                            <HiOutlineChevronLeft />
                        </div>
                        <h1 className="text-lg font-bold">{tab === 1 ? 'Intake Form' : 'Family History'}</h1>
                    </div>
                    {/* ---tab selector--- */}
                    <div className='flex items-center gap-4'>
                        {/* <button type='button' onClick={() => setTab(1)} className={`${tab === 1 ? 'font-bold border-b-2 border-primary-500' : ''}  `} >Intake Form</button>
                        <button type='button' onClick={() => setTab(2)} className={`${tab === 2 ? 'font-bold border-b-2 border-primary-500' : ''}  `} >Family History</button> */}
                        <button type='button' onClick={() => setTab(1)} className={`${tab === 1 ? 'font-bold border-b-2 border-[#01A1FF]' : ''}  `} >Intake Form</button>
                        <button type='button' onClick={() => setTab(2)} className={`${tab === 2 ? 'font-bold border-b-2 border-[#01A1FF]' : ''}  `} >Family History</button>
                    </div>
                </div>

                {/* ----tables---- */}
                <div>
                    {
                        tab === 1 ? <Clinical /> : <Family />
                    }
                </div>
            </div>
        </div>
    )
}

export default MedicalData