import './App.css';
import React , { useEffect } from 'react'
import { nanoid } from 'nanoid';
import Routes from './Routes/Routes';
import { ToastContainer } from 'react-toastify';


function App() {


  useEffect(() => {
    const uuid = localStorage.getItem('uuid');
    if (!uuid) localStorage.setItem('uuid', nanoid());

  }, []);

  return (
    <div>
      <Routes/>
      <ToastContainer hideProgressBar />
    </div>
  );
}

export default App;
