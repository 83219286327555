import React from 'react'
import {
    Page, Text, Document, StyleSheet, Font, Image,View
  } from '@react-pdf/renderer';
import Logo from '../assets/images/LogoCenter.png'
import Sign from '../assets/images/sign.png'

  Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
  });
  
  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      fontFamily: 'Oswald',
    },
    author: {
      fontSize: 16,
      textAlign: 'center',
      marginBottom: 40,
      fontWeight:'medium'
    },
    subtitle: {
      fontSize: 18,
      margin: 12,
      fontFamily: 'Oswald',
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Times-Roman',
      lineHeight:1
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 160,
      height:'15vh',
      width:'30vw'
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
    },
    container:{
        flexDirection:'row',
        justifyContent:'space-between',
        marginTop:'10vh'
        
    },
    signature:{
       height:'13vh',
      width:'25vw'
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
  });

  const date = new Date().toDateString();

  //getting patient signature.
  const user = localStorage.getItem("userData");
  const UserInfo = JSON.parse(user);

  const bookingDetails = JSON.parse(sessionStorage.getItem('details'))

  

  const signature = UserInfo?.eSignature

  

  
  const NewYork = ({verified=false , details=null}) => {

    

    let patientName = `${details?.patientId?.firstName} ${details?.patientId?.middleName? details?.patientId?.middleName:''} ${details?.patientId?.lastName}`

    let doctorName = `${details?.doctorId?.firstName} ${details?.doctorId?.middleName? details?.patientId?.middleName:''} ${details?.doctorId?.lastName}`
      
    

    const First = () =>  ` I, the undersigned, agree not to make any legal claim or complaint or commence any proceeding against ${doctorName} (hereinafter referred to as “Physician”), in providing me with a physician certification and/or registering me with the Department of Health Medical Marijuana Program Patient Registry as required by the Compassionate Care Act of the New York State Legislature of 2014.`

    const PatientName = ()=>  verified && `${patientName}`

  return (
    <Document>
      <Page style={styles.body}>
        <Image
          style={styles.image}
          src={Logo}
        />
        <Text style={styles.author}>Patient Consent and Waiver of Liability
 
</Text>
        <Text style={styles.text} children={<First/>}  />

        <Text  style={styles.text}>
        I am hereby giving voluntary informed consent to treatment with medical cannabis. The Physician has sufficiently explained the current state of knowledge in the medical community of the effectiveness of treatment of my condition with medical cannabis, the medically acceptable alternatives, and the potential risks and side effects. I understand that there are other treatment options and I am not obligated to seek treatment with this Physician.

        </Text>

        <Text  style={styles.text}>
        I release this Physician from any and all actions, causes of actions, claims, complaints, and demands for damages, loss of life, injury, economic or employment loss, positive results on drug screens, damage to reputation or character, termination of service or care by another healthcare professional, or whatsoever arising directly or indirectly as a result of my use of Medical Cannabis.
        </Text>

        <Text  style={styles.text}>
        I hereby affirm I am assuming all risks associated with the use of medical cannabis, both foreseeable and unforeseeable, that may occur now or anytime in the future.
        </Text>

        <Text style={styles.text}>
        The Federal Food and Drug Administration (FDA) approves all drugs prescribed by physicians, Cannabis is not an FDA approved medication. The use of cannabis in any form has not been evaluated by the FDA. The use of cannabis is not intended to diagnose, treat, or cure any disease.
        </Text>

        <Text style={styles.text}>
        The certification of a qualifying condition by this Physician does not constitute a prescription to use medical cannabis. The decision to use medicinal cannabis should not be based solely on this Physician’s certification. The patient is advised to consult with their primary care physician, private legal counsel, and perform their own independent research before using medical cannabis..
        </Text>

        <Text style={styles.text}>
        The Physician cannot write a prescription for medicinal cannabis and has no control over the content of the medication. The effects or the adverse risks of whichever medicinal cannabis product you decide to consume vary from patient to patient.
        </Text>

        <Text style={styles.text}>
        The Physician may not be able to provide you with thoroughly researched conclusions regarding all the potential benefits and adverse risks of medicinal cannabis use for your qualifying medical condition. The scientific research on cannabis is incomplete and does not meet the high requirements for all other medicines approved by the FDA.
        </Text>

        <Text style={styles.text}>
        The Physician may enter a dose and route order for the use of medicinal cannabis. You are solely responsible for administering medicinal cannabis as your condition warrants, as determined by your own judgement and are solely responsible for all the consequences.
        </Text>

        <Text style={styles.text}>
        Medicinal cannabis is generally well tolerated by patients. If a route/dose is not entered by the Physician, the patient will be referred to discuss dosing and administration with a qualified professional at an New York approved dispensary.
        </Text>

        <Text style={styles.text}>
        Any side effects tend to be mild and temporary, usually lasting one week or less as patients adjust. Common side effects include irritated throat, dry mouth, elevated heart rate, mild time and space disorientation, mild euphoria, a general sense of well-being, and in some instances, drowsiness and decreased motivation. Any side effects must be reported to the Physician at the follow up visit, or as needed during the interim. There is a very rare, but potentially serious, side effect known as “First Dose Psychosis” that may occur with initial exposure to medicinal cannabis. Should respiratory problems or other ill effects be experienced in association with the use of medical marijuana, I agree to discontinue its use and report any or report such problems or effects to the attending physician. Although smoking marijuana has not been linked to lung cancer, smoking marijuana can cause respiratory harm, such as bronchitis. Many researchers agree that marijuana smoke contains known carcinogens (chemicals that can cause cancer), and

        </Text>

        <Text style={styles.text}>
        Many researchers agree that marijuana smoke contains known carcinogens (chemicals that can cause cancer), and that smoking marijuana may increase the risk of respiratory diseases and cancers of the lungs, mouth and tongue. I have been advised that cannabis (medical marijuana) smoke contains chemicals known as tars that may be harmful to my health. Vaporizers may substantially reduce many of the potentially harmful smoke and toxins that are normally present in marijuana smoke.
        </Text>

        <Text style={styles.text}>
        I acknowledge that the Physician will immediately deactivate and revoke my medical cannabis recommendation if I am arrested for, charged with, and/or convicted of any cannabis related activities. I acknowledge that the Physician will not testify on my behalf for a medically necessary defense related to any criminal cannabis charges or other criminal or civil matters. Revocation of the Physician Certification will represent an immediate termination of the patient-physician relationship. Furthermore, I release the Physician from all subpoenas, interrogatories, attestations, or testimonies related to any criminal cannabis charges or other criminal or civil matters. I agree to not require Physician to fill out any releases, clearances, forms from employers, or any other paperwork other than that which is required for your certification in the New York Medical Marijuana Program.
        </Text>

        <Text style={styles.text}>
        If I request changes or amendments to my recommendation, I understand a follow-up visit is required with Physician which could be subject to an additional out-of-pocket expense.

        </Text>

        <Text style={styles.text}>
        This release of liability is to be binding on my heirs, executors, and assigns. I have read, understand and agree with all statements in this Waiver of Liability.
        </Text>


        <View style={styles.container}>
            <View>
             { verified && <Image
                src={signature}
                style={styles.signature}
            />}
            <Text style={styles.text} children={<PatientName/>} />
            <Text style={styles.text}>{date}</Text>
            </View>
            
            
        </View>

        <View>
            <Text style={styles.author}>Certifications</Text>
        </View>

        <Text style={styles.text}>
        I certify the information provided to the Physician is true and accurate to the best of my knowledge
        </Text>

        <Text style={styles.text}>
        Any person who fraudulently submits false, misleading, or inaccurate information to the Physician for the purpose of being recommended medical cannabis by such Physician commits a misdemeanor under New York law, may be subject to prosecution, and will be removed from the Department of Health Medical Marijuana Program Patient Registry.
                </Text>

        <Text style={styles.text}>
        I additionally certify that I have been given actual Notice and understand that, notwithstanding the Compassionate Care Act of the New York State Legislature (2014):        </Text>

        <Text style={styles.text}>
1.  Cannabis is a prohibited Schedule I controlled substance under Federal law. The Act does not provide any immunity from or affirmative defense to arrest or prosecution under Federal law;        </Text>

        <Text style={styles.text}>
2.  Participation in the program is permitted only to the extent provided by the strict requirements of the Compassionate Care Act of the New York State Legislature of 2014;        </Text>

        <Text style={styles.text}>
3.  Any activity not sanctioned by the Act may be a violation of State or Federal law and could result in arrest, conviction, or incarceration;        </Text>

        <Text style={styles.text}>
   4.     Growing, distributing, or possessing cannabis in any capacity, except through a federally approved research program, may be a violation of State or Federal law and could result in arrest, conviction, or incarceration;
        </Text>

        <Text style={styles.text}>
   5.    Use of medical cannabis, or possessing a cannabis patient registry card, may affect an individual’s ability to receive or retain Federal or State licensure and other areas;
        </Text>

        <Text style={styles.text}>
   6.    I agree to tell the attending physician if I have ever had symptoms of depression, been psychotic, attempted suicide or had any other mental problems. I also agree to tell the attending physician if I have ever been prescribed or taken medicine for any of the conditions stated above. Furthermore, I understand that the attending physician does not suggest nor condone that I cease treatment and or medication that stabilize my mental or physical condition.

        </Text>

        <Text style={styles.text}>
   7.     Little is known regarding short- and long-term effects of cannabis on mother and baby during pregnancy, and while breastfeeding. I understand that Physician has advised me, and I have agreed to, not use cannabis while pregnant or breastfeeding.

        </Text>

        <Text style={styles.text}>
   8..    Medical cannabis should NOT be used when operating a vehicle, vessel (e.g. boat) or machinery
        </Text>

        <View style={styles.container}>
            <View>
             { verified && <Image
                src={signature
                }
                style={styles.signature}
            />}
            <Text style={styles.text}  render={() => verified &&  (
            `${patientName}`
          )}
          fixed />
            <Text style={styles.text}>{date}</Text>
            </View>
            
            
            
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )}
          fixed
        />
      </Page>
    </Document>
  )
}

export default NewYork