import React, { useState } from "react";
import Tag from "../../assets/icons/Fees.svg";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Rodal from "rodal";

const Bar = ({ doctorId, data, fee = "" , profile={}, consultationFeeAudioVideo = "" , consultationFeeInPerson = "" , doctorData}) => {
  const navigate = useNavigate();


  const user = localStorage.getItem("userData");
  const UserInfo = JSON.parse(user);
  
  const isGuest = localStorage.getItem("loggedInAsGuest");
  const [visible, setvisible] = useState(false);

  const ConfirmLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    // navigate("/login");
    navigate("/login", { state: { doctorId: doctorId, doctorProfile: doctorData } });
  };

  //to payment option ( for flow)
  // const toPaymentOption = ()=>{

  //   navigate('/home/paymentOption')

  // }

  //Confirm Booking
  const toPaymentOption = () => {
    if (data.bookingSlotId === "") {
      toast.warn("Please Select a Time Slot");
    } else if (data.aptType === undefined) {
      toast.warn("Please Select Appointment Type");
    } else if ((data.aptType === "video" || data.aptType === "audio") && consultationFeeAudioVideo === null) {
      toast.warn("Unable to proceed payment due to fee is null");
    } else if (data.aptType === "inPerson" && consultationFeeInPerson === null) {
      toast.warn("Unable to proceed payment due to fee is null");
    } else {
      if (isGuest === 'true') {
        setvisible(true)
      } else {
        setTimeout(() => {
          navigate("/home/paymentOption", {
            state: {
              doctorId: doctorId,
              clinicId: doctorData?.clinicId,
              doctorAddress: doctorData?.addresses,
              profile:profile,
              bookingDate: data.bookingDate,
              patientId: UserInfo._id,
              bookingSlotId: data.bookingSlotId,
              specialityId: data.specialityId,
              // fee: fee,
              // fee: data.aptType === "video" ? consultationFeeAudioVideo : data.aptType === "audio" ? consultationFeeAudioVideo : data.aptType === "inPerson" ? consultationFeeInPerson : null  ,
              fee: data.aptType === "inPerson" ? consultationFeeInPerson : data.aptType === "video" ? consultationFeeAudioVideo : data.aptType === "audio" && consultationFeeAudioVideo ,
              aptType: data?.aptType,
              dateString : `${moment(data.bookingDate.slice(0, 10)).format('MMMM DD , YYYY')} | Time :${data.time}`
            },
          });
        }, 100);
    }
    }

    // BaseAPI(`${BaseURL}/booking/createAppointment`, requestOptions)
    //       .then(result => {

    //           
    //           if(result.status === 200){
    //             toast.success('Booking Created Successfully')

    //         }else{
    //           toast.error(result.message)
    //         }

    //         })
  };

  return (
    <div className="bg-white shadow-back rounded-lg space-y-5  py-5 px-3 mx-4 mt-8 mb-5 flex flex-col md:flex-row justify-between md:items-center">
      {/* date and time */}
      <div className="flex items-center">
        <h1 className="text-text font-bold tracking-wide mx-3 ">
          Date :{" "}
          {/* {`${moment(data.bookingDate.slice(0, 10)).format('MMMM DD , YYYY')} | Time :${data.time}`} */}
          {`${(moment(data.bookingDate.slice(0, 10)).format('MMMM DD , YYYY')) === 'Invalid date' ? 'Select date and slot' : (moment(data.bookingDate.slice(0, 10)).format('MMMM DD , YYYY'))} | Time :${data.time}`}
        </h1>
      </div>

      {/* fees */}
      <div className="flex items-center">
        <img src={Tag} alt='calendar' className="w-8 h-8" />
        <div className="text-text space-2 mx-3 font-bold">
          {/* <p className="text-sm">Fee</p>
          <p>$ {fee}</p> */}
          {/* <p className="text-sm">Fee $ { data.aptType === undefined ? "- -" : (data.aptType === "video" ? consultationFeeAudioVideo : data.aptType === "audio" ? consultationFeeAudioVideo : data.aptType === "inPerson" ? consultationFeeInPerson : "- -" )}</p> */}
          <p className="text-sm">Fee $ {data.aptType === "video" ? (consultationFeeAudioVideo !== null ? consultationFeeAudioVideo :'- -') : data.aptType === "audio" ? (consultationFeeAudioVideo !== null ? consultationFeeAudioVideo :'- -') : data.aptType === "inPerson" ? (consultationFeeInPerson !== null ? consultationFeeInPerson :'- -') : "- -" }</p>
        </div>
      </div>

      {/* button */}
      <div className="flex justify-center items-center my-3 md:my-0">
        <button
          onClick={toPaymentOption}
          className="bg-primary rounded-lg px-4 py-2 text-white font-bold"
        >
          Proceed to payment
        </button>
      </div>
      <ToastContainer />

      <div className="top-0">
        <Rodal visible={visible} onClose={() => setvisible(false)}>
          <h1 className="text-center text-lg font-bold mt-8">
          Please Login to Proceed with Payment
          </h1>
          <div className="flex justify-between mt-14 text-white font-bold mx-5">
            <button
              className="px-5 py-2 rounded-lg bg-primary"
              onClick={() => setvisible(false)}
            >
              No
            </button>
            <button
              className="px-5 py-2 rounded-lg bg-danger"
              onClick={() => ConfirmLogout()}
            >
              Login
            </button>
          </div>
        </Rodal>
      </div>
    </div>
  );
};

export default Bar;
