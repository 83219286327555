import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import { AiOutlineEye, AiOutlineEyeInvisible , AiFillCheckCircle } from "react-icons/ai";
import { BiLockAlt } from "react-icons/bi";
import { loginAPI } from "../../services/services";
import { toast } from "react-toastify";
import { UserDetailsContext } from "../../services/Context/UserContext";
import { UserContext } from "../../services/Context/UserContext";
import * as Yup from "yup";
import { Form, Formik, Field, ErrorMessage } from "formik";
import Spin from '../../components/Loader/Spin'

const LoginForm = () => {
  const labelClass = "block text-base text-text";
  let navigate = useNavigate();

  const { userInfo, setuserInfo } = useContext(UserContext);
  const { state } = useLocation();

  //states
  const [show, setshow] = useState(false);
  const { loginDetails, setloginDetails } = useContext(UserDetailsContext);
  const [loading , setloading] = useState(false)

  // document.title = "Login - MyHCare Patients";
  document.title = "Login - TeleSecure360 Patients";

  
  // Clear all localStorage items
  localStorage.clear();

  //Data  handling and api
  const Login = (values) => {

    setloading(true)
    

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      ...values,
      deviceType: 3,
      uuid: localStorage.getItem("uuid"),
      role: 2,
    });

    

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    loginAPI(requestOptions).then(async (result) => {
      
      if (result.status == "200") {
        setuserInfo({
          ...userInfo , auth:true
        })
        localStorage.setItem("userData", JSON.stringify(result.data));
        localStorage.setItem("token", result.data.token);
        localStorage.setItem("emailUser", result.data.email);
        localStorage.setItem("loggedInAsGuest", false);
        if (result.data.isPasswordToBeChanged) {
          toast.warn("Your password is expired. Please change your password");
          setTimeout(() => {
            navigate("/forgot");
          }, 2000);
        } else if (result.data.isTwoFactorActuallyRequired) {
          navigate("/twoFactor");
          localStorage.setItem("Authenicated", true);
        } else {
          toast.success(result.message , {
            icon : <AiFillCheckCircle className="text-secondary text-lg" />
          });
          // navigate("/home/home");
          if (state !== null) {
            navigate("/home/DoctorProfile", {
              state: { id: state?.doctorId, doctorProfile: state?.doctorProfile },
            });
          } else {
            navigate("/home/home");
          }
        }
      } else {
        toast.warn(result.message);
      }
    }).catch(err=>console.log(err)).finally(()=>setloading(false));
  };

  const ClickHandler = () => {
    setshow(!show);
  };

  //context cleanup
  useEffect(() => {
    setuserInfo((prevState) => ({
      ...prevState,
      personalRecord: {
        "First Name": "",
        "Middle Name": "",
        "Last Name": "",
        Gender: null,
        "Phone Number": "",
        profile: "",
        DOB: "",
        Email: "",
        Password: "",
        "Confirm Password": "",
        Address: "",
        Timezone: null,
        State: "",
        long: 75.26589,
        lat: 34.98765,
      },
      medicalRecord: null,
    }));
  }, []);

  //validation schema
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleLoginAsGuest = () => {
    localStorage.setItem("loggedInAsGuest", true);
    navigate("/home/home")
  }

  return (
    <>
      <div className="min-w-screen">
        <Header hide={true} />
        <div className="Background bg-gray min-h-screen flex flex-col justify-center items-center">
          <div className="w-full mx-5 flex flex-col justify-center md:w-1/3">
            <div className="text-center text-text">
              <h1 className="text-base tracking-widest">LOGIN</h1>
              <h1 className="text-xl font-bold mb-3">Login to your account</h1>
              <h1 className="text-base">
                PLEASE FILL OUT THE CREDIENTIALS FOR YOUR ACCOUNT
              </h1>
            </div>
            <div className="bg-white mt-5 mx-5 rounded-2xl px-5 py-5 shadow-lg ">
              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={validationSchema}
                onSubmit={Login}
              >
                {({ errors, touched }) => (
                  <Form>
                    <div>
                      <label className={labelClass}>Email Address</label>
                      <Field
                        name="email"
                        className="border border-primary min-w-full rounded-lg px-5 py-3 focus:outline-primary"
                        placeholder="Enter Email"
                        autofocus
                      />
                      <p className="text-sm text-danger  text-right">
                        {errors.email}
                      </p>
                    </div>
                    <div className="mt-5 ">
                      <label className={labelClass}>Password</label>
                      <div className="relative">
                        <Field
                          type={`${show ? "text" : "password"}`}
                          className="border border-primary min-w-full rounded-lg px-5 py-3 focus:outline-primary relative"
                          placeholder="Enter Password"
                          name="password"
                        />
                        {!show ? (
                          <AiOutlineEye
                            className="absolute right-4 text-lg bottom-3 text-text"
                            onClick={() => {
                              ClickHandler();
                            }}
                          />
                        ) : (
                          <AiOutlineEyeInvisible
                            className="absolute right-4 text-lg bottom-3 text-text"
                            onClick={() => {
                              ClickHandler();
                            }}
                          />
                        )}
                      </div>
                      <p className="text-sm text-danger text-right">
                        {errors.password}
                      </p>
                    </div>
                    <div className="my-4">
                      <span className="float-right text-danger my-3 text-sm font-bold">
                        <Link to="/forgot">Forgot Password?</Link>
                      </span>
                    </div>
                    <button
                      type="submit"
                      className={`bg-secondary flex justify-center items-center text-white tracking-widest font-bold w-full rounded-lg py-3 mt-3 ${loading && 'cursor-not-allowed opacity-80'}`}
                    >
                     {loading && <Spin className='mr-3'/>} LOGIN <BiLockAlt className="ml-2" />{" "}
                    </button>
                  </Form>
                )}
              </Formik>
              {/* <div className="flex justify-center py-4 font-bold text-text">OR</div> */}
              <div className="flex items-center justify-center py-4">
                <div className="flex-grow border-t border-gray-300"></div>
                <span className="px-4 font-bold text-text">OR</span>
                <div className="flex-grow border-t border-gray-300"></div>
              </div>

              <div className="flex justify-center font-bold text-text" role="button" tabIndex="0" onClick={() => handleLoginAsGuest()}>Continue as guest</div>
              {/* <button type="button" onClick={() => handleLoginAsGuest()} className={`bg-secondary flex justify-center items-center text-white tracking-widest font-bold w-full rounded-lg py-3 ${loading && 'cursor-not-allowed opacity-80'}`}>
                {loading && <Spin className='mr-3'/>} Continue as Guest
              </button> */}
              <h3 className="text-center mt-3 ">
                Don't have an account?
                <Link to="/signup/personal" className="font-bold text-text">
                  {" "}
                  SIGN UP{" "}
                </Link>{" "}
                here
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
