import React, { useState } from "react";
import Header from "../../components/Header/Header";
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiFillCheckCircle,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import { BaseURL, BaseAPI } from "../../services/services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { Form, Formik, Field, ErrorMessage } from "formik";
import Spin from "../../components/Loader/Spin";

const Newpass = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("forgotPasswordToken");

  

  const labelClass = "block text-base text-text";

  //states
  const [show, setshow] = useState(false);

  const [show1, setshow1] = useState(false);

  //loading state
  const [loading , setloading] = useState(false)


  const ClickHandler = () => {
    setshow(!show);
  };

  const ResetPassword = (values) => {
      setloading(true)
    

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      forgotPasswordToken: myParam,
      ...values,
    });

    

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    BaseAPI(`${BaseURL}/users/resetPassword`, requestOptions)
      .then((result) => {
        
        if (result.status != "200") {
          toast.warn(result.message);
        } else {
          toast.success("Password reset successfully" , {
            icon : <AiFillCheckCircle className="text-secondary"/>
          });
          navigate("/login");
        }
      })
      .catch((err) => console.log(err)).finally(()=>setloading(false));
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password must be strong"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  return (
    <>
      <Header hide={true} />
      <div className="Background bg-gray min-h-screen flex flex-col justify-center items-center">
        <div className="w-full mx-3 flex flex-col justify-center md:w-1/3">
          <div className="text-center text-text">
            <h1 className="text-base tracking-widest">LOGIN</h1>
            <h1 className="text-xl font-bold">Set New Password</h1>
            <h1 className="text-base mt-3">
              Type in your desired new PASSWORD
            </h1>
          </div>
          <div className="bg-white mt-5 rounded-lg px-5 py-5 md:px-10 ">
            <Formik
              initialValues={{ password: "", confirmPassword: "" }}
              validationSchema={validationSchema}
              onSubmit={ResetPassword}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className="relative">
                    <label className={labelClass}>New Password</label>
                    <div className="relative">
                      <Field
                        type={`${show ? "text" : "password"}`}
                        name="password"
                        className="border border-primary min-w-full rounded-lg px-5 py-3 focus:outline-primary"
                        placeholder="New Password"
                      />
                      {!show ? (
                        <AiOutlineEye
                          className="absolute right-4 text-lg bottom-3 text-text"
                          onClick={() => {
                            ClickHandler();
                          }}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          className="absolute right-4 text-lg bottom-3 text-text"
                          onClick={() => {
                            ClickHandler();
                          }}
                        />
                      )}
                    </div>
                    <p className="text-danger text-sm text-right">
                      {errors.password}
                    </p>
                  </div>
                  <div className="mt-5 relative">
                    <label className={labelClass}>Confirm New Password</label>
                    <div className="relative">
                      <Field
                        type={`${show1 ? "text" : "password"}`}
                        name="confirmPassword"
                        className="border border-primary min-w-full rounded-lg px-5 py-3 focus:outline-primary"
                        placeholder="Confirm Password"
                      />
                      {!show1 ? (
                        <AiOutlineEye
                          className="absolute right-4 text-lg bottom-3 text-text"
                          onClick={() => {
                            setshow1(!show1);
                          }}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          className="absolute right-4 text-lg bottom-3 text-text"
                          onClick={() => {
                            setshow1(!show1);
                          }}
                        />
                      )}
                    </div>
                    <p className="text-sm text-danger text-right">
                      {errors.confirmPassword}
                    </p>
                  </div>

                  <button
                    type="submit"
                    className={`bg-secondary text-white w-full rounded-lg py-3 mt-5 flex items-center justify-center ${loading && 'cursor-not-allowed opacity-80'} `}
                  >
                     {loading && <Spin className='mr-2'/> }Save Password and Login again
                  </button>
                  <div className="bg-gray rounded-lg p-3 text-text text-sm flex my-2">
                    <span className="text-lg mr-3">
                      <AiOutlineInfoCircle />
                    </span>{" "}
                    <span>
                      Password must contain atleast one Uppercase (A), Lowercase
                      (a) , Digit (1) and Special ($) character. Password must
                      be atleast 8 characters and different from previous
                      passwords{" "}
                    </span>
                  </div>
                </Form>
              )}
            </Formik>
            <form onSubmit={ResetPassword}></form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Newpass;
