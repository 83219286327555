import amex from '../assets/cardBrands/amex.svg';
import diners from '../assets/cardBrands/diners.svg';
import discover from '../assets/cardBrands/discover.svg';
import placeholder from '../assets/cardBrands/Placeholder.svg';
import jcb from '../assets/cardBrands/jcb.svg';
import maestro from '../assets/cardBrands/maestro.svg';
import mastercard from '../assets/cardBrands/mastercard.svg';
import unionPay from '../assets/cardBrands/unionpay.svg';
import visa from '../assets/cardBrands/visa.svg';
import mir from '../assets/cardBrands/mir.svg';
import alipay from '../assets/cardBrands/alipay.svg';
import paypal from '../assets/cardBrands/paypal.svg';

export default {
  amex,
  diners,
  discover,
  placeholder,
  jcb,
  maestro,
  mastercard,
  unionPay,
  visa,
  mir,
  alipay,
  paypal,
};
