import React, { useState, useEffect, useCallback } from "react";
import Participant from "../Participant/Participant";
import {
  AiOutlineLogout,
  AiOutlineEyeInvisible,
  AiOutlineAudio,
  AiOutlineAudioMuted,
} from "react-icons/ai";
import { MdCallEnd } from 'react-icons/md';
import { BiVideo, BiVideoOff } from "react-icons/bi";
import docImg from "../../../assets/images/Doctor.svg";
const Video = require("twilio-video");

const Room = ({ authToken, roomName, handleLogout , bookingData={} }) => {
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [muted, setmuted] = useState(false);
  const [video, setVideo] = useState(false);


  const [localTrack, setLocalTrack] = useState({
    video: true,
    audio: true,
  });

  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };
    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };
    Video.connect(authToken, {
      name: roomName,
    })
      .then((room) => {
        
        setRoom(room);
        room.on("participantConnected", participantConnected);
        room.on("participantDisconnected", participantDisconnected);
        room.participants.forEach(participantConnected);
      })
      .catch((err) => console.log(err));

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication
          ) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [authToken]);

  // const muteUnmute = useCallback(() => {
  //   setLocalTrack({ ...localTrack, audio: !localTrack.audio });
  // }, [localTrack]);

  const muteUnmute = () => {
    if (!muted) {
      room.localParticipant.audioTracks.forEach((track, trackId) => {
        track.track.disable();
      });
    } else {
      room.localParticipant.audioTracks.forEach((track, trackId) => {
        track.track.enable();
      });
    }

    setmuted((prev) => !prev);
  };

  // const cameraOffOn = useCallback(() => {
  //   setLocalTrack({ ...localTrack, video: !localTrack.video });
  // }, [localTrack]);

  const cameraOffOn = () => {
    if (!video) {
      room.localParticipant.videoTracks.forEach((track, trackId) => {
        track.track.disable();
      });
    } else {
      room.localParticipant.videoTracks.forEach((track, trackId) => {
        track.track.enable();
      });
    }

    setVideo((prev) => !prev);
  };

  const exitRoom = () => {
    room.localParticipant.audioTracks.forEach((track, trackId) => {
      track.track.stop();
    });
    room.localParticipant.videoTracks.forEach((track, trackId) => {
      track.track.stop();
    });

    room.disconnect();

    handleLogout();
  };

  const remoteParticipants = participants.map((participant) => (
    <Participant
      key={participant.sid}
      participant={participant}
      video={localTrack.video}
      audio={localTrack.audio}
    />
  ));

  return (
    <div className=" min-h-screen Home  mx-5 my-2">
      <div className="flex flex-col h-full">
        <div className="flex text-text justify-between items-center ">
          <h1 className="text-lg font-bold">{`You are in Session with ${bookingData?.doctorName}`}</h1>
        </div>
        <div className=" h-4/5 3xl:h-4/5 3xl:flex-1 max-h-screen w-full relative mx-auto bg-white rounded-md flex-col justify-center items-center ">
          <div className="local-participant absolute bottom-2 left-2 z-20 bg-white rounded-md">
          {room ? (
            <Participant
              key={room.localParticipant.sid}
              participant={room.localParticipant}
            />
          ) : (
            ""
          )}
        </div>
          {/* remote participant */}
          <div className="h-full w-full z-10 flex justify-center items-center">
          {remoteParticipants.length > 0 ? (
            remoteParticipants
          ) : (
            <NoParticipant />
          )}
        </div>
          <div className="flex space-x-5 mt-2 items-center justify-center w-full text-sm">
            <button
              onClick={() => muteUnmute()}
              className={`rounded-lg  p-5 text-lg font-bold shadow ${muted ? 'bg-danger text-white' : 'bg-white text-primary'}`}
            >
              {muted ? <AiOutlineAudioMuted /> : <AiOutlineAudio />}
            </button>
            <button
              onClick={() => cameraOffOn()}
              className={`rounded-lg  p-5 text-lg font-bold  shadow ${video ? 'bg-danger text-white' : 'bg-white text-primary'} disabled:cursor-not-allowed disabled:opacity-80`}
              title={'Turn camera off/on'}
            >
              {video ? <BiVideoOff /> : <BiVideo />}
            </button>
            <button
              onClick={() => exitRoom()}
              className="rounded-lg  p-5 text-lg font-bold  shadow bg-white text-danger "
            >
              <MdCallEnd />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const NoParticipant = () => {
  return (
    <div className=" rounded-lg p-5 flex flex-col justify-center items-center">
      <img src={docImg} alt="" className="h-72 w-72" />
      <p className="text-primary font-bold">
        Please wait for the other person to join.
      </p>
    </div>
  );
};

export default Room;
