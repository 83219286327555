import React, { useState, useRef, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import { base64Upload } from "../../services/services";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import { toast } from "react-toastify";

const Sign = ({ open, close, setEsign }) => {
  const signRef = useRef(null);
  const [signature, setSignature] = useState("");
  const [visible, setvisible] = useState(open);

  useEffect(() => {
    setSignature(signRef.current.toDataURL());
  }, [signRef.current]);

  const handleSave = () => {
    
    
    !signRef.current.isEmpty() ? base64Upload(signRef.current.toDataURL())
      .then((result) => {
        
        setEsign(result.data);
      })
      .then(() => {
        close(false);
      })
      .catch((err) => {
        console.log(err);
      }) : toast.error('Signature is empty')
  };

  return (
    <>
      <Rodal
        visible={open}
        onClose={() => close(false)}
        height={350}
        width={520}
      >
        <h1>Please Sign here</h1>
        <SignatureCanvas
          penColor="#275C53"
          ref={signRef}
          canvasProps={{
            width: 500,
            height: 200,
            className: "bg-gray rounded-lg",
          }}
        />

        <div className="flex justify-between items-center mt-5">
          <button
            className="px-3 py-2 bg-danger rounded-lg text-white"
            onClick={() => signRef.current.clear()}
          >
            Clear
          </button>
          <button
            className="px-3 py-2 bg-primary rounded-lg text-white"
            onClick={() => {
              setSignature(signRef.current.toDataURL());
              handleSave();
            }}
          >
            Save
          </button>
        </div>
      </Rodal>
    </>
  );
};

export default Sign;
