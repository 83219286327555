/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import {
    Form, Formik, Field, ErrorMessage,
} from 'formik';
import moment from 'moment';
import { BaseAPI, BaseURL } from "../../../../services/services";
import Spin from '../../../../components/Loader/Spin';
import { toast } from "react-toastify";
import Spinner from '../../../../components/Loader/Spinner';


const genderOptions = [
    {
        name: 'Male',
        values: 'male',
    },
];

const confirmationOptions = [
    {
        name: 'Yes',
        value: 'yes',
    },
    {
        name: 'No',
        value: 'no',
    },
    {
        name: 'Sometimes',
        value: 'sometime',
    },
];

const validationSchema = Yup.object({

    height: Yup.number().nullable().required('Required'),
    weight: Yup.number().nullable().required('Required'),
    allergies: Yup.string().nullable().required('Required'),
    occupation: Yup.string().nullable().required('Required'),
    alcoholIntake: Yup.string().nullable().required('Required'),
    tobacco: Yup.string().nullable().required('Required'),
    drugs: Yup.string().nullable().required('Required'),
    illness: Yup.string().nullable().required('Required'),
    diagnosisDate: Yup.date().nullable().required('Required'),

});

const defaultValues = {
    height: '',
    weight: '',
    allergies: '',
    occupation: '',
    alcoholIntake: '',
    tobacco: 'no',
    drugs: 'no',
    illness: '',
    diagnosisDate: '',
};

const defaultImage =
    "https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-image-182145777.jpg";

function Clinical() {

    const user = localStorage.getItem("userData");
    const token = localStorage.getItem("token");
    const UserInfo = JSON.parse(user);
    const clinicalDetails = UserInfo?.clinicalDetails

    // console.log(clinicalDetails)


    const [selectedData, setSelectedData] = useState();
    const [selectedVals, setSelectedVals] = useState();

    // loader state-----
    const [isUpdating, setIsUpdating] = useState(false);


    const handleFormSubmit = (values) => {

        setIsUpdating(true);

        // console.log(values)

        let myHeaders = new Headers({
            accept: "application/json",
            authorization: `${token}`,
            "Content-Type": "application/json",
        });

        var raw = JSON.stringify(values);

        var requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        BaseAPI(`${BaseURL}/patient/updateClinicalData`, requestOptions)
            .then((result) => {
                if (result.status !== 200) {
                    toast.warn(result?.message);
                } else {
                    toast.success(`Intake form updated`);
                    let obj = {
                        ...UserInfo,
                        clinicalDetails: result?.data
                    }
                    localStorage.setItem("userData", JSON.stringify(obj));
                    // window.location.reload();
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsUpdating(false));

    };

    useEffect(() => {

        if (clinicalDetails) {

            let obj = {
                height: clinicalDetails?.height,
                weight: clinicalDetails?.weight,
                allergies: clinicalDetails?.allergies,
                occupation: clinicalDetails?.occupation,
                alcoholIntake: clinicalDetails?.alcoholIntake,
                tobacco: clinicalDetails?.tobacco,
                drugs: clinicalDetails?.drugs,
                illness: clinicalDetails?.illness,
                // diagnosisDate: moment(clinicalDetails?.diagnosisDate).format('YYYY-MM-DD'),
                diagnosisDate: clinicalDetails?.diagnosisDate ? moment(clinicalDetails?.diagnosisDate).format('YYYY-MM-DD') : null,
            }

            setSelectedVals(obj)

        } else setSelectedVals(defaultValues)

    }, [])


    if (!selectedVals) return <Spinner />


    return (
        <div className="bg-white rounded-lg card transform-gpu drop-shadow-card p-8  flex flex-col md:flex-row gap-8 w-full">
            {/* ----------picture div-------- */}
            <div className="pr-8 border-r border-Darkgray">
                <button
                    className="rounded-full w-24 h-24 relative flex-none"
                    type="button"

                >
                    <img
                        className="w-full rounded-full md:w-24 md:h-24 object-cover"
                        src={UserInfo?.profilePic?.original || defaultImage}
                        alt="profile"
                    />
                </button>
            </div>
            <Formik
                validationSchema={validationSchema}
                initialValues={selectedVals}
                onSubmit={handleFormSubmit}
            >
                {
                    ({ values, setFieldValue, errors }) => (
                        <Form className="flex flex-col gap-4 flex-grow">
                            {/* ---------Info div--------- */}
                            <div className="grow">
                                <div className="flex items-center gap-8">
                                    <div className="font-medium flex-none">Basic Information</div>
                                    <hr className="flex-auto text-Darkgray" />
                                </div>

                                {/* --------grid for info-------- */}
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-y-6 gap-x-8 xl:gap-x-[4rem] text-sm mt-4 ">
                                    <div className="flex flex-col gap-2 relative">
                                        <label className="font-medium">Name</label>
                                        <input type="text" value={`${UserInfo?.firstName} ${UserInfo?.lastName}`} placeholder="Full Name" className="form-input" disabled />
                                    </div>
                                    <div className="flex flex-col gap-2 relative">
                                        <label className="font-medium">Date of Birth</label>
                                        <input type="text" value={UserInfo?.dob ? moment(UserInfo?.dob).format('MMM DD, YYYY') : 'No DOB Found!'} className="form-input" disabled />

                                    </div>

                                    {/* ---age gender---- */}
                                    <div className="grid grid-cols-2 gap-2">
                                        <div className="flex flex-col gap-2 relative">
                                            <label className="font-medium">Age (years)</label>
                                            <input type="text" value={UserInfo?.dob ? moment().diff(UserInfo?.dob, 'years') : '--'} placeholder="Age ( in years )" className="form-input" disabled />
                                        </div>

                                        <div className="flex flex-col gap-2 relative">
                                            <label className="font-medium">Gender</label>
                                            <input type="text" value={UserInfo?.gender ? UserInfo?.gender : 'Not Added'} placeholder="Age ( in years )" className="form-input" disabled />
                                        </div>
                                    </div>
                                    {/* ---height weight---- */}
                                    <div className="grid grid-cols-2 gap-2">
                                        <div className="flex flex-col gap-2 relative">
                                            <label className="font-medium">Height</label>
                                            <Field name="height" type="number" placeholder="Height (in cms)" className="form-input" />
                                            <ErrorMessage component="div" name="height" className="text-xs absolute -bottom-4 font-medium mt-0.5 text-danger right-0" />
                                        </div>

                                        <div className="flex flex-col gap-2 relative">
                                            <label className="font-medium">Weight</label>
                                            <Field name="weight" type="number" placeholder="weight ( in lbs )" className="form-input" />
                                            <ErrorMessage component="div" name="weight" className="text-xs absolute -bottom-4 font-medium mt-0.5 text-danger right-0" />
                                        </div>
                                    </div>
                                    {/* ---Allergies Occupation---- */}
                                    <div className="grid grid-cols-2 gap-2">
                                        <div className="flex flex-col gap-2 relative">
                                            <label className="font-medium">Allergies</label>
                                            <Field name="allergies" type="text" placeholder="If Any" className="form-input" />
                                            <ErrorMessage component="div" name="allergies" className="text-xs absolute -bottom-4 font-medium mt-0.5 text-danger right-0" />
                                        </div>

                                        <div className="flex flex-col gap-2 relative">
                                            <label className="font-medium">Occupation</label>
                                            <Field name="occupation" type="text" placeholder="Role" className="form-input" />
                                            <ErrorMessage component="div" name="occupation" className="text-xs absolute -bottom-4 font-medium mt-0.5 text-danger right-0" />
                                        </div>
                                    </div>
                                </div>

                                {/* ------------Social section------------- */}
                                <div className="flex items-center gap-8 mt-6">
                                    <div className="font-medium flex-none">Social History</div>
                                    <hr className="flex-auto text-Darkgray" />
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 text-sm mt-4 ">
                                    <div className="flex flex-col gap-2 relative">
                                        <label className="font-medium">Alcohol Intake</label>
                                        <Field name="alcoholIntake" type="text" placeholder="per day" className="form-input" />
                                        <ErrorMessage component="div" name="alcoholIntake" className="text-xs -bottom-4 absolute font-medium mt-0.5 text-danger right-0" />
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <div className="flex flex-col gap-2 relative">
                                            <label className="font-medium">Tobacco</label>
                                            <Field as='select' name='tobacco' className='form-input'>
                                                {
                                                    confirmationOptions.map((el, i) => <option key={i} value={el.value}>{el.name}</option>)
                                                }
                                            </Field>
                                            <ErrorMessage component="div" name="tobacco" className="text-xs absolute -bottom-4 font-medium mt-0.5 text-danger right-0" />
                                        </div>

                                        <div className="flex flex-col gap-2 relative">
                                            <label className="font-medium">Drugs</label>
                                            <Field as='select' name='drugs' className='form-input'>
                                                {
                                                    confirmationOptions.map((el, i) => <option key={i} value={el.value}>{el.name}</option>)
                                                }
                                            </Field>
                                            <ErrorMessage component="div" name="drugs" className="text-xs absolute -bottom-4 font-medium mt-0.5 text-danger right-0" />
                                        </div>
                                    </div>
                                </div>

                                {/* ------------Past Illness section------------- */}
                                <div className="flex items-center gap-8 mt-6">
                                    <div className="font-medium flex-none">Past Illnesses</div>
                                    <hr className="flex-auto text-Darkgray" />
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 text-sm mt-4 ">
                                    <div className="flex flex-col gap-2 relative">
                                        <label className="font-medium">Illness</label>
                                        <Field name="illness" type="text" placeholder="Illness" className="form-input" />
                                        <ErrorMessage component="div" name="illness" className="text-xs -bottom-4 absolute font-medium mt-0.5 text-danger right-0" />
                                    </div>
                                    <div className="flex flex-col gap-2 relative">
                                        <label className="font-medium">Diagnosis Date</label>
                                        <Field name="diagnosisDate" type="date" className="form-input" max={moment().format('YYYY-MM-DD')} min={moment().subtract('100', 'years').format('YYYY-MM-DD')} />
                                        {/* {
                                            console.log("error message", ErrorMessage)
                                        } */}
                                        <ErrorMessage component="div" name="diagnosisDate" className="text-xs absolute -bottom-4 font-medium mt-0.5 text-danger right-0" />
                                    </div>
                                </div>
                            </div>

                            {/* ---------Button Container---------- */}
                            <div className="flex justify-end">

                                <button
                                    type="submit"
                                    className="btn btn-primary flex items-center gap-3"
                                    disabled={isUpdating} >

                                    {
                                        isUpdating && <Spin />
                                    }

                                    Save

                                </button>
                            </div>
                        </Form>
                    )
                }
            </Formik>

        </div>
    );
}

export default Clinical;
