import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import { AiFillCheckCircle } from 'react-icons/ai'
import { ToastContainer, toast } from "react-toastify";
import { BaseURL, BaseAPI } from "../../services/services";
import { BiLockAlt } from "react-icons/bi";

const TwoFactorAuth = () => {
  const navigate = useNavigate();

  //get data from local storage
  const user = localStorage.getItem("userData");
  const mobile = JSON.parse(user).contact.mobile;

  //get last digit of mobile number
  const lastDigit = mobile.slice(mobile.length - 3);
  

  const token = localStorage.getItem("token");
  const [code, setCode] = useState({});

  const handleDigit = (e) => {
    setCode({
      ...code,
      [e.target.id]: e.target.value,
    });
  };

  const generateOTP = () => {
    return Object.values(code).join("");
  };

  const resendOTP = () => {
    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
      "Content-Type": "application/json",
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
    };

    BaseAPI(`${BaseURL}/users/resendTwoFactorCode`, requestOptions).then(
      (result) => {
        
        if (result.status === 200) {
          toast.success("OTP Sent to your registered mobile number" , {
            icon : <AiFillCheckCircle className="text-secondary text-lg" />
          });
        } else {
          toast.warn(result.message);
        }
      }
    );
  };

  const submitAuthCode = () => {
    const fields = document.querySelectorAll(".otp-input");
    const otp = [...fields].map((field) => field.value).join("");

    if (otp.length !== 4) {
      return toast.warn("Please enter 4 digit OTP");
    }

  

    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
      "Content-Type": "application/json",
    });

    var raw = JSON.stringify({
      authCode: otp,
      uuid: localStorage.getItem("uuid"),
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

   
     BaseAPI(`${BaseURL}/users/verifyTwoFactorAuth`, requestOptions).then(
        (result) => {
          
          if (result.status === 200) {
            localStorage.setItem("Authenicated", true);
            navigate("/home/home");
          } else {
            toast.warn(result.message);
          }
        }
      );
  };

  useEffect(() => {
    const fields = document.querySelectorAll(".otp-input");

    const handleKeyDown = (e) => {
      e.preventDefault();
      const i = Number(e.currentTarget.dataset.index);
      if (e.key === "Backspace") {
        fields[i].value = "";
        if (i !== 0) fields[i - 1].focus();
      } else if (e.keyCode > 47 && e.keyCode < 58) {
        fields[i].value = e.key;
        if (i !== fields.length - 1) fields[i + 1].focus();
      } else if (e.keyCode > 64 && e.keyCode < 91) {
        fields[i].value = String.fromCharCode(e.keyCode);
        if (i !== fields.length - 1) fields[i + 1].focus();
      } else if (i === 3 && e.keyCode === 13) submitAuthCode();
    };

    for (let i = 0; i < fields.length; i += 1) {
      fields[i].addEventListener("keydown", handleKeyDown);
    }

    return () => {
      for (let i = 0; i < fields.length; i += 1) {
        fields[i].removeEventListener("keydown", handleKeyDown);
      }
    };
  }, []);

  const inputClass =
    "rounded-lg text-center bg-Darkgray w-14 px-3 py-4 mx-3 focus:outline-primary focus:bg-white otp-input";

  return (
    <>
      <div className="min-w-screen">
        <Header hide={true} />
        <div className="Background bg-gray min-h-screen flex flex-col justify-center items-center">
          <div className="w-full mx-5 flex flex-col justify-center md:w-1/3">
            <div className="text-center text-text">
              <h1 className="text-base tracking-widest">
                TWO FACTOR AUTHENTICATION
              </h1>
              <h1 className="text-xl font-bold mb-3">Enter 4 digit code</h1>
              <h1 className="text-base">
                ENTER CODE SENT TO MOBILE NUMBER XXXXXX{lastDigit}
              </h1>
            </div>
            <div className="bg-white mt-5 mx-5 rounded-2xl px-5 py-5 shadow-lg ">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  submitAuthCode();
                }}
              >
                <div className="flex justify-between">
                  <input
                    type="text"
                    maxlength="1"
                    id="1"
                    autoFocus
                    data-index="0"
                    placeholder="_"
                    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                    className={inputClass}
                    pattern="[0-9]+"
                    onChange={(e) => handleDigit(e)}
                  />
                  <input
                    type="text"
                    id="2"
                    data-index="1"
                    placeholder="_"
                    maxlength="1"
                    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                    className={inputClass}
                    pattern="[0-9]"
                    onChange={(e) => handleDigit(e)}
                  />
                  <input
                    type="text"
                    id="3"
                    maxlength="1"
                    data-index="2"
                    placeholder="_"
                    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                    className={inputClass}
                    pattern="[0-9]"
                    onChange={(e) => handleDigit(e)}
                  />
                  <input
                    type="text"
                    id="4"
                    data-index="3"
                    placeholder="_"
                    maxlength="1"
                    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                    className={inputClass}
                    pattern="[0-9]"
                    onChange={(e) => handleDigit(e)}
                  />
                </div>
                <button
                  type="submit"
                  className="bg-secondary flex justify-center items-center text-white tracking-widest font-bold w-full rounded-lg py-3 mt-3"
                >
                  Login <BiLockAlt className="ml-2"/>
                </button>
              </form>

              <h3 className="text-center mt-3 ">
                Did not recieved ?{" "}
                <span
                  className="cursor-pointer font-bold text-primary"
                  onClick={() => resendOTP()}
                >
                  {" "}
                  Send Code{" "}
                </span>{" "}
                Again
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TwoFactorAuth;
