import California from "./California";
import NewYork from "./NewYork";
import Default from "./Default";

const stateDocuments = {
    'California' : California,
    'New York' : NewYork,
    'Others' : Default
}

export { stateDocuments }