import React, { useState, useEffect, useCallback } from "react";
import { BaseAPI, BaseURL } from "../../../services/services";
import Spinner from "../../Loader/Spinner";
import { BsDot } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MedicalForm = () => {
  //state
  const [medicalData, setmedicalData] = useState({});
  const [isLoading, setLoading] = useState(false);

  //session Storage
  const user = localStorage.getItem("userData");
  const token = localStorage.getItem("token");
  const patientId = JSON.parse(user)._id;
  

  const getMedicalInfo = useCallback(() => {
    
    setLoading(true);

    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
    });

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    BaseAPI(
      `${BaseURL}/users/getUserDetails?${patientId}`,
      requestOptions
    ).then((result) => {
      
      if (result.status !== 200) {
        toast.error(result.message);
        setLoading(false);
      } else {
        setmedicalData(result.data.medicalHistory);
        setLoading(false);
      }
    }).catch(err => {console.log(err); toast.error('Something went wrong!')}).finally(()=>setLoading(false));
  }, []);

  useEffect(() => {
    getMedicalInfo();
  }, []);

  return (
    <div className="p-4 text-text">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {Object.keys(medicalData).length !== 0 && (
            <div>
              <p>
                What is/are the main medical problem(s) which you currently have
                or have had in the past ?
              </p>
              <p className="font-bold">
                {medicalData.medicalProblems.join(" , ")}
              </p>
            </div>
          )}

          {Object.keys(medicalData).length !== 0 &&
            // medicalData.medicalQuestions.map((item, i) => {
              medicalData.medicalQuestions && medicalData.medicalQuestions.filter(item => item !== null).map((item, i) => {
              return (
                <div className="my-4" key={i}>
                  <p>{item.question}</p>
                  <p className="font-bold">{item.answer}</p>
                </div>
              );
            })}
        </>
      )}

      <ToastContainer />
    </div>
  );
};

export default MedicalForm;
