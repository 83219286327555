/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import { BaseAPI, BaseURL, getAvailbleDates } from "../../services/services";
import {
  HiOutlineChevronLeft,
  HiChevronLeft,
  HiChevronRight,
  HiLocationMarker,
  HiChevronDown,
  HiChevronUp,
} from "react-icons/hi";
import { AiFillTag } from "react-icons/ai";
import Bar from "../../components/BookingInfo/Bar";
import { GoPrimitiveDot } from "react-icons/go";
import { getSpecialitiesList } from "../../services/services";
import Spinner from "../../components/Loader/Spinner";
import { toast } from "react-toastify";
import moment from "moment";
import { Box, Button, Modal, Typography } from "@mui/material";
import { BsFillStarFill } from "react-icons/bs";

// TODO: New scrollable component date selector

const DoctorProfile = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  if (!state) {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    navigate("/login");
  }

  //
  const [doctorData, setDoctorData] = useState({});
  const [allSpecialities, setAllSpecialities] = useState([]);
  const [specialities, setSpecialities] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedData, setSelectedData] = useState({
    doctorId: state.id,
    bookingDate: "",
    patientId: "string",
    bookingSlotId: "",
    specialityId:
      Object.keys(doctorData).length > 0 ? doctorData?.specialities[0] : "",
    time: "",
  });

  //loader states
  const [loading, setLoading] = useState(false);
  const [slotLoading, setSlotLoading] = useState(false);
  //state for expand collapse timeslot section
  const [expand, setExpand] = useState(false);
  const [updatedSlots, setUpdatedSlots] = useState(null);
  const [appointmentType, setAppointmentType] = useState(null);

  const [availableDates, setAvailableDates] = useState([]);
  const [ratingData, setRatingData] = useState([]);

  let divRef = useRef(null);

  const today = new Date();
  const [selectedDate, setselectedDate] = useState(
    today.toISOString().slice(0, 10)
  );

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  const [calendarDates, setCalendarDates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const token = localStorage.getItem("token");

  const btnClass =
    "transition m-2 rounded-lg border hover:bg-primary hover:text-white px-3 py-2 cursor-pointer text-sm";

  //go to previous page
  const goBack = () => {
    navigate(-1);
  };

  //month changer
  const decreaseMonth = (currentMonth) =>
    currentMonth - 1 < 0 ? 11 : currentMonth - 1;
  const increaseMonth = (currentMonth) =>
    currentMonth + 1 > 11 ? 0 : currentMonth + 1;

  //calender dates
  const getCalendarDates = (month, year) => {
    const calendarDates = [];
    const today = new Date(); // Get the current date
    const currentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()); // Extract only the date component
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const monthToUse = month < currentMonth || (month === currentMonth && year < currentYear) ? currentMonth : month;
    const yearToUse = month < currentMonth || (month === currentMonth && year < currentYear) ? currentYear : year;

    const totalDaysInCurrentMonth = new Date(yearToUse, monthToUse + 1, 0).getDate();
    const offsetDays = new Date(yearToUse, monthToUse, 0).getDay();

    const insertDate = (y, m, d, insertFromStart = false) => {
      const temp = new Date(y, m, d);
      if (temp >= currentDate) {
        calendarDates[insertFromStart ? "unshift" : "push"]({
          dateString: moment(temp).format("YYYY-MM-DD"),
          date: temp.getDate(),
          month: temp.getMonth(),
          selected: false,
        });
      }
    };

    const startDay = monthToUse === currentMonth ? currentDate.getDate() : 1;

    for (let i = startDay; i <= totalDaysInCurrentMonth; i += 1) {
      insertDate(yearToUse, monthToUse, i);
    }

    for (let i = 1; calendarDates.length < 42; i += 1) {
      insertDate(yearToUse, monthToUse + 1, i);
    }

    return calendarDates;
  };

  //function for select/unselect time slot
  const UpdateSlotsArray = (arr) => {
    let dummySlots = arr[0].availabilityTime.map((slot, i) => {
      return {
        ...slot,
        selected: false,
        id: i,
      };
    });

    setUpdatedSlots(dummySlots);
  };

  useEffect(() => {
    setSlotLoading(true);

    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
    });

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    BaseAPI(
      `${BaseURL}/users/getRatingList?doctorId=${state.id}&forAppList=true`,
      requestOptions
    )
      .then((result) => {
        setRatingData(result?.data?.allData)
      })
      .catch((err) => { })
      .finally(() => setSlotLoading(false));
  }, [state.id])

  const renderStars = (rating) => {
    return [...Array(rating)].map((_, index) => (
      <BsFillStarFill key={index} color="#f1c40f" />
    ));
  };

  const calculateDaysAgo = (createdAt) => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const differenceInTime = currentDate - createdDate;
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  };

  //get time slots
  const getTimeSlots = async (selectedDate = selectedDate) => {
    setSlotLoading(true);

    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
    });

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    console.log('-- Here selectedDAte --', selectedDate);

    BaseAPI(
      `${BaseURL}/patient/getAvailabilityTimeSlots?doctorId=${state.id}&startDateAndTime=${selectedDate}&endDateAndTime=${selectedDate}`,
      requestOptions
    )
      .then((result) => {
        if (result.data?.availableDate === "") {
          toast.warn("Doctor isn't available for this date");
          setTimeSlots([]);
        } else if (result?.data?.response[0]?.availability !== undefined) {
          setTimeSlots(result?.data?.response[0]?.availability);
          //function to set selected/unselected property to time slots
          UpdateSlotsArray(result?.data?.response[0]?.availability);
        } else {
          setTimeSlots([]);
        }
      })
      .catch((err) => { })
      .finally(() => setSlotLoading(false));
  };

  //upadate selected data
  const updateTimeSlot = (slot, i, selectedDate) => {
    console.log('-- Here data i am getting in update slot time --', slot, i, '-- date', selectedDate);
    //for selected one to be set as true
    let arr = [...updatedSlots];
    arr = arr.map((slot) => {
      if (slot.id === i) {
        slot.selected = !slot.selected;
      } else {
        slot.selected = false;
      }
      return slot;
    });
    setUpdatedSlots(arr);

    //update seleted data to be passed for booking
    setSelectedData({
      ...selectedData,
      bookingSlotId: slot._id,
      bookingDate: selectedDate.slice(0, 10), //Date Format
      time: `${slot.startTime} - ${slot.endTime}`,
    });
  };

  const getDoctorProfile = () => {
    setLoading(true);

    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
    });

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    BaseAPI(
      `${BaseURL}/patient/viewDoctorProfile?id=${state.id}`,
      requestOptions
    )
      .then((result) => {
        if (result.status === 200) {
          setDoctorData(result.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //change Date
  const changeDate = (date) => {
    //set color for selected date
    // let arr = [...calendarDates];
    // arr = arr.map((date, i) => {
    //   if (i === index) {
    //     date.selected = true;
    //   } else {
    //     date.selected = false;
    //   }
    //   return date;
    // });
    // setCalendarDates(arr);

    setselectedDate(date);
  };

  // handle date of availability of doctor

  const generateAvailability = () => {
    // const dateString = `${currentYear}-${currentMonth + 1}-01`;

    // const startOfMonth = moment(dateString)
    //   .startOf("month")
    //   .format("YYYY-MM-DD");
    // const endOfMonth = moment(dateString).endOf("month").format("YYYY-MM-DD");
    // const currentDate = new Date();
    // const currentYear = currentDate.getFullYear();
    // const currentMonth = currentDate.getMonth();

    // let dateString;
    // let startOfMonth;
    // let endOfMonth;

    // if (currentMonth === new Date().getMonth()) {
    //   // If it's the current month, set startOfMonth to the current date
    //   startOfMonth = moment(currentDate).format("YYYY-MM-DD");
    //   dateString = `${currentYear}-${currentMonth + 1}-01`;
    //   endOfMonth = moment(dateString).endOf("month").format("YYYY-MM-DD");
    // } else {
    //   // If it's not the current month, set startOfMonth to the start of the month
    //   dateString = `${currentYear}-${currentMonth + 1}-01`;
    //   startOfMonth = moment(dateString)
    //     .startOf("month")
    //     .format("YYYY-MM-DD");
    //   endOfMonth = moment(dateString).endOf("month").format("YYYY-MM-DD");
    // }


    const currentDate = new Date();

    let dateString;
    let startOfMonth;
    let endOfMonth;

    if (currentMonth === new Date().getMonth()) {
      // If it's the current month, set startOfMonth to the current date
      startOfMonth = moment(currentDate).format("YYYY-MM-DD");
      dateString = `${currentYear}-${currentMonth + 1}-01`;
      endOfMonth = moment(dateString).endOf("month").format("YYYY-MM-DD");
    } else {
      // If it's not the current month, set startOfMonth to the start of the month
      dateString = `${currentYear}-${currentMonth + 1}-01`;
      startOfMonth = moment(dateString)
        .startOf("month")
        .format("YYYY-MM-DD");
      endOfMonth = moment(dateString).endOf("month").format("YYYY-MM-DD");
    }

    getAvailbleDates(state.id, startOfMonth, endOfMonth)
      .then((res) => {
        if (res?.status === 401) {
          toast.warn(res?.message || 'Session expired. Please try again later')
          localStorage.clear();
          navigate('/login')
        }
        const availabilty = (res?.data?.response[0] && res?.data?.response[0]?.availability) || [];
        const temp =
          availabilty &&
          availabilty.map((el) => el.availabilityDate.split("T")[0]);
        setAvailableDates(temp);
      })
      .catch((error) => {
        console.log("error", error)
        if (error?.status === 401) {
          toast.warn(error?.message || 'Session expired. Please try again later')
          localStorage.clear();
        }
      })
  };

  useEffect(() => {
    getTimeSlots(selectedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!state) {
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
      navigate("/login");
    } else {
      getDoctorProfile();
      getSpecialitiesList().then((result) => {
        setAllSpecialities(result.speciality);
      });
    }
    generateAvailability();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCalendarDates(getCalendarDates(currentMonth, currentYear));
    generateAvailability();
  }, [currentMonth, currentYear]);

  useEffect(() => {
    specialityArray();
    setSelectedData({
      ...selectedData,
      specialityId: doctorData?.specialities && doctorData?.specialities[0],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorData, allSpecialities]);

  const specialityArray = () => {
    let arrayOfSpecialities = [];
    doctorData &&
      doctorData?.specialities?.forEach((element) => {
        allSpecialities.forEach((speciality) => {
          if (element === speciality._id) {
            return arrayOfSpecialities.push({
              value: speciality._id,
              name: speciality.specialityName,
              selected: false,
            });
          }
        });
      });

    return setSpecialities(arrayOfSpecialities);
  };

  const selectSpeciality = (data) => {
    let arr = [...specialities];
    arr.forEach((element) => {
      if (element.value === data.value) {
        element.selected = !element.selected;
      } else {
        element.selected = false;
      }
    });
    setSpecialities(arr);
    setSelectedData({
      ...selectedData,
      specialityId: data.value,
    });
  };

  const handleTypeSelect = (str = "video") => {
    setAppointmentType(str);

    setSelectedData({
      ...selectedData,
      aptType: str,
    });
  };

  //check Date is greater or less
  const checkDate = (date) => {
    let today = new Date().toISOString().slice(0, 10);

    today = new Date(today);
    let selectedDate = new Date(date);

    if (selectedDate.getTime() < today.getTime()) {
      return false;
    } else {
      return true;
    }
  };

  const handleReadMore = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const truncateText = (text, limit) => {
    if (text.length > limit) {
      return text.substring(0, limit) + '...';
    }
    return text;
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    maxHeight: '85%',
    bgcolor: 'background.paper',
    borderRadius: '8px',
    p: 4,
    overflowY: 'auto',
    mt: 2,
    mb: 4,
  };

  const scrollBtn = (direction) => {
    if (direction === "r") {
      document.getElementById("container").scrollLeft += 60;
    } else document.getElementById("container").scrollLeft -= 60;
  };

  function convertToMinutes(time) {
    // Split the time into components
    const [timePart, period] = time.split(/(AM|PM)/i);
    let [hours, minutes] = timePart.split(':').map(Number);

    // Convert hours based on AM/PM
    if (period.toUpperCase() === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period.toUpperCase() === 'AM' && hours === 12) {
      hours = 0;
    }

    // Convert total time to minutes
    const totalMinutes = (hours * 60) + minutes;
    return totalMinutes;
  }

  const hidePastDate = (availabilityDate, startTime) => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const currentMinute = currentDate.getMinutes();
    const currentTimeInMinutes = currentHour * 60 + currentMinute;

    // Check if availabilityDate is the current date
    const isSameDate =
      currentDate.getDate() === new Date(availabilityDate).getDate() &&
      currentDate.getMonth() === new Date(availabilityDate).getMonth() &&
      currentDate.getFullYear() === new Date(availabilityDate).getFullYear();

    if (isSameDate) {
      // If it's the current date, check if startTime is in the past
      // const [time, amPm] = startTime.split(" ");
      // const [hourString, minuteString] = time.split(":");

      // // Try to convert the hour and minute strings to numbers using Number
      // const hour = Number(hourString);
      // const minute = Number(minuteString);

      // // If either hour or minute is NaN, use parseFloat as a fallback
      // const slotHour =
      //   isNaN(hour) ? parseFloat(hourString) : hour === 12 ? (amPm === "pm" ? 12 : 0) : amPm === "pm" ? hour + 12 : hour;
      // const slotMinute = isNaN(minute) ? parseFloat(minuteString) : minute;

      const slotTimeInMinutes = convertToMinutes(startTime);

      // console.log('-- Here 0-0- --', 'slotTime', slotTimeInMinutes, 'current', currentTimeInMinutes, 'time', startTime);

      if (slotTimeInMinutes < currentTimeInMinutes) {
        // If startTime is in the past, return 'hidden'
        return 'hidden';
      }
    }

    // If it's not the current date or startTime is not in the past, return an empty string
    return '';
  };

  const url =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

  return (
    <div className="Home bg-Light-gray min-h-screem  w-full text-text">
      <Navbar heading="Home / Search Results / Doctor Profile" />
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div>
            {/* heading */}
            <div className="flex text-text m-4 items-center">
              <div
                className="bg-white rounded-lg cursor-pointer p-2 mx-3 shadow-back text-lg font-bold"
                onClick={goBack}
              >
                <HiOutlineChevronLeft />
              </div>
              <h1 className="text-lg font-semibold">Doctor Profile</h1>
            </div>

            {/* profile section */}
            <div className="bg-white rounded-lg shadow-back p-4 mx-5 text-text">
              <div className="grid grid-cols-1 md:grid-cols-2 md:gap-28 gap-10">
                <div className="flex space-x-5">
                  <div className="flex justify-center items-center h-24 w-24 rounded-full">
                    <img
                      className="rounded-full w-20 h-20  md:w-full object-cover md:h-full"
                      src={doctorData?.profilePic?.original || url}
                      alt="profileImage"
                    />
                  </div>
                  <div>
                    <h1 className="font-semibold text-lg">{`${doctorData?.firstName} ${doctorData?.lastName}`}</h1>
                    <p className="text-sm mt-2">
                      <span className="flex items-center space-x-3">
                        <span className="text-secondary text-lg">
                          {""}
                          <GoPrimitiveDot />
                        </span>
                        {specialities &&
                          specialities.map((speciality, i) => {
                            return (
                              <span
                                key={i}
                                onClick={() => selectSpeciality(speciality)}
                                className={`cursor-pointer ${speciality.selected
                                  ? "text-primary font-bold"
                                  : "text-text"
                                  }`}
                              >
                                {speciality.name}{" "}
                              </span>
                            );
                          })}
                      </span>
                      <Modal
                        open={isModalOpen}
                        onClose={handleCloseModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography id="modal-modal-title" variant="h6" component="h2">
                            Expertise
                          </Typography>
                          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            {/* {doctorData?.expertize} */}
                            {doctorData?.expertize?.map((c, index) => <div key={index}> <b>{index + 1}. </b>{c}</div>)}
                          </Typography>
                          <Button onClick={handleCloseModal} sx={{ mt: 2 }} variant="contained">
                            Close
                          </Button>
                        </Box>
                      </Modal>
                    </p>
                    <p className="flex space-x-5 text-sm">
                      <span className="text-secondary mr-3 text-lg">
                        <HiLocationMarker />
                      </span>{" "}
                      {Object.keys(doctorData).length > 0 &&
                        doctorData?.addresses[0]?.address}
                    </p>

                    {/* ---fees----- */}
                    <p className="flex space-x-5 items-center text-primary font-bold text-sm">
                      {/* <AiFillTag className="mr-3" /> Consultation Fees : $
                      {doctorData?.consultationFee}{" "} */}
                      <span className="text-lg"><AiFillTag className="mr-3" /></span> Audio/Video Fee : $
                      {doctorData?.consultationFeeAudioVideo}{" "}
                    </p>
                    <p className="flex space-x-5 items-center text-primary font-bold text-sm ml-8">
                       In-Person Fee : $
                      {doctorData?.consultationFeeInPerson}{" "}
                    </p>
                  </div>
                </div>
                {doctorData.expertize && (
                  <div className="bg-Light-Primary-400 rounded-lg p-3 w-full my-3 md:my-0">
                    <p className="font-bold">Expertise</p>
                    {/* <p className="text-sm">{truncateText(doctorData?.expertize[0], 200)}
                        {doctorData?.expertize[0].length > 100 && (
                          <span
                            className="text-blue-500 cursor-pointer ml-2"
                            onClick={handleReadMore}
                          >
                            Read More
                          </span>
                        )}
                      </p> */}

                    <p className="text-sm">
                      {truncateText(doctorData?.expertize?.map((item, index) => `${index + 1}. ${item}`).join(' '), 200)}
                      {doctorData?.expertize?.join(' ')?.length > 100 && (
                        <span
                          className="text-blue-500 cursor-pointer ml-2"
                          onClick={handleReadMore}
                        >
                          Read More
                        </span>
                      )}
                    </p>
                  </div>
                )}
              </div>
              {/* bio */}
              <div className="mt-5">
                <h5> About :</h5>
                <p className="text-sm">
                  {Object.keys(doctorData).length > 0 && doctorData?.bio}
                </p>
              </div>
            </div>

            <div className="mx-4 mt-4 bg-white rounded-lg shadow-back p-4 text-sm">
              <div className="font-bold text-lg pb-3">Reviews</div>
              {ratingData.length === 0 ?
                <div className="text-center text-base font-semibold" style={{ color: 'gray' }}>No reviews available</div>
                :
                <div className={`flex flex-col ${ratingData.length > 5 ? 'h-64 overflow-y-auto' : ''}`}>
                  {ratingData.length > 0 && ratingData.map((c) => (
                    <div className="flex gap-4 pb-3">
                      <div>
                        <div><img src={c?.patientId?.profilePic?.original || url} alt="profile" className="w-10 h-10 rounded-full object-cover" /></div>
                      </div>
                      <div className="w-full">
                        <div className="flex justify-between"><div className="font-bold">{c?.patientId?.firstName} {c?.patientId?.lastName}</div> <div style={{ color: 'gray' }}>{calculateDaysAgo(c.createdAt) === 0 ? 'Today' : calculateDaysAgo(c.createdAt) === 1 ? ' 1 day ago' :  `${calculateDaysAgo(c.createdAt)} days ago`}</div></div>
                        <div style={{ color: 'gray' }}>{c?.review}</div>
                        <div className="flex gap-4">
                          <div className="flex gap-4 items-center">
                            <div className="font-semibold">Overall</div>
                            <div className="flex w-20">{renderStars(c.overallRatings)}</div>
                          </div>
                          <div className="flex gap-4 items-center">
                            <div className="font-semibold">Waiting Time</div>
                            <div className="flex w-20">{renderStars(c.waitingTimeRatings)}</div>
                          </div>
                          <div className="flex gap-4 items-center">
                            <div className="font-semibold">Behavior</div>
                            <div className="flex w-20">{renderStars(c.behaviorRatings)}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              }
            </div>

            {/* appointment listing */}
            <div className="mx-4 mt-4 bg-white rounded-lg shadow-back p-4 flex flex-col md:flex-row md:justify-between ">
              <div className="text-text  md:w-1/3 w-[32rem] flex flex-col gap-4">
                <div>
                  <div className=" border-b">
                    <h1 className="text-lg py-4">
                      Select Date For Appointment
                    </h1>
                  </div>
                  <div className="flex space-x-4 mt-2">
                    <div className="flex mt-3 space-x-3 items-center">
                      <button
                        onClick={() => {
                          if (currentYear >= new Date().getFullYear()) {
                            if (
                              currentYear === new Date().getFullYear() &&
                              currentMonth - 1 < new Date().getMonth()
                            ) {
                              console.log("not allowed");
                            } else setCurrentMonth(decreaseMonth(currentMonth));
                          }
                        }}
                      >
                        {" "}
                        <HiChevronLeft className="cursor-pointer" />{" "}
                      </button>
                      <span className="font-bold">{months[currentMonth]}</span>
                      <button
                        onClick={() =>
                          setCurrentMonth(increaseMonth(currentMonth))
                        }
                      >
                        {" "}
                        <HiChevronRight className="cursor-pointer" />{" "}
                      </button>
                    </div>
                    {/* for year change */}
                    <div className="flex mt-3 space-x-3 items-center">
                      <span
                        onClick={() => {
                          if (currentYear > new Date().getFullYear())
                            setCurrentYear((prev) => prev - 1);
                        }}
                      >
                        {" "}
                        <HiChevronLeft className="cursor-pointer" />{" "}
                      </span>
                      <span className="font-bold">{currentYear}</span>
                      <span onClick={() => setCurrentYear((prev) => prev + 1)}>
                        {" "}
                        <HiChevronRight className="cursor-pointer" />{" "}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <span
                      className=" text-lg font-bold cursor-pointer"
                      onClick={() => scrollBtn("l")}
                    >
                      <HiChevronLeft />
                    </span>
                    <div
                      className="w-full flex items-center gap-1 overflow-x-scroll py-5 mt-2 custom-scroll"
                      id="container"
                    >
                      {/*  eslint-disable-next-line array-callback-return */}
                      {calendarDates.map((date, i) => {
                        const isAvailable = availableDates.includes(
                          date.dateString
                        );
                        if (date.month === currentMonth) {
                          return (
                            <button
                              key={date.dateString}
                              disabled={!isAvailable}
                              onClick={() => {
                                getTimeSlots(date?.dateString);
                                setselectedDate(date?.dateString);
                              }}
                              className={`${isAvailable
                                ? "border-text"
                                : "bg-gray border-none cursor-not-allowed"
                                } text-text w-42 transition md:text-lg md:font-bold  font-bold border rounded-lg p-3 mx-2 ${selectedDate === date?.dateString
                                  ? "bg-primary text-white hover:bg-primary text-white border-primary"
                                  : "bg-white hover:bg-Light-Primary-400"
                                }  `}
                            >
                              {date.date}
                              <p className="text-sm font-normal mt-4 text-center">
                                {moment(date.dateString).format("ddd")}
                              </p>
                            </button>
                          );
                        }
                      })}
                    </div>
                    <span
                      className=" text-lg font-bold cursor-pointer"
                      onClick={() => scrollBtn("r")}
                    >
                      <HiChevronRight />
                    </span>
                  </div>
                </div>

                {/* ---type selector--- */}
                <div>
                  <h2 className="text-lg py-4">Select Appointment Type</h2>
                  <div className="flex flex-wrap gap-5 items-center justify-start my-3">
                    {/* --input fields--- */}
                    <div className="flex gap-2 items-center">
                      <input
                        type="radio"
                        name="appointmentType"
                        value="video"
                        onChange={() => handleTypeSelect("video")}
                      />
                      <label for="appointmentType" className="font-semibold">
                        Video
                      </label>
                    </div>
                    <div className="flex gap-2 items-center">
                      <input
                        type="radio"
                        name="appointmentType"
                        value="audio"
                        onChange={() => handleTypeSelect("audio")}
                      />
                      <label for="appointmentType" className="font-semibold">
                        Audio
                      </label>
                    </div>
                    <div className="flex gap-2 items-center">
                      <input
                        type="radio"
                        name="appointmentType"
                        value="inPerson"
                        onChange={() => handleTypeSelect("inPerson")}
                      />
                      <label for="appointmentType" className="font-semibold">
                        In Person
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* appointment time */}
              <div className="text-text md:w-1/2 md:mr-4 ">
                <div>
                  <h1 className="text-lg text-text border-b py-4 mb-2">
                    Select Slots for Appointment <span style={{ color: '#666861' }}>({doctorData?.timeZoneName})</span>
                  </h1>
                </div>
                <div
                  className={`mt-4 w-100%  h-auto ${expand ? "md:h-auto" : " md:h-56"
                    } overflow-scroll`}
                >
                  <h2 className="font-bold">
                    {moment(selectedDate).format("MMMM DD , YYYY")}
                    <span className="text-sm  ml-4 font-normal">{`( ${timeSlots[0]?.availabilityTime?.length || "no"
                      } slots )`}</span>
                  </h2>

                  {/* Laoding text */}
                  {slotLoading && (
                    <h2 className=" text-grey-400 text-lg mt-2 text-center font-bold">
                      Loading ...
                    </h2>
                  )}

                  {/* ----slots-------- */}
                  {!slotLoading && timeSlots.length > 0
                    ? timeSlots.map((item, i) => {
                      const currentDate = new Date().toISOString().split('T')[0];
                      // eslint-disable-next-line no-lone-blocks
                      // { console.log('here currentdate', item.availabilityDate) }
                      return (
                        <div key={i}>
                          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-1">
                            {updatedSlots &&
                              updatedSlots.map((slot, i) => {
                                return (
                                  <span
                                    onClick={() => {
                                      updateTimeSlot(
                                        slot,
                                        i,
                                        item.availabilityDate
                                      );
                                      divRef.current.scrollIntoView({
                                        behavior: "smooth",
                                      });
                                    }}
                                    className={`${btnClass} ${hidePastDate(item.availabilityDate, slot.startTime)} ${slot.selected &&
                                      "bg-primary text-white text-sm whitespace-nowrap"
                                      }  `}
                                    key={slot?._id}
                                  >
                                    {`${slot.startTime} - ${slot.endTime}`}
                                  </span>
                                );
                              })}
                          </div>
                        </div>
                      );
                    })
                    : !slotLoading && (
                      <h1 className="text-center text-lg font-bold mt-4">
                        No Slots Available
                      </h1>
                    )}

                </div>
                <p className="flex items-center justify-center text-lg font-bold">
                  {expand ? (
                    <HiChevronUp
                      className="cursor-pointer"
                      onClick={() => setExpand(false)}
                    />
                  ) : (
                    <HiChevronDown
                      className="cursor-pointer"
                      onClick={() => setExpand(true)}
                    />
                  )}
                </p>
              </div>
            </div>
          </div>
          <div ref={divRef} className="my-2">
            <Bar
              doctorId={state.id}
              profile={{ ...doctorData, specialities: allSpecialities }}
              data={selectedData}
              fee={doctorData?.consultationFee}
              consultationFeeInPerson={doctorData?.consultationFeeInPerson}
              consultationFeeAudioVideo={doctorData?.consultationFeeAudioVideo}
              doctorData={doctorData}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DoctorProfile;
