/* eslint-disable no-useless-escape */
import React, { useState, useContext, useEffect } from "react";
import { Country, State, City } from 'country-state-city';
import Navbar from "../../components/Navbar/Navbar";
import MedicalForm from "../../components/Forms/Medical/MedicalForm";
import { UserDetailsContext } from "../../services/Context/UserContext";
import { AiOutlineEye, AiOutlineEyeInvisible, AiFillCheckCircle } from "react-icons/ai";
import { BsPenFill } from "react-icons/bs";
import { MdAddAPhoto } from "react-icons/md";
import { BaseAPI, BaseURL } from "../../services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import { ImageUpload } from "../../services/services";
import Autocomplete from "../../components/PlacesAutocomplete/Autocomplete";
import { BsFillLockFill, BsDot } from "react-icons/bs";
import Sign from "../../components/Signature Pad";
import { Countries, States } from "../../services/Constants";
import * as Yup from "yup";
import { Form, Formik, Field, ErrorMessage } from "formik";
import Spin from "../../components/Loader/Spin";

const Profile = () => {
  //states
  const [switchForm, setswitchForm] = useState("Personal");
  const { loginDetails } = useContext(UserDetailsContext);



  //classes
  const btnClasss =
    "bg-white p-3 rounded-t-lg mr-4 drop-shadow-tab shadow-tab border-b border-white text-text font-bold";
  const deactiveClass = "bg-gray p-3 rounded-t-lg mr-4 text-text  ";

  return (
    <div className="Home bg-Light-gray min-h-screen w-full">
      <Navbar heading="Profile" />
      <div className="mt-5 mx-5">
        <span>
          <button
            className={switchForm === "Personal" ? btnClasss : deactiveClass}
            onClick={() => {
              setswitchForm("Personal");
            }}
          >
            Personal Info
          </button>
          <button
            className={switchForm === "Password" ? btnClasss : deactiveClass}
            onClick={() => {
              setswitchForm("Password");
            }}
          >
            Change Password
          </button>
          <button
            className={switchForm === "Medical" ? btnClasss : deactiveClass}
            onClick={() => {
              setswitchForm("Medical");
            }}
          >
            My Medical History
          </button>
        </span>
        <div className="bg-white  p-4 shadow-tab rounded-lg">
          {switchForm === "Personal" ? (
            <ViewProfile />
          ) : switchForm === "Password" ? (
            <ChangePassword />
          ) : (
            <MedicalForm />
          )}
        </div>
      </div>
    </div>
  );
};

function ViewProfile() {
  //localStorage
  const user = localStorage.getItem("userData");
  const token = localStorage.getItem("token");
  const UserInfo = JSON.parse(user);

  const [profilePic, setprofilePic] = useState({
    url: UserInfo.profilePic.original,
    thumbUrl: UserInfo.profilePic.thumbnail,
  });

  const phoneNumber =
    UserInfo?.contact?.countryCode + UserInfo?.contact?.mobile;
  //  const {country} = parsePhoneNumber(phoneNumber)

  const [phone, setphone] = useState(phoneNumber);

  //  console.warn( parsePhoneNumber(phone).nationalNumber)

  const [profileInfo, setprofileInfo] = useState({
    id: UserInfo._id,
  });
  const [lenght, setLength] = useState();
  const [validations, setValidations] = useState();
  const [open, setOpen] = useState(false);
  const [signature, setSignature] = useState(
    UserInfo?.eSignature ? UserInfo?.eSignature : ""
  );
  const [loading, setloading] = useState(false);

  const timezonesList = [
    { name: 'Eastern Standard Time', value: '1' },
    { name: 'Central Standard Time', value: '2' },
    { name: 'Pacific Standard Time', value: '3' },
    { name: 'Mountain Standard Time', value: '4' },
    { name: 'Hawaii Standard Time', value: '5' },
    { name: 'Alaska Standard Time', value: '6' },
    { name: 'Indian Standard Time', value: '7' },
    { name: 'South African Standard time', value: '8' },
  ];

  const updateInput = (e) => {
    let id = e.target.name;
    // setprofileInfo({
    //   ...profileInfo,
    //   [id]: e.target.value,
    // });
    if (id === 'timeZone') {
      const lat = e.target.value === '1' ? 25.76905 : e.target.value === '2' ? 41.8860775 : e.target.value === '3' ? 33.7985879 : e.target.value === '4' ? 51.0219537 : e.target.value === '7' ? 30.7364136 : e.target.value === '8' ? -29.1181113 : 25.76905
      const long = e.target.value === '1' ? -80.132234 : e.target.value === '2' ? -87.6379001 : e.target.value === '3' ? -117.9267073 : e.target.value === '4' ? -114.0265503 : e.target.value === '7' ? 76.6583503 : e.target.value === '8' ? 25.6322328 : -80.132234;

      const selectedTimezone = timezonesList.find(tz => tz.value === e.target.value)?.name;
      setprofileInfo({
        ...profileInfo,
        [id]: e.target.value,
        longitude: long,
        latitude: lat,
        timeZoneName: selectedTimezone,
      });
    } else {
      setprofileInfo({
        ...profileInfo,
        [id]: e.target.value,
      });
    }
  };



  const checkPhoneChange = () => {
    if (phone === phoneNumber) {
      return profileInfo;
    } else {
      return {
        ...profileInfo,
        countryCode: "+" + parsePhoneNumber(phone).countryCallingCode,
        mobile: parsePhoneNumber(phone).nationalNumber,
      };
    }
  };

  const updateProfile = (e) => {
    setloading(true);
    e.preventDefault();

    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
      "Content-Type": "application/json",
    });

    //if phone number is changed , update the api body

    var raw = JSON.stringify(checkPhoneChange());



    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    BaseAPI(`${BaseURL}/users/updateProfile`, requestOptions)
      .then((result) => {

        if (result.status !== 200) {
          toast.warn(result.message);
        } else {
          toast.success("Profile Updated Successfully", {
            icon: <AiFillCheckCircle className="text-secondary text-lg" />
          });
          localStorage.setItem("userData", JSON.stringify(result.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setloading(false));
  };

  //Handle image upload
  const UploadProfilePic = async (e) => {
    ImageUpload(e).then((result) => {

      setprofilePic(result.data);
      if (result.status === 200) {
        setprofileInfo({
          ...profileInfo,
          profilePic: {
            original: result.data.url,
            thumbnail: result.data.thumbUrl,
          },
        });
      }
    });
  };

  useEffect(() => {

    if (phone && (phone.length > 13 || phone.length < 10)) {
      setValidations({
        ...validations,
        phone: "Invalid Phone Number",
      });
    } else {
      setValidations({
        ...validations,
        phone: "",
      });
    }
  }, [phone]);

  useEffect(() => {

    setprofileInfo({ ...profileInfo, eSignature: signature?.url });
  }, [signature]);

  const inputClass =
    "bg-white outline-primary px-4 py-2 rounded focus:outline-primary focus:bg-white";
  //default image
  const defaultImage =
    "https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-image-182145777.jpg";

  //TODO: Redeisgn profile section like doctor panel ( section wise )

  return (
    <>
      <div>
        <div className="  my-5 flex justify-start items-center">
          <img
            className="h-20 w-20 rounded-full  my-5"
            src={profilePic?.url || defaultImage}
            alt="user"
          />
          <div className="relative mx-2">
            <input
              type="file"
              accept="image/png, image/jpeg , image/jpg"
              className="Upload-class h-20 w-20 rounded-full absolute  "
              onChange={UploadProfilePic}
            />
            <span className=" h-10 w-10 b-2 text-text font-bold rounded-full cursor-pointer    flex justify-center items-center">
              <MdAddAPhoto />{" "}
            </span>
          </div>
        </div>
        <div className="grid gap-5 md:gap-x-12 md:grid-cols-3 text-text">
          <div className="flex flex-col">
            <label className="text-sm font-bold">First Name</label>
            <input
              className={inputClass}
              name="firstName"
              onChange={updateInput}
              defaultValue={UserInfo.firstName}
              type="text"
              placeholder="First Name"
            />
          </div>
          <div className="flex flex-col">
            <label className="text-sm font-bold">Middle Name</label>
            <input
              className={`${inputClass} ${UserInfo.middleName == "" ? "bg-gray" : ""
                }`}
              name="middleName"
              defaultValue={UserInfo.middleName}
              onChange={updateInput}
              type="text"
              placeholder="Middle Name"
            />
          </div>
          <div className="flex flex-col">
            <label className="text-sm font-bold">Last Name</label>
            <input
              className={inputClass}
              type="text"
              onChange={updateInput}
              name="lastName"
              defaultValue={UserInfo.lastName}
              placeholder="Last Name"
            />
          </div>
          <div className="flex flex-col">
            <label className="text-sm font-bold">Email Address</label>
            <input
              className={inputClass}
              name="email"
              onChange={updateInput}
              defaultValue={UserInfo.email}
              type="text"
              placeholder="Email"
            />
          </div>
          <div className="flex flex-col">
            <label className="text-sm font-bold">Phone Number</label>
            <PhoneInput
              placeholder="Enter phone number"
              id="Phone Number"
              defaultCountry="US"
              className={inputClass}
              value={phone}
              onChange={setphone}
            />
            <p className="text-sm text-danger">
              {validations && validations?.phone}
            </p>
          </div>
        </div>
        <div className="text-text text-lg mb-4 mt-8 flex items-center gap-4">
          <p>Address</p>
          <hr className="text-text opacity-20 w-full border" />
        </div>
        <div className="grid gap-5 md:gap-x-12 md:grid-cols-3 text-text">
          {/* <div className="flex flex-col">
            <div className="relative">
            <label className="text-sm font-bold">Address</label>
            <Autocomplete
              name={"Profile"}
              personalInfo={profileInfo}
              setpersonalInfo={setprofileInfo}
              Length={lenght}
              setLength={setLength}
              defaultValue={UserInfo.addresses[0].address}
            />
            </div>
          </div> */}

          <div className="flex flex-col">
            <label className="text-sm font-bold">Address</label>
            <input
              className={inputClass}
              name="address"
              onChange={updateInput}
              defaultValue={UserInfo.addresses[0].address}
              value={profileInfo?.address}
              type="text"
              placeholder="Address"
            />
          </div>

          <div className="flex flex-col">
            <label className="text-sm font-bold">State</label>
            <div className="text-sm font-bold">
              <select
                name="country"
                id="country"
                defaultValue={UserInfo.addresses[0].country}
                value={profileInfo?.country}
                onChange={updateInput}
                className={`${inputClass} w-full text-base`}
              >
                {Country.getAllCountries().map((country) => (
                  <option key={country.isoCode} value={country.isoCode}>{country.name}</option>
                ))}
              </select>
            </div>
          </div>

          {/* <div className="flex flex-col">
            <label className="text-sm font-bold">City</label>
            <input
              className={inputClass}
              name="city"
              onChange={updateInput}
              defaultValue={UserInfo.addresses[0].city}
              value={profileInfo?.city}
              type="text"
              placeholder="City"
            />
          </div> */}

          {/* <div className="flex flex-col">
            <label className="text-sm font-bold">State</label>
            <input
              className={inputClass}
              list="states"
              name="state"
              onChange={updateInput}
              defaultValue={UserInfo.addresses[0].state}
              type="text"
              value={profileInfo?.state}
              placeholder="State"
            />
            {States()}
          </div> */}

          <div className="flex flex-col">
            <label className="text-sm font-bold">State</label>
            <div className="text-sm font-bold">
              <select
                name="state"
                id="state"
                defaultValue={UserInfo.addresses[0].state}
                value={profileInfo?.state}
                onChange={updateInput}
                className={`${inputClass} w-full text-base`}
              >
                {(profileInfo?.country ? profileInfo?.country : UserInfo.addresses[0].country) && State.getStatesOfCountry((profileInfo?.country ? profileInfo?.country : UserInfo.addresses[0].country)).map((state) => (
                  <option key={state.isoCode} value={state.name}>{state.name}</option>
                ))}
              </select>
            </div>
          </div>

          {/* <div className="flex flex-col">
            <label className="text-sm font-bold">Country</label>
            <input
              className={inputClass}
              list="countries"
              name="country"
              onChange={updateInput}
              defaultValue={UserInfo.addresses[0].country}
              type="text"
              value={profileInfo?.country}
              placeholder="Country"
            />
            {Countries()}
          </div> */}

          <div className="flex flex-col">
            <label className="text-sm font-bold">City</label>
            <input
              className={inputClass}
              name="city"
              onChange={updateInput}
              defaultValue={UserInfo.addresses[0].city}
              value={profileInfo?.city}
              type="text"
              placeholder="City"
            />
          </div>

          <div className="flex flex-col">
            <label className="text-sm font-bold">Zip Code</label>
            <input
              className={inputClass}
              name="zipCode"
              onChange={updateInput}
              defaultValue={UserInfo.addresses[0].zipCode}
              type="text"
              value={profileInfo?.zipCode}
              placeholder="Zip Code"
            />
          </div>

          <div className="flex flex-col">
            <label className="text-sm font-bold">Time Zone</label>
            <div className="text-sm font-bold">
              <select
                name="timeZone"
                id="Timezone"
                defaultValue={UserInfo.timeZone}
                value={profileInfo?.timeZone}
                onChange={updateInput}
                className={`${inputClass} w-full text-base`}
              >
                <option value="none" selected disabled hidden>
                  Select Timezone
                </option>
                <option value="3">Pacific Time Zone (PST)</option>
                <option value="1">Eastern Standard Time (EST)</option>
                <option value="2">Central Standard Time (CST)</option>
                <option value="4">Mountain Standard Time (MST)</option>
                <option value="5">Hawaii–Aleutian Standard Time (HST)</option>
                <option value="6">Alaska Standard Time (AKST)</option>
                <option value="7">Indian Standard Time (IST)</option>
                <option value="8">South African Standard Time (SAST)</option>
              </select>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end items-center">
          <div>
            {signature ? <img
              src={signature?.url || signature}
              className="h-28 w-28"
              alt="signature"
            /> : '' }
            <Sign open={open} close={setOpen} setEsign={setSignature} />
            <span
              onClick={() => setOpen(true)}
              // className="text-primary font-bold flex items-center cursor-pointer"
              className={`text-primary font-bold flex items-center cursor-pointer ${signature ? '' : 'pt-5'}`}
            >
              {/* <BsPenFill className="mx-3" /> Update Signature */}
              <BsPenFill className="mx-3" /> {signature ? 'Update Signature' : 'Add Signature'}
            </span>
          </div>
        </div>
        <div className="flex justify-end mt-5">
          <button
            onClick={() => window.location.reload()}
            className="bg-white border mx-4 text-primary px-3 py-2 rounded tracking-widest"
          >
            RESET
          </button>
          <button
            onClick={updateProfile}
            className={`bg-primary text-white px-3 py-2 rounded tracking-widest flex items-center ${loading && "cursor-not-allowed opacity-80"
              }`}
          >
            {loading && <Spin className="mr-3" />} SUBMIT
          </button>
        </div>
      </div>
    </>
  );
}

function ChangePassword() {
  const inputClass =
    "bg-gray w-full px-4 py-2 rounded focus:outline-primary focus:bg-white";
  const token = localStorage.getItem("token");

  const [hidePassword, sethidePassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });

  const [loading, setloading] = useState(false);

  const ClickHandler = (target) => {
    switch (target) {
      case "old":
        sethidePassword({
          ...hidePassword,
          oldPassword: !hidePassword.oldPassword,
        });
        break;

      case "new":
        sethidePassword({
          ...hidePassword,
          newPassword: !hidePassword.newPassword,
        });
        break;

      case "confirm":
        sethidePassword({
          ...hidePassword,
          confirmNewPassword: !hidePassword.confirmNewPassword,
        });
        break;

      default:
        break;
    }
  };

  //schema

  const validationSchema = Yup.object({
    oldPassword: Yup.string().required("Old password is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password must be strong"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const ChangePassword = (values) => {
    setloading(true);


    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
      "Content-Type": "application/json",
    });

    var raw = JSON.stringify({
      ...values,
    });



    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    BaseAPI(`${BaseURL}/users/changePassword`, requestOptions)
      .then((result) => {

        if (result.status !== 200) {
          toast.warn(result.message);
        } else {
          toast.success("Password Changed Successfully", {
            icon: <AiFillCheckCircle className="text-secondary text-lg" />
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setloading(false));
  };

  return (
    <>
      <Formik
        initialValues={{ oldPassword: "", password: "", confirmPassword: "" }}
        validationSchema={validationSchema}
        onSubmit={ChangePassword}
      >
        {({ errors, touched, handleReset }) => (
          <Form>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 text-text">
              <div className="space-y-4">
                <div className="flex flex-col ">
                  <label className="text-sm font-bold">Old Password</label>
                  <div className="relative">
                    <Field
                      className={inputClass}
                      name="oldPassword"
                      type={`${hidePassword.oldPassword ? "text" : "password"}`}
                      placeholder="Enter Old Password"
                    />
                    {!hidePassword.oldPassword ? (
                      <AiOutlineEye
                        className="absolute right-2 text-lg bottom-2 text-text"
                        onClick={() => {
                          ClickHandler("old");
                        }}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        className="absolute right-2 text-lg bottom-2 text-text"
                        onClick={() => {
                          ClickHandler("old");
                        }}
                      />
                    )}
                  </div>
                  <ErrorMessage
                    component="p"
                    name="oldPassword"
                    className="text-sm text-danger text-right"
                  />
                </div>
                <div className="flex flex-col ">
                  <label className="text-sm font-bold">New Password</label>
                  <div className="relative">
                    <Field
                      className={inputClass}
                      name="password"
                      type={`${hidePassword.newPassword ? "text" : "password"}`}
                      placeholder="Enter New Password"
                    />

                    {!hidePassword.newPassword ? (
                      <AiOutlineEye
                        className="absolute right-2 text-lg bottom-2 text-text"
                        onClick={() => {
                          ClickHandler("new");
                        }}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        className="absolute right-2 text-lg bottom-2 text-text"
                        onClick={() => {
                          ClickHandler("new");
                        }}
                      />
                    )}
                  </div>
                  <ErrorMessage
                    component="p"
                    name="password"
                    className="text-sm text-danger text-right"
                  />
                </div>
                <div className="flex flex-col ">
                  <label className="text-sm font-bold">
                    Confirm New Password
                  </label>
                  <div className="relative">
                    <Field
                      className={inputClass}
                      type={`${hidePassword.confirmNewPassword ? "text" : "password"
                        }`}
                      name="confirmPassword"
                      placeholder="Confirm New Password"
                    />

                    {!hidePassword.confirmNewPassword ? (
                      <AiOutlineEye
                        className="absolute right-2 text-lg bottom-2 text-text"
                        onClick={() => {
                          ClickHandler("confirm");
                        }}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        className="absolute right-2 text-lg bottom-2 text-text"
                        onClick={() => {
                          ClickHandler("confirm");
                        }}
                      />
                    )}
                  </div>
                  <ErrorMessage
                    component="p"
                    name="confirmPassword"
                    className="text-sm text-danger text-right"
                  />
                </div>
              </div>
              <div className="text-sm font-bold bg-gray rounded-lg p-4">
                <h2 className="text-text flex items-center">
                  <BsFillLockFill className="mx-3" /> Your password needs to
                  :{" "}
                </h2>
                <ul className="text-primary pl-4">
                  <li className="my-2 flex items-center">
                    {" "}
                    <BsDot className="mx-3" /> be at least 8 characters long
                  </li>
                  <li className="my-2 flex items-center">
                    <BsDot className="mx-3" /> include both lower and upper case characters
                  </li>
                  <li className="my-2 flex items-center">
                    <BsDot className="mx-3" /> include at least one number and symbol
                    character
                  </li>
                  <li className="my-2 flex items-center">
                    <BsDot className="mx-3" /> be different from your previous passwords{" "}
                  </li>
                </ul>
              </div>
            </div>

            <div className="flex justify-end space-x-5 mt-5">
              <button
                type="button"
                onClick={handleReset}
                className="px-6 py-2 border text-primary rounded"
              >
                Reset
              </button>
              <button
                type="submit"
                className={`px-3 py-2 bg-primary text-white rounded flex items-center ${loading && "cursor-not-allowed opacity-80"
                  }`}
              >
                {loading && <Spin className="mr-2" />} Change Password
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default Profile;
