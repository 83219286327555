import React from 'react'
import Navbar from '../../components/Navbar/Navbar'
import VideoChat from '../../components/VideoChat'


const Session = () => {
  return (
    <div className='Home bg-Light-gray min-h-screen w-full'>
        <Navbar heading='Session'/>
        <div className='mt-5 mx-5'>
            <VideoChat/>
        </div>
    </div>
  )
}

export default Session