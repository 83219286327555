import React, { useState, useEffect, useMemo, useRef } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Table from "../../components/Table/Table";
import { BaseAPI, BaseURL, getSpecialitiesList } from "../../services/services";
import {
  checkStatus,
  paymentStatus,
  timeConvert,
  defaultUserImage
} from "../../services/Constants";
import moment from 'moment'
import Spinner from "../../components/Loader/Spinner";
import {
  AiOutlineEye,
  AiOutlineCloseCircle,
  AiOutlineDownload,
  AiOutlineSearch,
} from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import { HiOutlineX , HiOutlineVideoCamera, HiPhone, HiUser, HiOutlinePhone, HiOutlineUser } from "react-icons/hi";
import Rodal from "rodal";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { BsClipboardPlus, BsFillStarFill, BsStar } from 'react-icons/bs'
import { BsCreditCard } from 'react-icons/bs'

// include styles
import "rodal/lib/rodal.css";

const validationSchema = Yup.object({
  reason: Yup.string("")
    .min(3, "Add descriptive reason")
    .required("Reason is required"),
});

const Appointment = () => {
  const [bookingData, setbookingData] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [confirmationBox, setconfirmationBox] = useState(false);
  const [idToDelete, setidToDelete] = useState("");
  const [searchText, setsearchText] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  //refs
  let searchRef = useRef("");
  let statusRef = useRef("");

  //declaration
  const navigate = useNavigate();

  //session storage
  const user = localStorage.getItem("userData");
  const token = localStorage.getItem("token");
  const patientId = JSON.parse(user)._id;


  const statusOptions = [
    {
      value: "",
      label: "All",
    },
    {
      value: "1",
      label: "Pending",
    },
    {
      value: "2",
      // label: "Confirmed",
      label: "Scheduled",
    },
    {
      value: "7",
      label: "Cancelled",
    },
    {
      value: "4",
      label: "Completed",
    },
    {
      value: "6",
      label: "Expired",
    },
    {
      value: "8",
      label: "No Show",
    },
    {
      value: "5",
      label: "Rescheduled",
    },
    {
      value: "9",
      label: "Session Completed",
    },
  ];

  //handle cancellation submit
  const handleSubmit = (values) => {
    
    cancelBooking(idToDelete, values.reason);
  };

  //handle search text

  const HandleSearch = (e) => {
    setsearchText(e.target.value);
  };

  //getting booking list   searchDoctor
  const getBookingList = () => {
    setisLoading(true);

    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
      "Content-Type": "application/json",
    });

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    BaseAPI(
      `${BaseURL}/booking/getBookingsList?patientId=${patientId}&searchDoctor=${searchText}&bookingStatus=${searchStatus}&limit=100`,
      requestOptions
    )
      .then((result) => {
        if (result.status !== 200) {
          toast.error(result.message);
          setTimeout(() => {
            navigate("/");
          }, 3000);
        } else {
          
          setbookingData(result.data);
        }
        setisLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      })
      .finally(() => setisLoading(false));
  };

  //getBooking Details
  const viewAppointmentDetails = (id) => {
    

    navigate("/home/appointment/view", { state: { id: id } });
  };

  const handleRating = (id) => {
    navigate("/home/appointment/rating", { state: { id: id } });
  };

  useEffect(() => {
    getBookingList();
  }, [searchText, searchStatus]);

  const clearFilter = () => {
    setSearchStatus("");
    setsearchText("");
    
    searchRef.current.value = null;
    statusRef.current.value = "";
  };

  //dummy data
  const data = useMemo(() => bookingData?.bookings, [bookingData]);

  //cancel booking
  const cancelBooking = (id, reason) => {
    

    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
      "Content-Type": "application/json",
    });

    var raw = JSON.stringify({
      bookingId: id,
      reason: reason,
    });

    

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    BaseAPI(`${BaseURL}/booking/cancelAppointment`, requestOptions).then(
      (result) => {
        
        setconfirmationBox(false);
        if (result.status === 200) {
          toast.success("Appointment cancelled successfully");
          getBookingList();
        } else toast.error(result.message);
      }
    );
  };

  const startSession = (id) => {
    navigate(`/home/session?id=${id}`);
  };

  const checkSessionValid = (date)=>{
    let bookingDate = moment(date).format('MMMM DD , YYYY')
    let todayDate = moment(new Date()).format('MMMM DD , YYYY')
    return bookingDate === todayDate
  }

  const [prescriptions, setPrescriptions] = useState([]);

  useEffect(async () => {

    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
      "Content-Type": "application/json",
    });

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    BaseAPI(`${BaseURL}/doctor/getPdfList?patientId=${patientId}&pdfType=1`, requestOptions)
      .then((result) => {
        if (result.data) {
          setPrescriptions(result.data);
        }
      })
  }, [patientId]);

  const showPrescription = (prescriptions, appointments, appointmentId) => {
    const matchingPrescriptions = Object.keys(prescriptions).length > 0 && prescriptions?.filter(prescriptionItem => {
      const matchingAppointment = appointments?.find(appointment => appointment?._id === prescriptionItem?.appointmentId);
      return matchingAppointment && matchingAppointment?._id === appointmentId;
    });

    // If there are matching prescriptions, return the icon
    if (matchingPrescriptions?.length > 0) {
      return (
        <span className="text-light-600 cursor-pointer" onClick={() => { handleViewPrescriptionPdf(matchingPrescriptions[0]?.pdfDocumentId) }}>
          <BsClipboardPlus className="w-4 h-4" />
        </span>
      );
    }

    return null; // If no matching prescription is found, return null
  };

  const handleViewPrescriptionPdf = (pdfDocumentId) => {
    console.log(pdfDocumentId)
    getPdf(pdfDocumentId)
      .then((res) =>
        res.arrayBuffer()
      )
      .then(res => {
        console.log(res)
        const file = new Blob([res], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL)
      })
      .catch(err => console.log(err))
  }

  const getPdf = (id) => {
    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
    });

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    return fetch(
      `${BaseURL}/doctor/getPdf?id=${id}`,
      requestOptions
    )
  }

  const [allSpecialities, setAllSpecialities] = useState([]);

  useEffect(() => {
    getSpecialitiesList().then((result) => {
      setAllSpecialities(result.speciality);
    });
  }, [])

  const handlePayment = (data) => {

    const getDoctorProfile = () => {
      let myHeaders = new Headers({
        accept: "application/json",
        authorization: `${token}`,
      });

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      BaseAPI(
        `${BaseURL}/patient/viewDoctorProfile?id=${data?.doctorData?._id}`,
        requestOptions
      )
        .then((result) => {
          if (result.status === 200) {
            setTimeout(() => {
              navigate("/home/paymentOption", {
                state: {
                  doctorId: data?.doctorData?._id,
                  clinicId: data?.doctorData?.clinicId,
                  doctorAddress: data?.doctorData?.addresses,
                  profile: { ...result.data, specialities: allSpecialities },
                  bookingDate: data.bookingDate,
                  patientId: data?.patientData?._id,
                  bookingSlotId: data?.bookingSlotId,
                  aptType: data?.aptType,
                  booking_id: data?._id,
                  paymentPending: 'yes',
                  specialityId: result.data.specialities[0],
                  // fee: data?.doctorData?.consultationFee,
                  fee: data?.aptType === 'inPerson' ? data?.doctorData?.consultationFeeInPerson : data?.aptType === 'video' ? data?.doctorData?.consultationFeeAudioVideo : data?.aptType === 'audio' ? data?.doctorData?.consultationFeeAudioVideo : null,
                  dateString: `${moment(data?.bookingDate.slice(0, 10)).format('MMMM DD , YYYY')} | Time :${timeConvert(data?.bookingDateAndTime?.slice(11, 16)) +
                    " - "}
                ${timeConvert(data?.bookingEndTime?.slice(11, 16))}`
                },
              });
            }, 100);
          }
        })
    };
    getDoctorProfile();
  }

  const columns = useMemo(
    () => [
      {
        Header: "Doctor",
        Cell: ({ row }) => (
          <div className="flex items-center text-text">
            <img
              src={row.original.doctorData?.profilePic?.original || defaultUserImage}
              className="rounded-full h-10 w-10 mr-3"
              alt='doctor'
            />
            <div>
              <p className="text-sm">{row.original.bookingId}</p>
              <p className="font-bold">{row.original.doctorFullName}</p>
            </div>
          </div>
        ),
      },
      // bookingDate
      // bookingDateAndTime: "2022-02-14T10:00:00.000Z"
      // bookingEndTime: "2022-02-14T11:00:00.000Z"
      {
        Header: "Appointment Date",
        Cell: ({ row }) => (
          <div className="text-text">
            <p>{moment(row.original.bookingDate.slice(0, 10)).format('MMMM DD,YYYY')}</p>
            <p>
              {row.original?.bookingDateAndTime == undefined
                ? ""
                : timeConvert(row.original?.bookingDateAndTime?.slice(11, 16)) +
                  " - "}{" "}
              {timeConvert(row.original?.bookingEndTime?.slice(11, 16))}
            </p>
          </div>
        ),
      },
      {
        Header: "Amount",
        Cell: ({ row }) => (
          <div>{row.original?.paymentAmount} $</div>
        ),
      },
      {
        Header: "Payment Status",
        Cell: ({ row }) => (
          <div>
            <p className={paymentStatus(row.original.paymentStatus).classname}>
              {paymentStatus(row.original.paymentStatus).message}
            </p>
            <p className={checkStatus(row.original.currentStatus).classname}>
              {checkStatus(row.original.currentStatus).message}
            </p>
          </div>
        ),
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <div className="flex items-center space-x-5 text-lg">
            <span
              data-tip="View Details"
              className="cursor-pointer"
              onClick={() => viewAppointmentDetails(row.original._id)}
            >
              <AiOutlineEye />
            </span>
            {paymentStatus(row.original.paymentStatus).message === "Unpaid" && row.original.currentStatus === 1  && <span className="text-light-600 cursor-pointer" title="Payment" onClick={() => handlePayment(row.original)}><BsCreditCard className="h-5 w-5" /></span>}
            {showPrescription(prescriptions, bookingData?.bookings, row.original._id)}
            {(row.original.currentStatus === 4 || row.original.currentStatus === 9) && !row.original?.rating && <span className="text-light-600 cursor-pointer" title="Rating" onClick={() => handleRating(row.original._id)}> <BsStar className="h-4 w-4" /></span> }
            {(row.original.currentStatus === 4 || row.original.currentStatus === 9) && row.original?.rating && <span className="text-light-600 cursor-pointer" title="View Rating" onClick={() => handleRating(row.original._id)}> <BsFillStarFill className="h-4 w-4" /></span> }
            {
                // row.original.currentStatus === 2 && row.original.isPatientEligibleForCard === "" && row.original.aptType === 'video' &&
                (row.original.currentStatus === 2 || row.original.currentStatus === 5) && row.original.isPatientEligibleForCard === "" && row.original.aptType === 'video' &&
                <span className="text-primary cursor-pointer"
                data-tip = 'Start Session'
                 onClick={()=>{
                    startSession(row.original._id)
                 }}
                 >
                  <HiOutlineVideoCamera/>
                </span>
              }
              {
                // row.original.currentStatus === 2 && row.original.isPatientEligibleForCard === "" && row.original.aptType === 'audio' &&
                (row.original.currentStatus === 2 || row.original.currentStatus === 5) && row.original.isPatientEligibleForCard === "" && row.original.aptType === 'audio' &&
                <span className="text-primary cursor-pointer" data-tip = 'Audio Session'>
                  <HiOutlinePhone/>
                </span>
              }
              {
                // row.original.currentStatus === 2 && row.original.isPatientEligibleForCard === "" && row.original.aptType === 'inPerson' &&
                (row.original.currentStatus === 2 || row.original.currentStatus === 5) && row.original.isPatientEligibleForCard === "" && row.original.aptType === 'inPerson' &&
                <span className="text-primary cursor-pointer" data-tip = 'In-Person'>
                  <HiOutlineUser/>
                </span>
              }
            {(
              row.original.currentStatus === 2 ||
              row.original.currentStatus === 5) && row.original.isPatientEligibleForCard ==="" && (
              <span
                data-tip="Cancel Appointment"
                className="text-danger cursor-pointer"
                onClick={() => {
                  setidToDelete(row.original._id);
                  setconfirmationBox(true);
                }}
              >
                <AiOutlineCloseCircle />
              </span>
            )}
            {row.original?.isPatientSignDone && row.original?.isDoctorSignDone &&
              row.original?.pdfDocument?.fileLink && (
                <a
                  href={row.original?.pdfDocument?.fileLink}
                  target="_blank"
                  data-tip="Download"
                  rel="noreferrer"
                  className="text-primary cursor-pointer "
                  download
                >
                  <AiOutlineDownload />
                </a>
              )}
              
            <ReactTooltip type="dark" effect="float" />
          </div>
        ),
      },
    ],
    [bookingData?.bookings, prescriptions]
  );

  return (
    <div className="Home bg-Light-gray min-h-screen w-full">
      <Navbar heading="Home / Appointment" />
      <div className="flex justify-between items-center w-full py-5 px-4 flex-col md:flex-row space-y-3 md:space-y-0">
        <div>
          <h1 className="text-lg font-bold text-text">My Appointments</h1>
        </div>
        <div className="flex space-x-2 md:space-x-10 px-3 md:px-0">
          <div className="relative">
            <input
              type="search"
              ref={searchRef}
              className="rounded-lg  px-4 pl-8 py-3 shadow text-sm"
              placeholder="Search"
              onChange={HandleSearch}
            />
            <span className="absolute top-3.5 text-base text-text left-1">
              <AiOutlineSearch />
            </span>
          </div>
          <select
            className="rounded-lg text-text px-4 py-2 text-sm shadow"
            ref={statusRef}
            onChange={(e) => {
              setSearchStatus(e.target.value);
            }}
          >
            <option value="none" selected disabled hidden>
              Appointment Status
            </option>
            {statusOptions.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              );
            })}
          </select>
          {searchStatus !== "" || searchText !== "" ? (
            <button
              className="bg-danger p-3 rounded-lg  text-white text-lg font-bold "
              onClick={clearFilter}
            >
              {" "}
              <HiOutlineX />
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      { isLoading ? (
        <Spinner />
      ) : (
        bookingData && (
          <Table
            className="bg-white mx-3"
            columns={columns}
            data={data}
            pageSize={10}
          />
        )
      )}

      {idToDelete !== "" && (
        <Rodal
          visible={confirmationBox}
          onClose={() => setconfirmationBox(false)}
          height={300}
        >
          <div className="text-text h-auto">
            <h1 className="font-bold">Confirm</h1>
            <h2 className="mt-3 text-lg text-center">
              Are you sure you want to cancel this appointment?
            </h2>
            <Formik
              validationSchema={validationSchema}
              initialValues={{ reason: "" }}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form className="mt-4">
                  <div className="relative flex flex-col">
                    <label className="text-sm font-bold">
                      Reason for Cancellation
                    </label>
                    <Field
                      component="textarea"
                      name="reason"
                      className="border p-2 rounded-lg"
                    />
                    <ErrorMessage name='reason' component='p' className="text-sm text-danger text-right" />
                  </div>
                  <div className="mt-3 flex px-10 justify-between">
                    <button
                      type="button"
                      className="rounded-lg px-5 py-2 text-primary border font-bold tracking-widest"
                      onClick={() => setconfirmationBox(false)}
                    >
                      No
                    </button>
                    <button
                      type="submit"
                      className="rounded-lg px-5 py-2 text-white bg-danger border font-bold tracking-widest"
                    >
                      Yes
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Rodal>
      )}
    </div>
  );
};

export default Appointment;
