import React from "react";
import {
  Page,
  Text,
  Document,
  StyleSheet,
  Font,
  Image,
  View,
} from "@react-pdf/renderer";
import Logo from "../assets/images/LogoCenter.png";

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    lineHeight: 1,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 140,
    height: "15vh",
    width: "30vw",
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "10vh",
  },
  signature: {
    height: "15vh",
    width: "30vw",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

const date = new Date().toDateString();

//getting patient signature.
const user = localStorage.getItem("userData");
const UserInfo = JSON.parse(user);

const signature = UserInfo?.eSignature;



const California = ({ verified = false, details = null }) => {
  

  let patientName = `${details?.patientId?.firstName} ${
    details?.patientId?.middleName ? details?.patientId?.middleName : ""
  } ${details?.patientId?.lastName}`;


  const PatientName = () => verified && `${patientName}`;

  return (
    <Document>
      <Page style={styles.body}>
        <Image style={styles.image} src={Logo} />
        <Text style={styles.author}>
          Pursuant to California’s Health and Safety Code Section 11362.5
        </Text>
        <Text style={styles.text}>
          The purpose of this medical document is to identify this individual as
          a patient whose possession and or cultivation of medical cannabis is
          permissible pursuant to California Health and Safety Code Section
          11362.5 and Senate Bill 420. This affirms the patient listed above has
          been examined and evaluated by the physician indicated on this
          document and that the physician is licensed to practice medicine in
          the State of California. It is their assessment that the
          abovementioned patients qualifies under California Health and Safety
          Code Section 11362.5 for the use of cannabis for medical purposes. The
          attending physician is responsible for only the medicinal cannabis
          aspect of medical care. This patient assumes full responsibility for
          any and all risks associated with this treatment option. The physician
          has discussed the potential medical benefits and risks of cannabis
          use. This patient hereby gives permission for representatives of
          Heally to discuss the nature of their condition(s) and the information
          contained within this document to verification purposes. This is a
          non-transferable document. This document is the property of the
          physician indicated on this document and can be revoked at any time
          without notice. Void after expiration, if altered or misused. By
          signing below, I confirm that I have been advised and fully understand
          that the daily and prolonged use of Medical Marijuana (i.e., Cannabis)
          in any form may cause damage to any of the organs and cells of the
          human body.
        </Text>
        <View style={styles.container}>
          <View>
            {verified && <Image src={signature} style={styles.signature} />}
            <Text style={styles.text} children={<PatientName />} />
            { !verified && <Text style={styles.text}>Patient Signature</Text> }
            <Text style={styles.text}>{date}</Text>
          </View>

        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default California;
