import data from "./data.json";
import countries from "./countryCodes.json";

export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

const strongPassword = (password) => {
  var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(password);
};

const checkStatus = (status) => {
  if (status === 1) {
    return {
      message: "Pending",
      classname: "text-secondary font-bold",
    };
  } else if (status === 2) {
    return {
      // message: "Booked",
      message: "Scheduled",
      classname: "text-primary font-bold",
    };
  } else if (status === 3) {
    return {
      message: "Rejected",
      classname: "text-danger font-bold",
    };
  } else if (status === 4) {
    return {
      message: "Completed",
      classname: "text-primary font-bold",
    };
  } else if (status === 5) {
    return {
      message: "Rescheduled",
      classname: "text-secondary font-bold",
    };
  } else if (status === 6) {
    return {
      message: "Expired",
      classname: "text-danger font-bold",
    };
  } else if (status === 7) {
    return {
      message: "Cancelled",
      classname: "text-danger font-bold",
    };
  } else if (status === 8) {
    return {
      message: "No Show",
      classname: "text-dark font-bold",
    };
  }
  else if (status === 9) {
    return {
      message: "Session Completed",
      classname: "text-primary font-bold",
    };
  }
};

const paymentStatus = (status) => {
  if (status === 1 || status === null) {
    return {
      message: "Unpaid",
      classname: "text-secondary font-bold",
    };
  } else if (status === 2) {
    return {
      message: "Paid",
      classname: "text-primary font-bold",
    };
  }
};

const DateAndTime = (dateString, type = "date") => {
  let date = new Date(dateString);
  let weekday = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  if (type === "date") {
    return dateString.split("-").reverse().join("/");
  } else if (type === "time") {
    return dateString.slice(11, 16);
  } else if (type === "day") {
    return weekday[date.getDay()];
  }
};

const UtcToCurrentTimezone = (date, timezone = "en-US") => {
  const dateTime = new Date(date);
  return dateTime
    .toLocaleString(timezone, {
      timeZone: "America/Mexico_City",
    })
    .replaceAll("/", "-");
};

const States = () => {
  const states = ["Alabama"];
  return (
    <datalist id="states">
      {data.map((stateInfo) => {
        return <option value={stateInfo.State} />;
      })}
    </datalist>
  );
};

const Countries = () => {
  return (
    <datalist id="countries">
      {countries.map((country) => {
        return <option value={country.name} />;
      })}
    </datalist>
  );
};

 //default image
 const defaultUserImage =
 "https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-image-182145777.jpg";

function timeConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

function objectDiff(a, b) {
  const obj = JSON.parse(JSON.stringify(a));
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && JSON.stringify(obj[key]) === JSON.stringify(b[key])) delete obj[key];
    if (obj[key] === b[key]) delete obj[key];
  });
  return obj;
}

export {
  strongPassword,
  checkStatus,
  paymentStatus,
  UtcToCurrentTimezone,
  States,
  DateAndTime,
  timeConvert,
  Countries,
  objectDiff,
  defaultUserImage
};
