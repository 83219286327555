/* eslint-disable react-hooks/rules-of-hooks */
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// export const BaseURL = "https:// .thesynergyworks.com/api/v1";
export const BaseURL = "https://api.telesecure360.com/api/v1";



// live = 'https://api.kifdoctors.com/api/v1'
// dev = 'https://api-dev.kifdoctors.com/api/v1'

export const loginAPI = (requestOptions) => {
  return fetch(`${BaseURL}/users/login`, requestOptions).then((response) =>
    response.json()
  );
};

export const ForgotAPI = (requestOptions) => {
  return fetch(`${BaseURL}/users/forgotPassword`, requestOptions).then(
    (response) => response.json()
  );
};

export const BaseAPI = (url, requestOptions) => {
  return fetch(url, requestOptions).then((response) => response.json());
};

export const getQualificationsList = () => {
  const token = localStorage.getItem("token");

  let myHeaders = new Headers({
    accept: "application/json",
    authorization: `${token}`,
    "Content-Type": "application/json",
  });

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return BaseAPI(`${BaseURL}/admin/getQualificationsList`, requestOptions)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getSpecialitiesList = () => {
  const token = localStorage.getItem("token");

  let myHeaders = new Headers({
    accept: "application/json",
    authorization: `${token}`,
    "Content-Type": "application/json",
  });

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return BaseAPI(`${BaseURL}/admin/getSpecialitiesList`, requestOptions)
    .then((result) => result.data)
    .catch((err) => console.log(err));
};

export const ImageUpload = async (e) => {
  

  var formdata = new FormData();
  formdata.append("file", e.target.files[0], e.target.files[0].name);

  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  return fetch(`${BaseURL}/common/uploadFile`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const FileUpload = async ( blob) => {
 

  var formdata = new FormData();
  formdata.append("file", blob , 'doc.pdf');

  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  return fetch(`${BaseURL}/common/uploadFile`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const multipleFile = async (file) => {
  

  var formdata = new FormData();
  formdata.append("file", file, file.name);

  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  return fetch(`${BaseURL}/common/uploadFile`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

function DataURIToBlob(dataURI) {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}

export const base64Upload = async (str) => {
  
  let file = DataURIToBlob(str);
  var formdata = new FormData();
  formdata.append("file", file, "sign.png");

  

  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  return fetch(`${BaseURL}/common/uploadFile`, requestOptions).then(
    (response) => response.json()
  );
};

// get doctor availability
export const getAvailbleDates = async (doctorId = '', startDate = '2023-03-01', endDate = '2023-05-01') => {
  // getAvailabilityAndAppointments?doctorId=61f8efa663271eb1efe166d5&startDateAndTime=2022-02-13&endDateAndTime=2022-03-20

  const token = localStorage.getItem("token");



  let myHeaders = new Headers({
    "accept": "application/json",
    authorization: `${token}`,
    'Content-Type': 'application/json'
  });

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };

  return fetch(`${BaseURL}/doctor/getAvailabilityAndAppointments?doctorId=${doctorId}&startDateAndTime=${startDate}&endDateAndTime=${endDate}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error)
      if (error?.status === 401) {
        toast.warn(error?.message || 'Session expired. Please try again later')
        localStorage.clear();
      }
    }
    );

}

export const getDoctorProfile = (doctorId) => {

  const token = localStorage.getItem("token");


  let myHeaders = new Headers({
    accept: "application/json",
    authorization: `${token}`,
  });

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(
    `${BaseURL}/patient/viewDoctorProfile?id=${doctorId}`,
    requestOptions
  )
    .then((response) => response.json())

};

export const submitReview = (body) => {

  const token = localStorage.getItem("token");



  let myHeaders = new Headers({
    accept: "application/json",
    authorization: `${token}`,
    "Content-Type": "application/json",
  });

  var raw = JSON.stringify(body);



  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
  };

  return fetch(
    `${BaseURL}/users/ratingToDoctor`,
    requestOptions
  )
    .then((response) => response.json())

};
