import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import { useNavigate, useLocation } from "react-router-dom";
import { HiOutlineChevronLeft, HiOutlineVideoCamera , HiDocumentText, HiOutlinePhone } from "react-icons/hi";
import { AiOutlineFieldTime ,  AiOutlineInfoCircle , AiFillCheckCircle } from 'react-icons/ai'
import { BsPenFill } from 'react-icons/bs'
import { BaseAPI, BaseURL } from "../../../services/services";
import {
  paymentStatus,
  checkStatus,
  timeConvert,
} from "../../../services/Constants";
import { toast } from "react-toastify";
import Spinner from "../../../components/Loader/Spinner";
import Spin from '../../../components/Loader/Spin'
import moment from 'moment'
import DatePicker from "react-datepicker";

const View = () => {
  const inputClass = "bg-Light-gray px-5 py-3 rounded-lg mr-8";

  
  const token = localStorage.getItem("token");

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  if(!state){
    localStorage.removeItem('token')
    localStorage.removeItem('userData')
    navigate('/login')
  }

  //state
  const [bookingDetails, setBookingDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  
  //start date for rescheduling
  const [selectedDate , setSelectedData] = useState(new Date())
  const [avaiableSlots , setAvailableSlots] = useState(null)
  const [slotId , setSlotId] = useState(null)
  const [isRescheduling , setIsRescheduling] =  useState(false)
  const [showReschedule , setShowReschedule] = useState(false)

  

  const goBack = () => {
    navigate(-1);
  };

  //to session page
  const startSession = () => {
    navigate(`/home/session?id=${state.id}`);
  };

  //getBooking Details
  const viewAppointmentDetails = (id) => {
    setLoading(true);

    

    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
      "Content-Type": "application/json",
    });

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    BaseAPI(
      `${BaseURL}/booking/getBookingDetails?bookingId=${id}`,
      requestOptions
    )
      .then((result) => {
        if(result.status === 200){
        setBookingDetails(result.data);
        sessionStorage.setItem('details' , JSON.stringify(result.data))
      }else{
        toast.error('OOPS ! Something went wrong. Please try after some time.')
      }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const toDocuments = () => {
    navigate('/home/appointment/doc', { state: { bookingId: state.id , bookingData : bookingDetails  } })
  }

  useEffect(() => {
    if(!state){
      localStorage.removeItem('token')
      localStorage.removeItem('userData')
      navigate('/login')
    }else
    viewAppointmentDetails(state.id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rescheduleAppointment = () => {

    if(!slotId){
      return toast.warn('Please select a slot')
    }

    setIsRescheduling(true)

    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
      "Content-Type": "application/json",
    });

    var raw = JSON.stringify({
      bookingId: bookingDetails._id,
      bookingDate: selectedDate.toISOString().split('T')[0],
      bookingSlotId: slotId,
    });

    

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    BaseAPI(
      `${BaseURL}/booking/rescheduleAppointment`,
      requestOptions
    ).then(result => {
      
      if(result.status === 200){
      toast.success('Booking has been rescheduled' , {
        icon : <AiFillCheckCircle className="text-secondary text-lg" />
      })
      setShowReschedule(false)
      viewAppointmentDetails(state.id)}
      else{
        toast.error('Please select a valid slot.')
      }
    }).catch(err => {
      console.log(err);
      toast.error('Something went wrong!')
    }).finally(()=>{
      setIsRescheduling(false)
    })



  }

  const getTimeSlots = () => {
    let myHeaders = new Headers({
      accept: "application/json",
      authorization: `${token}`,
    });

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    let startDate = selectedDate.toISOString().slice(0,10)
    

    BaseAPI(
      `${BaseURL}/patient/getAvailabilityTimeSlots?doctorId=${bookingDetails?.doctorId?._id}&startDateAndTime=${startDate}&endDateAndTime=${startDate}`,
      requestOptions
    )
      .then((result) => {
        
        if(result?.data?.response[0]?.availability[0]?.availabilityTime)
        setAvailableSlots(
          result?.data?.response[0]?.availability[0]?.availabilityTime 
        );
        else setAvailableSlots(null)
      })
      .catch((err) => {
        console.log(err);
        setAvailableSlots(null)
      });
  }


  //function to check is session button to show or hide
  //remove if want to test video session

  const checkSessionValid = ()=>{
    let bookingDate = moment(bookingDetails.bookingDateAndTime.slice(0, 10)).format('MMMM DD , YYYY')
    let todayDate = moment(new Date()).format('MMMM DD , YYYY')
    console.log(todayDate)
    console.log(typeof bookingDate , bookingDate)
    console.log(bookingDate === todayDate)
    return bookingDate === todayDate
  }


  useEffect(()=>{
    bookingDetails && getTimeSlots()
  } , [selectedDate , bookingDetails])

  return (
    <div className="Home bg-Light-gray min-h-screen w-full">
      <Navbar heading="Home / Appointment" />
      <div className="flex justify-between items-center w-full py-5 px-4 flex-col md:flex-row">
        <div className="flex text-text  items-center ">
          <div
            className="bg-white rounded-lg cursor-pointer p-2 mx-3 shadow-back text-lg font-bold"
            onClick={goBack}
          >
            <HiOutlineChevronLeft />
          </div>
          <h1 className="text-lg font-bold">Appointment Details</h1>
        </div>
        <div className="my-3 md:my-0 flex gap-4 items-center">
        {
           bookingDetails?.pdfDocument?.fileLink && bookingDetails?.isDoctorSignDone && <a href={bookingDetails?.pdfDocument?.fileLink } target='_blank' rel='noreferrer' className="bg-primary text-white flex items-center px-3 py-2 rounded-lg cursor-pointer" > <HiDocumentText className="mr-3" /> Issued Document</a>
        }
        {
          
          bookingDetails?.isPatientEligibleForCard === "Yes"  && !bookingDetails?.isPatientSignDone && <button className="bg-white text-primary border flex items-center px-3 py-2 rounded-lg" onClick={()=>toDocuments()}> <BsPenFill className="mr-3"/> Sign Document</button>
          
        }
          {/* {bookingDetails?.currentStatus === 2 &&  bookingDetails?.isPatientEligibleForCard ===""&& !bookingDetails?.isToShowEligibleOption && bookingDetails?.aptType === 'video' && ( */}
          {(bookingDetails?.currentStatus === 2 || bookingDetails?.currentStatus === 5) &&  bookingDetails?.isPatientEligibleForCard ===""&& !bookingDetails?.isToShowEligibleOption && bookingDetails?.aptType === 'video' && (
            <button
              className="bg-primary text-white flex items-center px-3 py-2 rounded-lg"
              onClick={() => startSession()}
            >
              <HiOutlineVideoCamera className="mr-4" />
              <span>Start Session </span>
            </button>
          )}

          {/* {bookingDetails?.currentStatus === 2 &&  bookingDetails?.isPatientEligibleForCard ===""&& !bookingDetails?.isToShowEligibleOption && bookingDetails?.aptType === 'audio' && ( */}
          {(bookingDetails?.currentStatus === 2 || bookingDetails?.currentStatus === 5) &&  bookingDetails?.isPatientEligibleForCard ===""&& !bookingDetails?.isToShowEligibleOption && bookingDetails?.aptType === 'audio' && (
            <button className="bg-primary text-white flex items-center px-3 py-2 rounded-lg">
              <HiOutlinePhone className="mr-4" />
              <span>Start Session </span>
            </button>
          )}

          { (bookingDetails?.currentStatus === 2 || bookingDetails?.currentStatus === 5) && !bookingDetails?.isToShowEligibleOption && bookingDetails?.isPatientEligibleForCard ==="" && <button
           className={`bg-secondary text-white flex items-center px-3 py-2 rounded-lg ${showReschedule && 'opacity-60'}`}
            onClick={()=>setShowReschedule(!showReschedule)}>
            <AiOutlineFieldTime className="mr-4" />
            <span>Reschedule</span>
          </button>}
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        bookingDetails && (
          <>
          {/* -------When Doctor marks as not eligible ------- */}
          {  bookingDetails?.isPatientEligibleForCard === "No" &&
                <div className="flex justify-end p-4">
                <span className="text-danger text-sm flex items-center"><AiOutlineInfoCircle className="mr-3 text-lg"/>You have been marked as not eligible.</span>
                </div>
              }
          <div className="bg-white rounded-lg mx-4 text-text shadow">
            <div className="flex justify-between items-center border-gray border-b p-5">
              <h2 className="font-bold  ">
                Appointment Id: {bookingDetails.bookingId}
              </h2>
              <div>
                <span className="mx-4">
                  Payment :{" "}
                  <span
                    className={
                      paymentStatus(bookingDetails.paymentStatus).classname
                    }
                  >
                    {paymentStatus(bookingDetails.paymentStatus).message}
                  </span>{" "}
                </span>
                <span>
                  Status :{" "}
                  <span
                    className={
                      checkStatus(bookingDetails.currentStatus).classname
                    }
                  >
                    {checkStatus(bookingDetails.currentStatus).message}
                  </span>{" "}
                </span>
              </div>
            </div>
            <div className="pl-10 py-3 mt-3">
              <div className="grid grid-cols-1 md:grid-cols-3 ">
                <div className="flex flex-col">
                  <label className="text-sm font-bold">Patient Name</label>
                  <p
                    className={inputClass}

                  >{
                      bookingDetails.patientId.firstName +
                      " " +
                      bookingDetails.patientId.lastName
                    }</p>
                </div>
                <div className="flex flex-col">
                  <label className="text-sm font-bold">Patient Email</label>
                  <p
                    className={inputClass}
                  >{bookingDetails.patientId.email}</p>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 my-4">
                <div className="flex flex-col">
                  <label className="text-sm font-bold">Doctor Name</label>
                  <p
                    className={inputClass}
                  >{
                      bookingDetails.doctorId.firstName +
                      " " +
                      bookingDetails.doctorId.lastName
                    }</p>
                </div>
                <div className="flex flex-col">
                  <label className="text-sm font-bold">Doctor Email</label>
                  <p
                    className={inputClass}
                  >{bookingDetails.doctorId.email}</p>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 my-4">
                <div className="flex flex-col">
                  <label className="text-sm font-bold">Appointment Date</label>
                  <p
                    className={inputClass}
                  >{
                      moment(bookingDetails.bookingDateAndTime.slice(0, 10)).format('MMMM DD , YYYY')
                    }</p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 ">
                  <div className="flex flex-col">
                    <label className="text-sm font-bold">Start Time</label>
                    <p
                      className={inputClass}
                    >{
                        timeConvert(bookingDetails.bookingDateAndTime.slice(11, 16))
                      }</p>
                  </div>
                  <div className="flex flex-col">
                    <label className="text-sm font-bold">End Time</label>
                    <p
                      className={inputClass}
                    >{
                        timeConvert(bookingDetails.bookingEndTime.slice(11, 16))
                      }</p>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 my-4">
                <div className="flex flex-col">
                  <label className="text-sm font-bold">Total Amount</label>
                  <p className={inputClass}>{bookingDetails?.paymentAmount ? (bookingDetails?.paymentAmount % 1 !== 0 ? `${bookingDetails?.paymentAmount?.toFixed(1)} $` : `${bookingDetails?.paymentAmount} $`) : '--' }</p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 ">
                  <div className="flex flex-col">
                    <label className="text-sm font-bold">Cost for Appointment</label>
                    <p className={inputClass}>{bookingDetails?.initialAmount ? (bookingDetails?.initialAmount % 1 !== 0 ? `${bookingDetails?.initialAmount?.toFixed(1)} $` : `${bookingDetails?.initialAmount} $`) : '--'}</p>
                  </div>
                  <div className="flex flex-col">
                    <label className="text-sm font-bold">Tax</label>
                    <p className={inputClass}>{bookingDetails?.taxAmount ? (bookingDetails?.taxAmount % 1 !== 0 ? `${bookingDetails?.taxAmount?.toFixed(1)} $` : `${bookingDetails?.taxAmount} $`) : '--'}</p>
                  </div>
                </div>
              </div>

              {bookingDetails.currentStatus === 7 && (
                <div className=" grid grid-cols-1 md:grid-cols-3 my-4">
                  <div className="flex flex-col md:col-span-2">
                    <label className=" text-danger font-bold">
                      Cancellation Reason
                    </label>
                    <textarea
                      name="reason"
                      value={
                        bookingDetails.updatedHistory[
                          bookingDetails.updatedHistory.length - 1
                        ].updatedEntity[0].reason
                      }
                      className={`${inputClass}`}
                      disabled
                    ></textarea>
                  </div>
                </div>
              )}
            </div>
          </div>
         { showReschedule && <div className="bg-white rounded-lg mx-4 text-text my-4 p-4 shadow">
            <h1 className="font-bold p-3 border-b flex items-center justify-between  border-gray" >
            Reschedule
            <button  className={`bg-primary text-white flex items-center px-3 py-2 rounded-lg ${isRescheduling && ' cursor-not-allowed opacity-70 '}`} onClick={()=>rescheduleAppointment()} disabled={isRescheduling} >{ isRescheduling &&  <Spin className='mr-2'/>} Reschedule</button>
            </h1>
            <div className="flex gap-10 flex-col md:flex-row  md:items-center my-5 md:pl-10">
              <div className="flex flex-col gap-3">
                <label className="text-sm font-bold" >Current Date</label>
                <p className={inputClass}>{moment(bookingDetails.bookingDateAndTime.slice(0, 10)).format('MMMM DD , YYYY')}</p>
              </div>
              <div className="flex flex-col gap-3">
                <label className="text-sm font-bold" >Current Time slot</label>
                <p className={inputClass}>{`${timeConvert(bookingDetails.bookingDateAndTime.slice(11, 16))} - ${ timeConvert(bookingDetails.bookingEndTime.slice(11, 16))}`}</p>
              </div>
            </div>
            <div className="flex md:flex-row flex-col gap-10 md:items-center my-5 md:pl-10">
            <div className="flex flex-col gap-3">
                <label className="text-sm font-bold">Select Date</label>
                <DatePicker
                  selected={selectedDate}
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={50}
                  className={inputClass}
                  onChange={(date) => {
                    setSelectedData(date)
                  }}
                  minDate={new Date()}
                />
              </div>
              <div className="flex flex-col gap-3">
                <label className="text-sm font-bold">Select Time Slot</label>
                <select className={inputClass} 
                onChange={(e) => setSlotId(e.target.value)}>
                  <option>Select Slot</option>
                  {avaiableSlots &&
                    avaiableSlots.map((slot, index) => {
                  if (slot.isAvailable)
                    return (
                      <option key={slot._id} value={slot._id}>
                        {slot.startTime +
                          " - " +
                          slot.endTime}
                      </option>
                    );
                })}
                </select>
              </div>

            </div>
          </div>}
          </>
        )
      )}{" "}
    </div>
  );
};

export default View;
